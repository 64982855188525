<template>
  <div
    class="semi-rounded-button"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
    @click="button_clicked"
    :style="button_style"
  >
    <span class="button-text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'MemlistCircularButton',
  props: {
    text: {
      type: String,
      default: 'Click Me'
    },
    primary_color: {
      type: String,
      default: '#0085eb' // Example primary color
    },
    secondary_color: {
      type: String,
      default: '#ffffff' // Example secondary color (white background)
    },
    // This is the color shown on hover (between primary and secondary).
    // You could compute a mid-tone color if desired; here we just take it as a prop.
    hover_color: {
      type: String,
      default: '#cce7f9' // A color halfway between white and #0085eb, as an example
    }
  },
  watch: {
    
  },
  data() {
    return {
      hovered: false
    };
  },
  computed: {
    button_style() {
      // Base styles
      let bg = this.secondary_color;
      let textColor = this.primary_color;
      let borderColor = this.primary_color;

      if (this.hovered) {
        // Hover state (when not selected): halfway to primary color
        bg = this.hover_color;
      } else {
        // Normal unselected, unhovered state
        bg = this.secondary_color;
      }

      return {
        display: 'inline-flex',
        'align-items': 'center',
        'justify-content': 'center',
        'border-radius': '9999px', // Semi-circle (pill) shape
        'border': `1px solid ${borderColor}`,
        'background-color': bg,
        'color': textColor,
        'padding': '0.5em 1em',
        'cursor': 'pointer',
        'transition': 'background-color 0.2s ease, color 0.2s ease'
      };
    }
  },
  methods: {
    button_clicked() {
      this.$emit('click');
    }
  }
};
</script>

<style scoped>
.button-text {
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
}
</style>
