<template>
  <div>
    <!-- The Button -->
    <div
      class="semi-rounded-button"
      @mouseover="hovered = true"
      @mouseleave="hovered = false"
      @click="select_button_clicked"
      :style="button_style"
    >
      <div v-if="selected && focused" class="input-container">
        <input
          ref="input"
          type="text"
          v-model="entered_value"
          @input="on_input_changed"
          v-on:blur="on_input_lost"
          class="selected-input"
          :style="input_style"
          :placeholder="''"
        />
      </div>
      <div v-else class="input-container">
        <span class="button-text">{{ calculated_text }}</span>
      </div>
    </div>

    
  </div>
</template>

<script>
export default {
  name: 'MemlistCircularSelectButton',
  props: {
    currency: {
      type: String,
      default: null
    },
    preselected: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: 'Click Me'
    },
    primary_color: {
      type: String,
      default: '#0085eb' // Example primary color
    },
    secondary_color: {
      type: String,
      default: '#ffffff' // Example secondary color (white background)
    },
    hover_color: {
      type: String,
      default: '#cce7f9' // A color halfway between white and #0085eb, for example
    }
  },
  watch: {
    preselected: {
      handler(val) {
        this.selected = this.preselected;
      },
      immediate: true
    }
  },
  data() {
    return {
      focused: false,
      selected: false,
      hovered: false,
      entered_value: '' // Holds the user-entered text when selected
    };
  },
  computed: {
    calculated_text() {

      if (!this.entered_value) {
        return '';
      }

      if (this.currency) {
        return this.entered_value + ' ' + this.currency;
      }

      return this.entered_value;
    },
    input_style() {
      let textColor = this.primary_color;

      if (this.selected) {
        textColor = this.secondary_color;
      }

      return {
        color: textColor
      }
    },
    button_style() {
      // Base styles
      let bg = this.secondary_color;
      let textColor = this.primary_color;
      let borderColor = this.primary_color;

      if (this.selected) {
        // Selected state: fill with primary color, text is secondary color
        bg = this.primary_color;
        textColor = this.secondary_color;
      } else {
        if (this.hovered) {
          // Hover state (when not selected): halfway to primary color
          bg = this.hover_color;
        } else {
          // Normal unselected, unhovered state
          bg = this.secondary_color;
        }
      }

      return {
        display: 'inline-flex',
        'align-items': 'center',
        'justify-content': 'center',
        'border-radius': '9999px', // pill shape
        border: `1px solid ${borderColor}`,
        'background-color': bg,
        color: textColor,
        padding: '0.5em 1em',
        cursor: 'pointer',
        transition: 'background-color 0.2s ease, color 0.2s ease'
      };
    }
  },
  methods: {
    on_input_lost() {
      this.focused = false;
    },
    select_button_clicked() {
      this.focused = true;
      // Once clicked, the button becomes selected
      this.selected = true;
      this.$emit('selected', this.value);

      this.$nextTick(()=>{
        this.$refs['input'].focus();
      });
    },
    reset() {
      // Method to reset selection and clear entered value
      this.selected = false;
      this.entered_value = '';
    },
    get_value() {
      return this.value;
    },
    on_input_changed() {
      // Emit an event when the input value changes
      this.$emit('changed', this.entered_value);
    }
  }
};
</script>

<style scoped>

.semi-rounded-button {
  width: 100%;
  height: 100%;
}
.selected-input {
  text-align: center;
}

.button-text {
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
}

.input-container {

}

input:focus {
  margin-top: -2px;
  border: none;
  outline: none;
}

.selected-input {
  width: 100%;
  padding: 0.5em;
  font-size: 1rem;
}
</style>
