// USA
export const locale = {
  ERRORS: {
    HEADERS: {
      ERR_INVITE_TEMPLATE_NOT_SET: 'Template missing',
      ERR_TODO_MUST_SET_COMPANY_IDS: 'Companies missing',
      ERR_TODO_SET_DATE: 'Dates missing',
      ERR_TODO_MISSING_SUBTASKS: 'Subtasks missing',
      ERR_CANT_REMOVE_ALL_MEMBERSHIPS: 'Cant remove all memberships',
      ERR_CANT_REMOVE_MEMBERSHIP_WITH_INVOICE: 'Cant remove membership',
      ERR_PENDING_PAYMENTS_MEMBER: 'Missing payment',
      ERR_NO_MEMBERS_IN_SENDOUT_QUERY: 'No recipients',
      ERR_MEMBER_ALREADY_EXISTS: 'Member already exists',
      ERR_EMAIL_ALREADY_REGISTERED: 'Email already exists',
      ERR_MEMBER_MISSING_SKV_PERIOD: 'Missing SKV Period',
      ERR_MEMBER_SET_REG: 'Invalid action',
      ERR_EVENTSIGNUP_ANY_MEMBER_PAID: 'Missing membership',
      ERR_EVENTSIGNUP_MAX_TICKETS: 'Max tickets',
      ERR_EVENTSIGNUP_COMPANY_MEMBER_PAID: 'Membership paid',
      ERR_EVENTSIGNUP_UNDEFINED_ERROR: 'Unknown error',
      ERR_EVENTSIGNUP_MEMBERS_ONLY: 'Members only',
      ERR_EVENTSIGNUP_INACTIVE_MEMBERSHIP: 'Inactive membership',
      ERR_EVENTSIGNUP_ANY_MEMBER: 'Not allowed',
      ERR_FORBIDDEN: 'Forbidden',
      ERR_MISSING_REQUIRED_PAGE_CONFIRMPAGE: 'Missing required page',
      ERR_409_GROUP: 'Parent exist',
      ERR_RENEWS_HAVE_ERRORS: 'Renews with errors',
      ERR_MULTIPLE_WORKSHEETS: 'Multiple worksheets',
      ERR_IMPORT_TOO_MANY_ROWS: 'Too many rows'
    },
    TEXTS: {
      ERR_INVITE_TEMPLATE_NOT_SET: 'Invite to system template is missing. Go to System -> Users -> Invite template.',
      ERR_TODO_MUST_SET_COMPANY_IDS: 'You need to select at least 1 company to receive the task.',
      ERR_TODO_SET_DATE: 'You need to set start and end date for the task.',
      ERR_TODO_MISSING_SUBTASKS: 'You need to create at least 1 subtask.',
      ERR_CANT_REMOVE_ALL_MEMBERSHIPS: 'Deleting this membership would leave the member without ANY memberships. This means it will be impossible to find the member. This action is prohibited.',
      ERR_CANT_REMOVE_MEMBERSHIP_WITH_INVOICE: 'Not allowed to delete membership with invoice connected',
      ERR_PENDING_PAYMENTS_MEMBER: 'Payments are pending on your account. You are prohibited from signing in. Wait at least 5 minutes and register again with payment.',
      ERR_NO_MEMBERS_IN_SENDOUT_QUERY: 'There are no recipients in the query. Click on "Search" to make a selection.',
      ERR_MEMBER_ALREADY_EXISTS: 'Member with given data already exists',
      ERR_EMAIL_ALREADY_REGISTERED: 'A user with the provided email is already registered in the system',
      ERR_MEMBER_MISSING_SKV_PERIOD: 'Member lacks SKV period assigned',
      ERR_MEMBER_SET_REG: 'Its only allowed to unset the registration flag.',
      ERR_EVENTSIGNUP_ANY_MEMBER_PAID: 'You need a paid active membership to continue.',
      ERR_EVENTSIGNUP_MAX_TICKETS: 'Youre not allowed to purchase any more tickets for this event.',
      ERR_EVENTSIGNUP_COMPANY_MEMBER_PAID: 'You must have a membership in the company who published the event.',
      ERR_EVENTSIGNUP_UNDEFINED_ERROR: 'Undefined error. Please provide the following information to the administrator: ',
      ERR_EVENTSIGNUP_MEMBERS_ONLY: 'You must be member to join.',
      ERR_EVENTSIGNUP_INACTIVE_MEMBERSHIP: 'You have no active memberships.',
      ERR_EVENTSIGNUP_ANY_MEMBER: 'Event is closed or otherwise not allowing new participants.',
      ERR_FORBIDDEN: 'You are not allowed to perform this action.',
      ERR_MISSING_REQUIRED_PAGE_CONFIRMPAGE: 'You need to set a confirm page for this form.',
      ERR_409_GROUP: 'Cant remove access when you group have access to the parent company',
      ERR_RENEWS_HAVE_ERRORS: 'You cant create invoices when there are renews with errors (in red).',
      ERR_MULTIPLE_WORKSHEETS: 'Excel file can not contain more than 1 worksheet. Remove hidden or unused worksheets.',
      ERR_IMPORT_TOO_MANY_ROWS: 'Import can only handle a maximum of 100 000 rows.'
    },
  },

  VALIDATIONS: {
    ENTER_PASSWORD: 'Enter password, minimum 6 characters'
  },

  BANKID: {
    SCAN_QR: 'Scan QR in BankID',
    TIMEOUT: 'Login took to long. Refresh the page.',
    COMPLETE: 'BankID Signed!',
    FAILED: 'Failure from BankID',
    OPEN_BANKID: 'Open BankID',
  },

  TRANSLATOR: {
    SELECT: "Select your language"
  },
  AUTH: {
    ADMINISTRATOR: 'Administrator',
    MENU: {
      DONATIONS: 'Donations',
      CUSTOMERS: 'Customers',
      MEMBERS: 'MEMBERS',
      MEMBERS_CONTROL: 'Members',
      HISTORY: 'Event log',
      MEMBER_REGISTRATIONS: 'Member requests',
      DASHBOARD: 'Dashboard',
      TODO: 'Taskmanager',
      ERROR: 'Errors',
      EVENT: 'Events',
      REPORTS: 'Reports',
      MEMBER_EDITOR: 'Member Editor',
      QUICKSEARCH: 'Quick Search',
      ADV_SEARCH: 'Advanced Search',
      DUPLICATES: 'Duplicates',
      SPAR: 'SPAR',
      IMPORT: 'Import',
      STICKPROV: 'Samples',
      LADOK: 'LADOK',
      ATTRIBUTES: 'Attributes',
      RENEWS: 'Renewals',
      RENEWS_DEPRECATED: 'Renewals (Deprecated)',
      MUCF: 'MUCF',
      SETTINGS: 'Settings',
      REGISTERSETUP: 'Registerwizard',
      FORMS: 'Forms',
      PERIODS: 'Periods',
      USERS: 'Users',
      SUBCOMPANY: 'Subcompanies',
      FILES: 'Files',
      SHOPITEM: 'Shop',
      BOOK: 'Accounting',
      SENDOUT: 'Sendout',
      TEMPLATES: 'Templates',
      SUPPORT: 'Support',
      BILLING: 'Billing',
      COMPANIES: 'Companies',
      LANGUAGE: 'Language',
      SYSTEM: 'System',
      IMPORTCOMPANIES: 'Import companies',
      APIKEYS: 'API: Keys',
      PAYMENTS: 'Payments',
      TRANSACTIONS: 'Transactions',
      SMS: 'SMS',
      GRANT: 'Grants',
      PAGE: 'Pages',
      REDOC: 'API: Documentation',
      ADMIN: 'Administration',
      ORDERS: 'Orders',
      INVOICES: 'Invoices',
      FORTNOX_INVOICES: 'Fortnox Invoices',
      COUPONS: 'Coupons (BETA)',
      JOBS: 'Jobs',
      EMAILS: 'E-mail events',
      EDUCATION: 'Educations',
      THS_INSTANCES: 'Instances',
      THS_LOGS: 'THS Logs',
      FAITH: 'Religious community',
      COMMUNICATION: 'Communication',
      NOTES: 'Notes',
      USER_PERMISSIONS:'User permissions',
      INSTRUMENT_TYPES: 'Instrument types',
      SWISH_MERCHANT: 'Swish Merchant',
      SUPPORT_ADMIN: 'Support Admin',
      KVITTO: 'Receipt service'
    }
  },

  BOILERPLATE: {
    TEXTAREA: 'Prisma model source',
    BOILERPLATE: 'Boilerplate',
    PRISMA_BOILERPLATE: 'Prisma Boilerplate',
  },

  DASHBOARD: {
    MEMBERS: 'New members',
    MEMBERSHIPS: 'Memberships',
    PERIOD_MEMBERSHIPS: 'Memberships per period',
    DATE_MEMBERSHIPS: 'Memberships per date',
    MUCF_6_25: 'MUCF 6-25 years',
    MUCF_TOTAL: 'MUCF Total',
    COUNT: 'Calculation',
    SKV_INTAG: 'Skatteverket Registration'
  },

  MUCF: {
    MUCF_GUIDE: 'MUCF Guide',
    SETTINGS: 'Settings',
    SEARCH_COMPANY: 'Search company',
    NUM_ROWS: 'Number of rows'
  },

  MEMBER_EDUCATION: {
    CREATE_SOURCES: {
      LADOK: 'LADOK',
      ADMIN: 'Administrator',
      MEMBER: 'Member'
    },
    CREATING: 'Connecting education',
    EDITING: 'Connected education',
    ID: 'Id',
    CREATED_AT: 'Created at',
    SOURCE: 'Source',
    EDUCATION_ID: 'Education',
    MEMBER_ID: 'Member',
    PERIOD_ID: 'Period',
    HEADER: '',
    TAB_MEMBER_EDUCATION: '',
    TAB_SETTINGS: '',
    CREATED: '',
    DELETED: '',
    UPDATED: '',
    UNABLE_TO_LOAD: '',
    UNABLE_TO_CREATE: '',
    UNABLE_TO_UPDATE: '',
    UNABLE_TO_DELETE: '',
    NO_RECORDS_FOUND: '',
    CREATE_MEMBER_EDUCATION: '',
    ADDED: 'Education connected',
    UNABLE_ADD: 'Unable to connect education',
  },

  SWISH_MERCHANT: {
    TEST_CREATED: 'Swish test created',
    FINALIZE_SETUP: 'Finalize Setup:',
    CREATE_TEST: 'Create Swish Test',
    AN_ERROR_OCCURED: 'Unable to create Swish test',

    PAYER_NUMBER: 'Swishnumber',
    AMOUNT: 'Amount',
    MESSAGE: 'Message',
    USE_SWISH_NUMBER: 'Use Swishnumber',
    USE_QR_CODE: 'Use QR code',
    USE_LOCAL_APP: 'Open local app',

    HEADER: 'Swish Merchant',
    UNABLE_LOAD_FILES: 'Unable to load ceritificates',
    TAB_MERCHANTS: 'Merchants',
    TAB_SETTINGS: 'Settings',
    NO_RECORDS_FOUND: 'No records found',
    CREATE_MERCHANT: 'Create merchant',
    CREATED_AT: 'Date created',
    KEY_FILE: 'Merchant Key file (.key)',
    CERT_FILE: 'Merchant Cert file (.pem)',

    DELETED: 'Swish merchant deleted',

    PAYER_ALIAS: 'Swish alias',
    PAYEE_ALIAS: 'Swish alias',
    PASSPHRASE: 'Passphrase',
    STATUS: 'Status',
    STATUSES: {
      PENDING: 'Pending',
      VALID_CERTS: 'Valid certs',
      OPERATIONAL: 'Operational',
      ERROR: 'Error'
    }
  },


  VALIDATION: {
    REQUIRED: 'Required',
    EMAIL: 'Invalid e-mail',
    ADM_FEE: 'Administration fee amount is invalid',
    MEM_FEE: 'Fee per member is invalid',
  },


  HEADER: {
    FILTER: 'Filter',
    FILTER_HELP: 'Filter on org or name'
  },

  CUSTOMER: {
    CUSTOMERS: 'Customers',
    CREATE: 'Create customer',
    LOGO: 'Logo',
    NAME: 'Customer',
    CREATED_AT: 'Created',
    DELETE_CONFIRM_TITLE: 'Delete customer',
    DELETE_CONFIRM_MESSAGE: 'Confirm that you want to delete the customer',
    CONTACT_NAME: 'Contact name',
    ADMIN_PHONE: 'Administrators phone',
    ADMIN_EMAIL: 'Administrators email',
    ROOT_COMPANY_ID: 'Head company',
    ID: 'ID',
    CONTACT_INFORMATION: 'Contact information',
    CUSTOMER_EDITOR: 'Customer editor',
    BASIC_INFORMATION: 'Basic information'
  },

  ACCOUNT: {
    NO_BANKID_DATA: 'No BankID data',
    INVALID_BANKID_TOKEN: 'Invalid BankID token',
    INVALID_LINK2: 'This link is invalid',
    TAB_BANKID: 'BankID',
    TAB_EMAIL: 'E-mail/Password',
    NO_USER: 'No user',
    NO_USER_INFO: 'There is no user with that personal number',
    START_BANKID_SIGN: 'Start BankID signing',
    MOBILE_BANKID: 'BankID app on your phone and login',
    OPEN_BANKID: 'Open BankID',
    BANKID_ERROR: 'BankId error',
    START_BANKID_QR: 'Start the BankId app on your phone and scan the QR code',
    LOGIN_BANKID: 'Login with BankId',
    LOGGING_IN: 'Logging in...',
    NO_ACCESS_HEADER: 'No access',
    NO_ACCESS_INFO1: 'Your account lacks page access.',
    NO_ACCESS_INFO2: 'Contact administrator to get access to at least one page in any company.',
    MY_PROFILE: 'My profile',
    LOGIN: 'Login',
    LOGOUT: 'Logout',
    LACKS_ACCESS: 'You lack access to any page. Contact administrator.',
    INVALID_EMAIL_PASSWORD: 'Invalid e-mail or password',
    PASSWORD_RULE_NOT_MET: 'Password invalid',
    TO_LOGIN: 'To login',
    PASSWORD_WAS_CHANGED: 'Password has been changed',
    PASSWORDS_MISMATCH: 'Passwords mismatch',
    PASSWORDS_6CHAR: 'Password must be at least 6 characters',
    UNABLE_RESET: 'Unable to reset password',
    RESET: 'Password was reset',
    UPDATE_PASSWORD: 'Update password',
    RESET_CLICK: 'Click to reset password',
    LINK_SENT: 'Link sent',
    LINK_SENT_INFO: 'Check your e-mail for instructions',
    ENTER_EMAIL: 'Enter e-mail to get instructions',
    RENEW_YOUR_MEMBERSHIP: 'Renew your membership',
    YOU_CAN_RENEW: 'You can now renew your membership',
    RENEW_MEMBERSHIP: 'Renew membership',
    INVALID_LINK: 'Invalid login link. Contact your administrator for correct login link.'
  },

  SPAR: {
    ID: 'ID',
    JOB_NAME: 'Name of the SPAR job'
  },

  FORM: {
    FORMS: 'Forms',
    REQUIRE_PARENTS_BELOW_AGE: 'Require parent below age',
    AGE_REQUIRED: 'Age required',
    VISIBILITY_OPTION: 'Visibility option',
    VISIBILITY_OPTIONS: {
      ALWAYS: 'Always',
      IF_FAMILY_MEMBERSHIP: 'If family membership',
    },
    IF_FAMILY_MEMBERSHIP_INFO: 'Warning! This requires the shop/membership page ordered before this page!',
    ASSIGN_SHOP_ITEM: 'Assign membership',
    ASSIGN_COMPANY: 'Assign company',
    ADDED_ATTRIBUTES: 'Added attributes',
    ADD_ATTRIBUTE: 'Add attribute',
    SELECT_ATTRIBUTES: 'Select attribute',
    FORM_RULE_DELETED: 'Rule deleted',
    FORM_RULE_CREATED: 'Rule created',
    FORM_RULE_UPDATED: 'Rule updated',
    RULES: 'Rules',

    STYLE: {
      BG: 'Background color',
      BUTTON_COLOR: 'Button color',
      PRIMARY_COLOR: 'Primary color',
      SECONDARY_COLOR: 'Secondary color',
      BACKGROUND_COLOR: 'Background color',
      HOVER_COLOR: 'Hover color',
      
    },
    FORM_VISIT_TYPE: 'Visitor type',
    FORM_VISIT_TYPES: {
      FIRST: 'First visit',
      STEP: 'Step',
      DONE_OK: 'Done - OK',
      DONE_ERROR: 'Error',
      DONE_WAIT_PAYMENT: 'Done - Wait payment',
    },
    TOTAL_VISITS: 'Total visits',
    TOTAL_DONE: 'Total done',
    BAR_VISITORS: 'Form visitors',
    CREATE_FORM_RULE: 'Create rule',

    FORM_RULES: 'Rules',

    FORM_RULE_TYPE: 'Applied rule',
    
    FORM_RULE_TYPES: {
      ASSIGN_MEMBERSHIP: 'Assign membership (Company and shop item)',
      ASSIGN_COMPANY: 'Assign company',
      ASSIGN_SHOP_ITEM: 'Assign shop item',
      ASSIGN_ATTRIBUTE: 'Assign attribute',
    },

    PRESELECTED_COMPANY: 'Preselected company',
    PRESELECTED_COMPANY_INFO: 'When no company select page is present, auto assign this company on the member.',
    PRESELECTED_SHOP_ITEM: 'Preselected article/membership',
    PRESELECTED_SHOP_ITEM_INFO: 'When no shop page is present, auto assign this article on the member.',
    APPEARANCE: 'Appearance',
    MERGE_TAG: 'Merge tag for template',
    PAGE_NOT_CREATED: 'Page was not created',
    COMPANY_ADMINISTER: 'Company administrations',
    SELECTED_COMPANIES: 'Selected companies ({count})',
    COMPANIES_WITH_MEMBERSHIPS: 'Companies with memberships ({count})',
    SELECT_COMPANIES: 'Enabled companies',
    VALIDATION: 'Validation',
    PAGE_VALIDATIONS: {
      NULL: 'Error',
      NO_GROUP_IDS: 'No questions',
      MISSING_FIELDS: 'Missing fields',
      NO_COMPANY_HIERARCHY: 'No company hierarchy',
      OK: 'OK'
    },
    SELECT_QUESTIONS: 'Select questions/attributes',
    VISIBLE_FIELDS: 'Visible fields',
    FIELD_NAME: 'Fields',
    REQUIRED: 'Required',
    SHOW: 'Show',
    PAGE_NAME: 'Page name',
    HTML: 'Description (HTML)',
    PAGE_CREATED: 'Page created',
    PAGE_UPDATED: 'Page updated',
    PAGE_DELETED: 'Page deleted',
    UNABLE_CREATE_PAGE: 'Unable to create page',
    UNABLE_UPDATE_PAGE: 'Unable to update page',
    UNABLE_DELETE_PAGE: 'Unable to delete page',
    SETTINGS: 'Settings',
    PAGES: 'Pages',
    TEXTS_TAB: 'Texts',
    
    ID: 'ID',
    REQUIRED_CONFIRM_PAGE_MISSING: 'Required confirm page missing',
    CREATE: 'New form',
    COPIED: 'Link copied',
    LINK_URL: 'Link URL',
    UNABLE_LIST: 'Unable to list forms',
    UNABLE_COPY: 'Unable to copy link',
    UNABLE_CREATE: 'Unable to create form',
    UNABLE_DELETE: 'Unable to delete form',
    UNABLE_LOAD: 'Unable to load form',
    UNABLE_UPDATE: 'Unable to update form',
    DELETED: 'Form deleted',
    CREATED: 'Form created',
    UPDATED: 'Form updated',
    NAME: 'Form name',
    CREATED_AT: 'Created at',
    DELETE_CONFIRM_TITLE: 'Delete form',
    DELETE_CONFIRM_MESSAGE: 'Do you want to delete the form?',
    INDEX: 'Index',
    PAGE_DELETED: 'Page deleted',
    NAME_REQUIRED: 'Name is required',
    CREATE_PAGE: 'Skapa sida',
    PAGE_TYPE: 'Page type',
    PAGE_TYPES: {
      PERSONNR: 'Personal number',
      QUESTIONS: 'Questions',
      PERSON_DETAILS: 'Personal details',
      FAMILY: 'Family',
      PARENTS: 'Guardians',
      COMPANY_SELECT: 'Membership selection',
      COMPANY_DETAILS: 'Company details',
      SHOP: 'Shop',
      DONATION: 'Donation',
      TICKETS: 'Tickets',
      PAYMENT: 'Payment',
      CONFIRM: 'Confirm',
      BANKID_LOGIN: 'BankID Login',
      BANKID_SIGN: 'BankID Sign',
      LOGIN: 'Login'
    },
    TYPE: 'Type',
    TYPES: {
      REGISTER_MEMBER: 'Register member',
      RENEW_MEMBER: 'Renew member',
      COMPANY: 'Company',
      EVENT: 'Event',
      DONATION: 'Donation'
    },
    REQUIRED_TEXT: 'Required',
    TEXTS: {
      SUCCESS_HEADER: 'On success text',
      SUCCESS_TEXT: 'On success text',
      FAILURE_HEADER: 'On failure text',
      FAILURE_TEXT: 'Registration failed text',
    }
  },

  MEMBERTYPE: {
    CREATE: 'Create member type',
    CREATED: 'Member type created',
    CREATED_AT: 'Created at',
    UNABLE_CREATE: 'Unable to create member type',
    UPDATED: 'Member type updated',
    UNABLE_UPDATE: 'Unable to update member type',
    DELETED: 'Member type deleted',
    UNABLE_DELETE: 'Unable to delete member type',
    NAME: 'Name',
    NONE_FOUND: 'No member types found',
  },

  MEMBER_EDITOR: {
    SHOW: 'Show',
    SECTION_FIELD: 'Section/Field',
    EDIT_MEMBER: 'Editing member',
    CREATE_MEMBER: 'Creating member',
    PERSONAL_DETAILS: "Personal details",
    CONTACT_INFORMATION: "Contact information",
    INVOICE_ADDRESS: "Invoice address",
    STATUS: "Status",
    MEMBERSHIPS: "Memberships",
    EVENTS: "Events",
    EDUCATIONS: "Educations",
    FAMILY: "Family",
    PARENTS: "Guardians",
    FAITH: "Religious community",
    ATTRIBUTES: "Attributes",
    FILES: "Files",
    RENEWALS: "Renewals",
    INVOICES: "Invoices",
    PAYMENTS: "Payments",
    NOTES: "Notes",
    OPTIONS: "Options",
    OTHER: "Other"
  },
  USER: {
    
    NOT_LOGGED_IN: 'Not logged in',
    YOU_ARE_NOT_LOGGED_IN: 'Your session has finished. You need to proceed to login again.',
    GOTO_LOGIN: 'Go to login',

    SUPPORT_NEEDED_HEADER: 'Support',
    SUPPORT_NEEDED_INFO: 'We are glad to help you with any issues you are facing. Have you checked the helptexts and documentation?',
    SUPPORT_NOT_IN_DOCUMENTATION: 'Question is not covered in the documentation, contact support',

    INTERNET_ERROR: 'No connection',
    INTERNET_ERROR_INFO: 'Your browser has lost connection to the service. Please reload the page to try again.',

    NEW_VERSION_HEADER: 'New version',
    NEW_VERSION_INFO: 'A new version is available. Please reload the page to start using the new version.',

    GOTO_FEEDBACK: 'Go to feedback',
    GOTO_RELEASE_NOTES: 'Go to release notes',
    NEW_PATCH_NOTES: 'Release notes available',
    PLEASE_READ_PATCH_NOTES: 'We have new release notes available which details the changes made in the system. Click on the link below to view.',
    SESSION_ENDED: 'Your session has ended',
    CLICK_TO_LOGIN_AGAIN: 'Click to login again',
    UPDATE_AVAILABLE: 'An update is available. Click button below to refresh the page.',
    UPDATE_PAGE: 'Reload page',
    FEEDBACK_HEADER: 'Please provide feedback!',
    PLEASE_PROVIDE_FEEDBACK: 'We need your help to be better! Your feedback will provide important information for us.',
    ADDRESS: 'Address',
    NUMBER_USERS_FOUND: 'Number of users: ',
    SET_PAGES_FROM_COMPANY: 'Set pages from selected company',
    CLICK_COMPANY_INFO: 'Click on the name of the company for more options',
    READ_INFO: 'View - Can view page but not grant access to others',
    ADMIN_INFO: 'Admin - Able to grant access to others',
    ACCESS_V2: 'Access v2',
    ACCESS_V3: 'Access v3',
    SET_ACCESS_ON_COMPANIES: '<< Set page access for selected companies',
    SELECT_ALL_SUBCOMPANIES: 'Select all subcompanies for selected company',
    DESELECT_ALL_SUBCOMPANIES: 'Deselect all subcompanies for selected company',
    SHOW_ACCESS: 'Show access',
    EDIT_ACCESS: 'Edit access',
    ACCESS_HIERARCHY: 'Access v2 (BETA)',
    NOTIFICATIONS_UPDATED: 'Notifications updated',
    USERS: 'Users',
    ADD_USER: 'Add user',
    CONFIRM_DELETE: 'Do you want to delete the user?',
    CREATE_ADD_EMAIL: 'Add a new user by e-mail',
    INVITE: 'Invite',
    USER_DATA: 'User data',
    ACCESS: 'Access',
    NOTIFICATIONS: 'Notifications',
    ACCESS_INFO: 'User will have access to the companies selected.',
    NOTIFICATIONS_INFO: 'User will get notifications for each company. SMS costs are applied.',
    ACCESS_GRANTED: 'Access granted',
    UNABLE_ACCESS_GRANT: 'Unable to grant access',
    ACCESS_UPDATED: 'Access updated',
    UNABLE_ACCESS_UPDATE: 'Unable to update access',
    DELETED: 'User was deleted',
    UNABLE_DELETE: 'Unable to delete user',
    REMOVED_FROM_COMPANY: 'User was removed from company',
    UNABLE_REMOVE_FROM_COMPANY: 'Unable to remove user from company',
    UNABLE_LIST_ACCESS: 'Unable to list all access',
    UNABLE_LIST_COMPANY: 'Unable to list all companies',
    UNABLE_LIST: 'Unable to list users',
    UNABLE_CREATE: 'Unable to create user',
    UPDATED: 'User updated',
    UNABLE_UPDATE: 'Unable to update user',
    INVALID_EMAIL: 'Invalid e-mail',
    EMAIL_TAKEN: 'E-mail is already registered',
    INVITATION_SENT: 'Invitation was sent',
    UNABLE_SEND_INVITATION: 'Unable to send invitation',
    EMAIL_TAKEN_INFO: 'E-mail is already registered in the system. To invite the user to the company, use the "Invite" button.',
    TITLE: 'Title',
    IS_DIRECTOR: 'Is board member',
    IS_ACCOUNTANT: 'Is accountant',
    DELETE: 'Delete user',
    NEW: 'New user',
    INVITE_USER: 'Invite user',
    NO_ACCESS: 'User has no access to any page and can not log in as a result',
    HAS_ACCESS: 'Has access',
    HAS_GRANT: 'Has grant',
    IS_SUPERADMIN: 'Is superadmin',
    SEARCH_COMPANY: 'Search company',
    MY_INFORMATION: 'My information',
    NOTIFICATIONS: 'Notifications',
    UNABLE_UPDATE_ACCESS: 'Unable to update access',
    CANT_UPDATE_YOURSELF: 'You cant grant yourself access',
    ADD_READ_ALL: 'Add read permissions for all',
    ADD_WRITE_ALL: 'Add admin permissions from all',
    REMOVE_READ_ALL: 'Remove read permissions for all',
    REMOVE_WRITE_ALL: 'Remove admin permissions from all',
    PAGES_ACCESS_INFO: 'On this page you can add or remove access for all users',
    BEARER: 'Bearer token',
    BEARER_INFO: 'Bearer token is used in Authorization header.',
    SCOPES: 'Scopes',
    SAVE_SCOPES: 'Save scopes',
    API_KEY: 'API keys',
    AREA_ALLOC: 'Company allocation',
    BILLING: 'Billing',
    TRANSACTION: 'Transaction',
    BOOK: 'Accounting',
    COMPANY_USER: 'User access',
    CARD: 'Member card',
    COMPANY: 'Company',
    CAMPAIGN: 'Campaign',
    EDUCATION: 'Education',
    EMAIL: 'Email',
    EVENTS: 'Event',
    EVENT_CATEGORY: 'Event category',
    EXTRA_DATA: 'Extra data',
    FILE: 'File',
    FILEUPLOAD: 'File upload',
    FORTNOX: 'Fortnox',
    IMPORT: 'Import',
    INVOICE: 'Invoice',
    JOB: 'Job',
    LINK: 'Link',
    LOCATION: 'Location',
    MEMBER_COMPANY: 'Membership',
    MEMBER_EVENT: 'Event participation',
    MEMBER_PROPERTY: 'Member attribute',
    MEMBER_RENEW: 'Member renew',
    MEMBER_STICKPROV: 'Member sample',
    MEMBER: 'Member',
    PAGE: 'Page',
    PAYMENT: 'Payment',
    PERIOD: 'Period',
    PERSON: 'Person',
    PROPERTY_GROUP: 'Attribute group',
    REDEEM_CODE: 'Coupon',
    SEARCH: 'Search',
    SENDOUT: 'Sendout',
    SHOP_ITEM: 'Shop article',
    SHOP_ORDER: 'Order',
    SMS: 'SMS',
    SPAR: 'SPAR',
    STICKPROV: 'Sample',
    STRIPE: 'Stripe',
    SYSTEM: 'System',
    TEMPLATE: 'Template',
    TODO_TASK: 'Todo task',
    TODO: 'Todo/Grant',
    TRACKING: 'Tracking',
    USER: 'User',
    TITLE: 'Title',
    MEMBERTYPE: 'Member type',
    WEBHOOK: 'Webhook',
    AUTH_TOKEN: 'Authentication',
    UNABLE_LIST_AUTH: 'Unable to list authentication',
    AUTH_DELETED: 'Authentication deleted',
    AUTH_CREATED: 'Authentication created',
    SHOW_BEARER_INFO: 'You must copy the bearer token now. It\'s not possible to copy the token at a later time.',
    SHOW_TOO_LATE: 'Previously created authentication tokens can not be viewed.',
    FULL_ACCESS: 'Give full access',
    ACCESS_GIVEN: 'Access granted',
    TAB_USERS: 'Users',
    TAB_TERMS: 'User terms',
    TAB_GROUPS: 'Groups',
    TABLE_HEADER_TERMS: 'Terms',
    ACCEPTED_TERM: 'Accepted',
    NOTIF_EMAIL: 'Email notifications',
    NOTIF_SMS: 'SMS notifications',
    USER_ID: 'UserID',
    FIRSTNAME: 'Firstname',
    LASTNAME: 'Lastname',
    EMAIL: 'Email',
    VERIFY_PASSWORD: 'Input user password',
    INPUT_PASSWORD: 'Password',
    INVALID_PASSWORD: 'Invalid password',
    GET_TOKEN: 'Get token',
    ROLE: 'Role',
    ROLES: {
      ADMIN: 'Admin',
      OWNER: 'Owner',
      USER: 'User',
      NONE: 'None'
    },
    DETAILS: 'Details',
    TERMS: 'Terms',
    ID: 'UserID',
    USER_ID: 'UserID',
    NAME: 'Name/email',
    GROUP: 'Group',
    CREATE: 'Create user',
    UNABLE_GET: 'Unable to get user',
    UNABLE_LIST: 'Unable to list users',
    UNABLE_DELETE: 'Unable to delete user',
    UNABLE_CREATE: 'Unable to create user',
    UNABLE_UPDATE: 'Unable to update user',
    CREATED: 'User created',
    UPDATED: 'User updated',
    DELETED: 'User deleted',
    EMAIL: 'E-mail',
    PHONE: 'Phone',
    FIRSTNAME: 'Firstname',
    LASTNAME: 'Lastname',
    PASSWORD: 'Password',
    PASSWORD_REPEAT: 'Repeat password',
    PASSWORD_INFO: 'Leave empty to keep current password',
    PASSWORDS_MISMATCH: 'Passwords mismatch',
    PASSWORDS_6CHAR: 'Password must be at least 6 characters',
    PASSWORD_UPDATED: 'Password was updated',
    CREATED_AT: 'Created at',
    PERSONNR: 'Swedish Personnummer'
  },
  ATTRIBUTE: {
    PRIORITY: 'Priority',
    UNABLE_SAVE: 'Unable to save attribute',
    FREE_TEXT: 'Free text',
    IS_FREE_TEXT: 'Is free text',
    DESCR_PLACEHOLDER: 'Visible text',
    DESCR: 'Visible text',
    NAME: 'Internal name',
    EDIT_ATTRIBUTE: 'Edit attribute',
    SAVE: 'Save attribute',
    CREATED: 'Attribute created',
    UNABLE_CREATE: 'Unable to create attribute',
    UNABLE_LIST: 'Unable to list attributes',
    FORM_INVALID: 'Form is invalid',
    UPDATED: 'Attribute updated',
    UNABLE_UPDATE: 'Unable to update attribute',
    DELETED: 'Attribute deleted',
    UNABLE_DELETE: 'Unable to delete attribute',
    ALREADY_DEFINED: 'Attribute already defined',
    TEXT_TOO_LONG: 'Text is too long',
  },
  HISTORY: {
    MEMBER: 'Member',
    USER: 'User',
    HISTORY_TYPE: {
      LOGIN_FAIL: 'Login failure',
      LOGIN_SUCCESS: 'Login success',
      SEARCH_MEMBERS: 'Search members',
      SEARCH_USERS: 'Search users',
      VIEW_MEMBER: 'View member',
      VIEW_USER: 'View user',
      EDIT_MEMBER: 'Editing member',
      EDIT_USER: 'Editing user',
      DELETE_MEMBER: 'Deleting member',
      DELETE_USER: 'Deleting user',
      CREATE_MEMBER: 'Creating member',
      CREATE_USER: 'Creating user',
    },
    FIELD: 'Field',
    OLD_VALUE: 'Old value',
    NEW_VALUE: 'New value',
    NO_DATA_FOUND: 'No data found',
    GROUP: 'Group',
    DATA: 'Changed data',
    INSPECTOR: 'Inspecting history',
    HISTORY_SETTINGS_UPDATED: 'Settings updated',
    UNABLE_UPDATE_HISTORY_SETTINGS: 'Unable to update settings',
    UNABLE_LOAD_HISTORY_SETTINGS: 'Unable to load history settings',
    NAME: 'Inställning',
    SETTINGS: 'Settings',
    CREATE: 'Create',
    VIEW: 'View',
    UPDATE: 'Update',
    DELETE: 'Delete',
    LOGIN: 'Login',
    ACTIVATE: 'Activate',
    HISTORY: 'Event log',
    CREATED_AT: 'Date',
    TYPE: 'Type',
    CREATED_BY: 'Author',
    USERS: 'User log',
    MEMBERS: 'Member log',
    USER: 'Användare',
    MEMBER: 'Member',
    CREATED_BY_TYPE: 'Role',
    NO_FOUND: 'No events found',
    NUM_ROWS: 'Number of events',
    DETAILS: 'Details',
    UNABLE_GET_USER: 'Unable to get user',
    UNABLE_GET_MEMBER: 'Unable to get member',
  },
  SETTINGS: {
    STRIPE_ACCESS_TOKEN: 'Stripe Access Key (SK)',
    STRIPE_PK_TOKEN: 'Stripe Publishable Key (PK)',
    INVALID_STRIPE_ACCESS_TOKEN: 'Invalid Stripe Access Key',
    INVALID_STRIPE_PK_TOKEN: 'Invalid Stripe Publishable Key',
    ENABLED_STRIPE: 'Stripe enabled',
    ENABLED_SWISH: 'Swish enabled',
    ENABLED_INVOICE: 'Pay with invoice enabled',
    SWISH_SETTINGS: 'Swish settings',
    STRIPE_SETTINGS: 'Stripe settings',
    INVALID_SWISH_PAYEE_ALIAS: 'Invalid Swish payee alias',
    SWISH_PAYEE_ALIAS: 'Swish payee alias',
    VERIFY_SWISH_SETTINGS: 'Verify Swish settings',
    SWISH_TEST_SUCCESS: 'Swish verification successful',
    SWISH_TEST_FAILURE: 'Swish verification failed',
    SWISH_OPERATIONAL: 'Operational',
    INVOICE_PAYMENTS: 'Invoice payments'
  },
  EDUCATION: {
    STATUSES: {
      FINISHED: 'Finished',
      RUN_FIRST_IMPORT: 'Validating...',
      DONE_FIRST: 'Done validation',
      FAIL_FIRST: 'Error validation',
      RUN_FIN_IMPORT: 'Importing...',
      DONE_FINAL: 'Done import',
      FAIL_FINAL: 'Fail import',

      RUN_FAST_FIRST_IMPORT: 'Preparing import...',
      DONE_FAST_FIRST_IMPORT: 'Ready to import',
      RUN_FAST_FINAL_IMPORT: 'Importing...',
      DONE_FAST_FINAL_IMPORT: 'Import finished',
      FAIL_FAST_FIRST_IMPORT: 'Error during preparation',
      FAIL_FAST_FINAL_IMPORT: 'Error during import',
      VALIDATING: 'Validating...',
      VALIDATED_OK: 'Validation OK',
      VALIDATED_ERROR: 'Validation error',

      OK: 'Klart',
      FAIL: 'Fel'
    },
    ACTIVATE_EDUCATIONS: 'Activate educations',
    TYPE: 'Type',
    ADDED: 'Education was added',
    UNABLE_ADD: 'Unable to add education',
    UNABLE_DELETE: 'Unable to delete education',
    TYPES: {
      NONE: 'None',
      PROGRAM: 'Program',
      COURSE: 'Course',
      FREE_COURSE: 'Free course',
      UNKNOWN: 'Unknown'
    },
    NUM_ROWS: 'Number of rows',
    STUDENT_STATUS: 'Student status',
    STUDENT_STATUSES: {
      UNKNOWN: 'Unknown',
      NONE: 'None',
      STUDENT: 'Student',
      NOT_STUDENT: 'Not student'
    },
    EDUCATION: 'Education',
    EDUCATIONS: 'Educations',
    NO_EDUCATIONS_FOUND: 'No educations found',
    NAME: 'Name',
    SHORTNAME: 'Short Name',
    UNABLE_GET: 'Unable to retrieve education',
    UNABLE_LIST: 'Unable to list educations',
    UNABLE_DELETE: 'Unable to delete education',
    UNABLE_CREATE: 'Unable to create education',
    UNABLE_UPDATE: 'Unable to update education',
    CREATE: 'Create education',
    UPDATE: 'Update education',
    DELETE: 'Delete education',
    CONFIRM_DELETE: 'Are you sure you want to delete the education?',
    CONFIRM_DELETE_MULTIPLE: 'Are you sure you want to delete the educations?',
    CREATE_SUCCESS: 'Education created',
    UPDATE_SUCCESS: 'Education updated',
    DELETE_SUCCESS: 'Education deleted',
    DELETE_MULTIPLE_SUCCESS: 'Educations deleted',
    CREATE_ERROR: 'Could not create education',
    UPDATE_ERROR: 'Could not update education',
    DELETE_ERROR: 'Could not delete education',
    DELETE_MULTIPLE_ERROR: 'Could not delete educations',
    SELECT_EDUCATION: 'Select education',
    SELECT_EDUCATION_PLACEHOLDER: 'Select education...',
    SECTION: 'Section',
    PERIOD: 'Period',
    CODE: 'Code',
    LADOK_ROWS: 'Ladok rows',
    STUDENT_ID: 'Student-ID',
    COURSE_CODE: 'Course code',
    PROGRAM_CODE: 'Program code',
    FACULTY: 'Faculty',
    CREATED_AT: 'Created at',
    NO_LADOK_ROWS: 'No LADOK rows',
    LADOK_FILE_ROW_ID: 'LADOK row ID',
    LADOK_FILE_ID: 'LADOK file ID',
    NO_FOUND: 'No educations found',
  },
  FILE: {
    UPLOADED: 'File uploaded',
    UPLOAD_FILE: 'Upload file',
    VISIBLE_MEMBER_TYPE: 'Available for member type',
    MEMBER_TYPE_ANY: 'None',
    HEADER: 'Files',
    FILE_ID: 'File ID',
    FILENAME: 'Filename',
    SIZE_KB: 'File size',
    CREATED_BY: 'Created by',
    UPLOAD_TO_FORTNOX: 'Upload to Fortnox',
    CREATED_AT: 'Created at',
    NO_FILES: 'No files found',
    DOWNLOAD: 'Download',
    DELETED: 'File deleted',
    UNABLE_DELETE: 'Unable to delete file',
    UPDATED: 'File updated',
    UNABLE_UPDATE: 'Unable to update file',
    UPLOADED: 'File uploaded',
    UNABLE_UPLOAD: 'Unable to upload file',
    CREATE: 'Upload',
    UNABLE_CREATE: 'Unable to create file',
    UPLOAD: 'Upload',
    ALLOW_UPLOAD: 'Allow file upload',
  },
  SKV: {
    UPDATED: 'Skatteverket status updated',
    UNABLE_UPDATE: 'Unable to update',
    SKV_STATUS: 'Skatteverket Status',
    SKV_PERIOD: 'Skatteverket Period',
    COMPANY: 'Congregation',
    MEMBER_COMPANY_UPDATED: 'Membership updated',
    UNABLE_UPDATE_MEMBER_COMPANY: 'Unable to update member company',
    SKV_STATUSES: {
      IGNORE: 'Ignore',
      MINOR_IGNORE: 'Minor - Ignore',
      REGISTERED: 'Registered',
      DELETED: 'Removed',
      PEN_REGISTER: 'Pending registration',
      PEN_DELETED: 'Pending deletion',
      ERR_PERSONNR: 'Error on personnr',
      ERR_DUPLICATE: 'Errorr - Duplicate',
    }
  },

  ACCESS: {

    TOKEN: {
      API_ACCESS: 'Api access',
      REFRESH_TOKEN: 'Refresh Token',
      CREATED_AT: 'Skapad'
    },

    GROUP: {
      NEW_GROUP: 'New group',
      DETAILS: 'Details',
      COMPANIES: 'Companies',
      PERMISSIONS: 'Rights',
      PAGES: 'Pages',
      GROUPS: 'Groups',
      NAME: 'Group name',
      GROUP_ID: 'Group ID',
      HAS_ACCESS: 'Access',
      ID: 'Group ID',
      CREATED_AT: 'Created date',
      CREATE: 'Create group',
      CREATED: 'Group created',
      UPDATED: 'Group updated',
      DELETED: 'Group deleted',
      USER_REMOVED: 'User removed from group',
      UNABLE_CREATE: 'Unable to create group',
      UNABLE_UPDATE: 'Unable to update group',
      UNABLE_DELETE: 'Unable to delete group',
      UNABLE_GET: 'Unable to get group',
      UNABLE_GET_HIERARCHY: 'Unable to get hierarchy',
      UNABLE_REMOVE_COMPANY: 'Unable to remove company',
      UNABLE_REMOVE_USER: 'Unable to remove user',
      UNABLE_ADD_COMPANY: 'Unable to add company',
    },

    PAGE: {
      NAME: 'Page',
      ACCESS: 'Access',
      UNABLE_ADD: 'Unable to add page',
      UNABLE_REMOVE: 'Unable to remove page',
      UNABLE_GET: 'Unable to get pages',
    },

    PERMISSIONS: {
      NAME: 'Permission',
      UNABLE_ADD: 'Unable to add permission',
      UNABLE_REMOVE: 'Unable to remove permission',
      UNABLE_GET: 'Unable to get permissions',
      UNABLE_SET: 'Unable to set permissions',

      PERMISSION: {
        A: 'Admin',
        R: 'Read',
        N: 'None',
      },

      MEMBER: {
        NAME: 'Member',
        C: 'Create member',
        R: 'Read member',
        U: 'Update member',
        D: 'Delete member',
        S: 'Search members',
        PUBLIC_ID: 'Change member number'
      },
      USER: {
        NAME: 'User',
        C: 'Create user',
        R: 'Read user',
        U: 'Update user',
        D: 'Delete user'
      },
      EVENT: {
        NAME: 'Event',
        C: 'Create event',
        R: 'Read event',
        U: 'Update event',
        D: 'Delete event',
        VIEW_PARTICIPANTS: 'View participants',
        EDIT_PARTICIPANTS: 'Edit participants',
      },

      COMPANY: {
        NAME: 'Company',
        C: 'Create company',
        R: 'Read company',
        U: 'Update company',
        D: 'Delete company'
      },
      BILLING: {
        NAME: 'Billing',
        C: 'Create billing',
        R: 'Read billing',
        U: 'Update billing',
        D: 'Delete billing'
      },
      CAMPAIGN: {
        NAME: 'Campaign',
        C: 'Create campaign',
        R: 'Read campaign',
        U: 'Update campaign',
        D: 'Delete campaign'
      },
      COMPANY_PERIOD: {
        NAME: 'Company period',
        C: 'Create company period',
        R: 'Read company period',
        U: 'Update company period',
        D: 'Delete company period'
      },
      EDUCATION: {
        NAME: 'Education',
        C: 'Create education',
        R: 'Read education',
        U: 'Update education',
        D: 'Delete education'
      },
      SENDOUT: {
        NAME: 'Sendout',
        C: 'Create sendout',
        R: 'Read sendout',
        U: 'Update sendout',
        D: 'Delete sendout'
      },
      FILE: {
        NAME: 'File',
        C: 'Create file',
        R: 'Read file',
        U: 'Update file',
        D: 'Delete file'
      },
      IMPORT: {
        NAME: 'Import',
        C: 'Create import',
        R: 'Read import',
        U: 'Update import',
        D: 'Delete import'
      },
      INVOICE: {
        NAME: 'Invoice',
        C: 'Create invoice',
        R: 'Read invoice',
        U: 'Update invoice',
        D: 'Delete invoice'
      },
      LADOK: {
        NAME: 'Ladok',
        C: 'Create ladok',
        R: 'Read ladok',
        U: 'Update ladok',
        D: 'Delete ladok'
      },
      HISTORY: {
        NAME: 'History',
        C: 'Create history',
        R: 'Read history',
        U: 'Update history',
        D: 'Delete history'
      },
      FORTNOX: {
        NAME: 'Fortnox',
        C: 'Create fortnox',
        R: 'Read fortnox',
        U: 'Update fortnox',
        D: 'Delete fortnox'
      },
      MEMBER_COMPANY: {
        NAME: 'Memberships',
        C: 'Create membership',
        R: 'Read membership',
        U: 'Update membership',
        D: 'Delete membership'
      },
      MEMBER_RENEW: {
        NAME: 'Renewals',
        C: 'Create renewal',
        R: 'Read renewal',
        U: 'Update renewal',
        D: 'Delete renewal'
      },
      MEMBER_INSURANCE: {
        NAME: 'Insurances',
        C: 'Create insurance',
        R: 'Read insurance',
        U: 'Update insurance',
        D: 'Delete insurance'
      },
      MEMBER_PROPERTY: {
        NAME: 'Properties',
        C: 'Create property',
        R: 'Read property',
        U: 'Update property',
        D: 'Delete property'
      },
      STICKPROV: {
        NAME: 'Samples',
        C: 'Create sample',
        R: 'Read sample',
        U: 'Update sample',
        D: 'Delete sample'
      },
      PERIOD: {
        NAME: 'Period',
        C: 'Create period',
        R: 'Read period',
        U: 'Update period',
        D: 'Delete period'
      },
      PERSON: {
        NAME: 'Person',
        C: 'Create person',
        R: 'Read person',
        U: 'Update person',
        D: 'Delete person'
      },
      SEARCH: {
        NAME: 'Search',
        C: 'Create search',
        R: 'Read search',
        U: 'Update search',
        D: 'Delete search'
      },
      SHOP_ITEM: {
        NAME: 'Articles',
        C: 'Create article',
        R: 'Read article',
        U: 'Update article',
        D: 'Delete article'
      },
      SHOP_ORDER: {
        NAME: 'Orders',
        C: 'Create order',
        R: 'Read order',
        U: 'Update order',
        D: 'Delete order'
      },
      SMS: {
        NAME: 'SMS',
        C: 'Create SMS',
        R: 'Read SMS',
        U: 'Update SMS',
        D: 'Delete SMS'
      },
      SKV: {
        NAME: 'Skatteverket',
        C: 'Create Skatteverket',
        R: 'Read Skatteverket',
        U: 'Update Skatteverket',
        D: 'Delete Skatteverket'
      },
      TEMPLATE: {
        NAME: 'Templates',
        C: 'Create template',
        R: 'Read template',
        U: 'Update template',
        D: 'Delete template'
      },
      SYSTEM: {
        NAME: 'System',
        C: 'Create system',
        R: 'Read system',
        U: 'Update system',
        D: 'Delete system'
      },
      TODO: {
        NAME: 'Todo/Taskmanager',
        C: 'Create todo',
        R: 'Read todo',
        U: 'Update todo',
        D: 'Delete todo'
      },
      TRANSACTION: {
        NAME: 'Transaction',
        C: 'Create transaction',
        R: 'Read transaction',
        U: 'Update transaction',
        D: 'Delete transaction'
      },
      WEBHOOK: {
        NAME: 'Webhook',
        C: 'Create webhook',
        R: 'Read webhook',
        U: 'Update webhook',
        D: 'Delete webhook'
      },
      API: {
        NAME: 'API',
        C: 'Create API',
        R: 'Read API',
        U: 'Update API',
        D: 'Delete API'
      },
      TODO_TASK: {
        NAME: 'Task',
        C: 'Create task',
        R: 'Read task',
        U: 'Update task',
        D: 'Delete task'
      },
      BANKID: {
        NAME: 'BankId',
        C: 'Create BankId',
        R: 'Read BankId',
        U: 'Update BankId',
        D: 'Delete BankId'
      },
      EMAIL: {
        NAME: 'Email',
        C: 'Create Email',
        R: 'Read Email',
        U: 'Update Email',
        D: 'Delete Email'
      },
      INSTRUMENT: {
        NAME: 'Instrument',
        C: 'Create Instrument',
        R: 'Read Instrument',
        U: 'Update Instrument',
        D: 'Delete Instrument'
      },
      INVOICE_TEMPLATE: {
        NAME: 'Invoice template',
        C: 'Create Invoice template',
        R: 'Read Invoice template',
        U: 'Update Invoice template',
        D: 'Delete Invoice template'
      },
      MEMBER_TYPE: {
        NAME: 'Member type',
        C: 'Create Member type',
        R: 'Read Member type',
        U: 'Update Member type',
        D: 'Delete Member type'
      },
      PAYMENT: {
        NAME: 'Payment',
        C: 'Create Payment',
        R: 'Read Payment',
        U: 'Update Payment',
        D: 'Delete Payment'
      },
      REDEEM_CODE: {
        NAME: 'Redeem code',
        C: 'Create Redeem code',
        R: 'Read Redeem code',
        U: 'Update Redeem code',
        D: 'Delete Redeem code'
      },
      SAMFUND: {
        NAME: 'Samfund',
        C: 'Create Samfund',
        R: 'Read Samfund',
        U: 'Update Samfund',
        D: 'Delete Samfund'
      },
      SPAR: {
        NAME: 'SPAR',
        C: 'Create SPAR',
        R: 'Read SPAR',
        U: 'Update SPAR',
        D: 'Delete SPAR'
      },
      TITLE: {
        NAME: 'Titles',
        C: 'Create title',
        R: 'Read title',
        U: 'Update title',
        D: 'Delete title'
      },
      TRACKING: {
        NAME: 'Tracking',
        C: 'Create tracking',
        R: 'Read tracking',
        U: 'Update tracking',
        D: 'Delete tracking'
      },
      CARD: {
        NAME: 'Card',
        C: 'Create card',
        R: 'Read card',
        U: 'Update card',
        D: 'Delete card'
      },
      COMPANY_TERM: {
        NAME: 'Company terms',
        C: 'Create company terms',
        R: 'Read company terms',
        U: 'Update company terms',
        D: 'Delete company terms'
      },
      BOOK: {
        NAME: 'Accounting',
        C: 'Create accounting',
        R: 'Read accounting',
        U: 'Update accounting',
        D: 'Delete accounting'
      },
      REGISTRATION: {
        NAME: 'Registration',
        C: 'Create registration',
        R: 'Read registration',
        U: 'Update registration',
        D: 'Delete registration'
      },
      AUTH: {
        NAME: 'Authentication',
        C: 'Create authentication',
        R: 'Read authentication',
        U: 'Update authentication',
        D: 'Delete authentication'
      },
      ACCESS: {
        NAME: 'Access',
        C: 'Create access',
        R: 'Read access',
        U: 'Update access',
        D: 'Delete access'
      },
      THS: {
        NAME: 'THS',
        C: 'Create THS',
        R: 'Read THS',
        U: 'Update THS',
        D: 'Delete THS'
      },
      SERVER: {
        NAME: 'Server',
        C: 'Create server',
        R: 'Read server',
        U: 'Uppdate server',
        D: 'Delete server'
      }
    },

    TERMS: {
      ACCEPTED_AT: 'Accepted at',
      NAME: 'Terms',
      NO_TERMS: 'No terms accepted'
    }
  },

  BOOK: {
    STATUS: 'Status',
    ERROR: 'Error message',
    CREATED_AT: 'Created at',
    SEARCH_VOUCHER: 'Search voucher',
    ADD_ROW: 'Add row',
    REMOVE_ROW: 'Remove row',
    BOOKIT: 'Create voucher',
    CONFIRM_DELETE: 'Are you sure you want to delete the voucher?',
    CHECK_FORTNOX_STATUS: 'Checking Fortnox status...',
    FORTNOX_NOT_CONNECTED: 'Fortnox is not connected for this association and therefore it is not possible to create vouchers in Fortnox',
    FORTNOX_IS_CONNECTED: 'Fortnox is connected',
    VOUCHERS: 'Vouchers',
    BOOKED: 'Booked',
    NOT_BOOKED: 'Not Booked',
    UPLOAD_DINTERO_FILE: 'Upload Dintero file',
    CREATE_VOUCHER: 'Create voucher',
    VOUCHER_NUMBER: 'Voucher number',
    MESSAGE: 'Message',
    ROWS: 'Rows',
    ACCOUNT: 'Account',
    COST_CENTER: 'Cost center',
    PROJECT: 'Project',
    DEBIT: 'Debit',
    CREDIT: 'Credit',
    DESCRIPTION: 'Description',
    VOUCHER_CREATED: 'Voucher created',
    UNABLE_CREATE_VOUCHER: 'Unable to create voucher',
    VOUCHER_UPDATED: 'Voucher updated',
    UNABLE_UPDATE_VOUCHER: 'Unable to update voucher',
  },

  FORTNOX: {
    FORTNOX: 'Fortnox',
    UNABLE_CHECK_STATUS: 'Unable to check status for Fortnox',
    API_SETTINGS_SAVED: 'Fortnox API details saved',
    UNABLE_UPDATE_API: 'Unable to update API connection',
    DELETED_API_CONNECTION: 'Fortnox API connection removed',
    UNABLE_GET_VOUCHER_SERIES: 'Unable to fetch Fortnox Voucher Series',
    ACCOUNTING_METHOD: 'Accounting Method',
    API_ERROR: 'An error has occurred with the Fortnox connection',
    IS_CONNECTED: 'Fortnox is connected to this association',
    PAYMENT_ACCOUNT: 'Account for payment (four digits)',
    FEE_ACCOUNT: 'Account for Dintero’s transaction fees (four digits)',
    VOUCHER_SERIES: 'Voucher Series (one letter)',
    LEV_ACCOUNT: 'Account for Supplier Debt (Invoice Method)',
    CASH_METHOD: 'Cash Method',
    INVOICING_METHOD: 'Invoice Method',
    ENABLE_AUTO_BOOK_INFO: 'Automatic accounting takes place after a transaction has occurred against your bank through a so-called "Settlement". This transaction will have the sum of the payment minus the transaction fee. This means that the voucher created will be ITEM COST MINUS FEE',
    ENABLE_AUTO_BOOK: 'Enable automatic accounting',
    BUNDLE_BOOKS_INFO: 'This option means that all transactions will end up in the same voucher, if the checkbox is unchecked then each transaction will be its own voucher.',
    BUNDLE_BOOKS: 'Group transactions in the same voucher',
    ENABLE_FORTNOX_INVOICE_INFO: 'Fortnox invoices are created through the menu option "Fortnox Invoices"',
    ENABLE_FORTNOX_INVOICE: 'Enable Fortnox Invoices',
    DELETE_CONNECTION: 'Remove connection',
    AUTHORIZE_INSTRUCTIONS: 'To connect Fortnox to this association, authorize our integration by clicking the button below and log in if you are prompted',
    AUTHORIZE: 'Authorize Fortnox',

  },

  DUPLICATE: {
    TAB_HEADER: 'Duplicates',
    MERGE_MEMBER_OPTION: 'Merge member options',
    MERGE_MEMBERS_INFORMATION: 'Members will be merged based on the option below. However, member data that is missing from one record will be kept on the final record.',
    MERGE_MEMBER_OPTIONS: {
      KEEP_NEWEST_CREATED: '',
      KEEP_OLDEST_CREATED: '',
    },
    MERGE_MEMBERSHIP_INFORMATION: 'Memberships will from the other member records will be moved into the final record. The option below will determine which memberships will be kept.',
    MERGE_MEMBERSHOP_OPTION: 'Merge membership options',
    MERGE_MEMBERSHIP_OPTIONS: {
      KEEP_ALL: '',
      REMOVE_EXACT_DUPLICATES: ''
    },
    EXCEPTIONS: 'Exceptions',
    CONFLICT_INFORMATION: 'Some records will cause conflicts if moved. If any of these conditions are true, the merge will abort on that particular member:',
    CONFLICT_INFORMATION1: 'If each member has an active renewal record. Only one renewal may be active on any member.',
    CONFLICT_INFORMATION2: 'If one member is a head member, and the other is a non head member.',

  },

  SCANQR: {
    REDIRECTING: 'Redirecting...',
    NO_TOKEN: 'No token',
    NO_MEMBER: 'No member'
  },

  SYSTEM: {
    ONLY_THS: 'THS Only',
    UPDATED: 'System updated',
    UNABLE_UPDATE: 'Unable to update system',
    TEMPLATE: {
      INVITE_BY_EMAIL_TEMPLATE: 'Invitation to system',
      CONTACT_FORM: 'Contact Form',
      STICKPROV_SMS: 'Random Sample SMS',
      STICKPROV_EMAIL: 'Random Sample Email',
      SUBCOMPANY_REQUEST: 'To applicant: Affiliate Application',
      SUBCOMPANY_ACCEPT: 'To applicant: Affiliate Accepted',
      SUBCOMPANY_REJECT: 'To applicant: Affiliate Rejected',
      PARENT_COMPANY_REQUEST: 'To parent company: Affiliate Application',
      GRANT_FINALIZED: 'To applicant: Application is finalized and closed',
      GRANT_MUST_HANDIN: 'To applicant: Applicant organization must submit receipts for grant',
      GRANT_REJECT: 'To applicant: Applicant organization is rejected for grant',
      GRANT_ACCEPT: 'To applicant: Applicant organization is accepted for grant',
      GRANT_CREATED: 'To parent company: Grant Application Created',
      GRANT_HAS_HANDIN: 'To parent company: Applicant organization has submitted receipts',
      GRANT_DELETED: 'To parent company: Applicant organization has deleted the application',
      EVENT_JOINED: 'When member/person has joined the event',
      MEMBER_JOINED: 'Member created',
      MEMBER_RENEW_PERIOD_SMS: 'Membership Renewal SMS',
      MEMBER_RENEW_REMIND_SMS: 'Membership Renewal Reminder SMS',
      MEMBER_RENEW_CONFIRM_SMS: 'Membership Renewal Confirmation SMS',
      MEMBER_RENEW_PERIOD_EMAIL: 'Membership Renewal Email',
      MEMBER_RENEW_REMIND_EMAIL: 'Membership Renewal Reminder Email',
      MEMBER_RENEW_CONFIRM_EMAIL: 'Membership Renewal Confirmation Email',
      PAYMENT_REMINDER: 'Member Missing Payment',
      PAYMENT_RECIEVED: 'Member Payment Received',
      PAYMENT_FAIL: 'Member Payment Failed',
      INVITE_NONMEMBER_FROM_EVENT: 'To non-member, invitation to become a member through event',
      REDEEM_CODE_EMAIL: 'Coupon with code (Email)',
      MEMBER_APPLICATION_PENDING_TEMPLATE_ID: 'Member application: Pending (Email)',
      MEMBER_APPROVED_EMAIL_TEMPLATE_ID: 'Member application: Approved (Email)',
      MEMBER_REJECTED_EMAIL_TEMPLATE_ID: 'Member application: Rejected (Email)',
      ADMIN_EXIT_REQUESTED_TEMPLATE_ID: 'Admin: Exit requested notification (Email)',
    }
  },

  RENEW_WIZARD: {
    UNABLE_GET_DATA: 'Unable to get data. Did you already renew your membership?',
    ERROR_NOT_RENEWED1: 'Your membership was not renewed, try again later.',
    ERROR_ALREADY_RENEWED1: 'Your membership is already renewed. This link is invalid.',
    MEMBERSHIP_RENEWED: 'Your membership has been renewed!',
    MEMBERSHIP_RENEWED_HEADER: 'Renewed',
    ALREADY_RENEWED: 'Already renewed'
  },


  COMPANY_PERMISSION: {
    GROUP: 'Group',
    USER: 'User',
    ASSIGNED_GROUP: 'Assigned',
    NAME: 'Name',
    READ: 'Read',
    ADMIN: 'Admin',
    YES: 'Yes',
    NO: 'No',
    PAGES: 'Pages',
    ASSIGNED: 'Access({num})',
    NOT_ASSIGNED: 'No access',
    PERMISSIONS: 'Rights'
  },

  LADOK: {
    MEMBER: 'Member', 
    PERIOD: 'Period',
    
    STATUS: 'Status',
    STATUSES: {
      MEMBER: 'Medlem',
      NOT_MEMBER: 'Not medlem',
      ALL: 'All'
    },
    SEARCH_INPUT: 'Search',
    SEARCH: 'LADOK Search',
    SEARCH_HEADER: 'Search',
    SEARCH_PLACEHOLDER: 'StudenID, Member number, SSN...',
    KURSKOD: 'Coursecode',
    PROGRAMKOD: 'Programcode',
    
    FILE_ERRORS: {
      NOT_ENOUGH_CAPTIONS: 'Not enough columns',
      MISSING_HEADER: 'Missing column with name',
      INVALID_PERSONNR: 'Invalid personnr',
      NO_DELIMITER: 'Missing delimiter',
      NO_ROWS: 'No rows'
    },
    UNABLE_CREATE: 'Unable to create LADOK job',
    UNABLE_START: 'Unable to start LADOK job',
    CREATED: 'LADOK job created',
    STARTED: 'LADOK job started',
    UNABLE_UPLOAD: 'Unable to upload file',
    UNKNOWN_ERROR: 'An unknown error has occurred',
    ERROR_IN_FILE: 'A file error has occurred',
    ERROR_REASON: 'Error:',
  },


  COMPANY: {
    POSTMARK_INBOUND_EMAIL: 'Inbound e-mail',
    COUNT: '#',
    SELECTED_COMPANY: 'Selected company',
    SELECT_TO_EDIT: 'Click on row below to edit',
    NEW: 'New company',
    EDIT: 'Edit company',
    CREATE: 'Create company',
    UPDATE: 'Update company',
    DELETE: 'Delete company',

    COMPANY_NAME: 'Company name',
    CNUM_ALREADY_EXISTS: 'Company with number {cnum} already exists',
    COMPANY_HIERARCHY: 'Company hierarchy',
    YOU_ARE_EDITING: 'You are editing company {company}',
    YOU_ARE_CREATING: 'You will create a subcompany to {company}',
    CONFIRM_DELETE: 'Are you sure you want to delete the company?',
    DELETE_HEADER_CONFIRM: 'Confirm delete',
    SUBCOMPANIES: 'Subcompanies',
    COMPANIES: 'Companies',
    COMPANY: 'Company',
    REG_DATE: 'Reg date',
    VFDT: 'From date',
    VTDT: 'To date',
    ADD_MEMBERSHIP: 'Add membership',
    COMPANY_ID: 'Company ID',
    NAME: 'Name',
    SHORTNAME: 'Shortname',
    UPLOAD_LOGO: 'Upload logo',
    CNUM: 'Company number',
    TYPE: 'Type',
    ORG_NUMBER: 'Org. number',
    VAT: 'VAT number',
    MPID: 'Member sequence number',
    PC_ID: 'Parent company ID',
    STREET: 'Address',
    CO: 'C/o',
    ZIPCODE: 'Postal code',
    POST: 'City',
    REGION_CODE: 'Region',
    COMMUNE_CODE: 'Commune',
    EMAIL: 'E-post (not published)',
    PHONE: 'Phone (not published)',
    LANDLINE: 'Landline',
    C_NAME: 'Contact name',
    C_EMAIL: 'Contact e-mail',
    C_PHONE: 'Contact phone',
    WEB: 'Website',
    MUCF_INCLUDE: 'Include in MUCF calculations',
    PCINC: 'Include statistics in parent company',
    RIKS: 'National Federation',
    DEFAULT: 'Company/Association',
    STUDENT: 'Student',
    WORKGROUP: 'Workgroup',
    UNABLE_GET: 'Unable to get company',
    UNABLE_GET_NOTES: 'Unable to get notes',
    UNABLE_LIST: 'Unable to list company',
    UNABLE_DELETE: 'Unable to delete company',
    UNABLE_CREATE: 'Unable to create company',
    UNABLE_UPDATE: 'Unable to update company',
    UNABLE_UPDATE_NOTES: 'Unable to update notes',
    CREATED: 'Company created',
    UPDATED: 'Company updated',
    UPDATED_NOTES: 'Notes updated',
    DELETED: 'Company deleted',
    ALL_COMPANIES: 'All companies',
    CONFIRM_DELETE: 'Confirm delete',
    CONFIRM_DELETE_MESSAGE: 'Are you sure you want to delete the company?',
    RENEWABLE: 'Renewable',
    THS_NOTES: 'THS Notes',
    ACCOUNT_NO: 'Account number',
    'ADDRESS+ZIPCODE+POSTADDRESS': 'Address+Postcode+City',
    BANK_NAME: 'Bank',
    BG: 'Bankgiro',
    PG: 'Plusgiro',
    CREATED_AT: 'Created date',
    IBAN: 'IBAN',
    PC_CNUM: 'Parent company',
    SKIP: 'Skip',
    SWIFT_BIC: 'SWIFT/BIC',
    'ZIPCODE+POSTADDRESS': 'Postcode+City',
    CLEARING_NO: 'Clearing number',
    SWISH: 'Swish',
    INVOICE_HEADING: 'Invoice address',
    INVOICE_CO: 'C/o',
    INVOICE_ADDRESS: 'Address',
    INVOICE_ZIPCODE: 'Postal code',
    INVOICE_POST: 'City',
    INVOICE_COUNTRY: 'Country',
    INVOICE_EMAIL: 'E-mail',
  },
  ONBOARDING: {
    CREATE: 'Create',
    NAME: 'Onboarding name',
    CREATE_ONBOARDING: 'Create onboarding',
    NEW_STEP: 'New step',
    NEW_GROUP: 'New group',
    GROUP_NAME: 'Group name',

    CREATED: 'Onboarding created',
    STEP_CREATED: 'Onboarding step created',
    UPDATED: 'Onboarding updated',
    UNABLE_CREATE: 'Unable to create onboarding',
    UNABLE_CREATE_STEP: 'Unable to create onboarding step',
    UNABLE_UPDATE: 'Unable to update onboarding',
    UNABLE_UPDATE_STEP: 'Unable to update onboarding step',
    UNABLE_DELETE: 'Unable to delete onboarding',
    UNABLE_DELETE_STEP: 'Unable to delete onboarding step',

    CREATE_GROUP: 'Create group',
    HEADER: 'Onboarding',
    STATUSES: {
      PENDING: 'Pending',
      BLOCKED: 'Blocked',
      SKIP: 'Skip',
      DELETED: 'Deleted',
      DONE: 'Done',
    },
    DEPARTMENTS: {
      SALES: 'Sales',
      SUPPORT: 'Support',
      TECH: 'Tech',
      CUSTOMER: 'Customer',
    }
  },
  SHOP_ITEM: {
    IS_DONATION_INFO: 'Donation article which can be used publicly',
    IS_DONATION: 'Is donation',
    
    SHOW_INACTIVE: 'Show inactive',
    AMOUNT: 'Amount',
    AMOUNT_VAT: 'Vat',
    UNABLE_UPDATE: 'Unable to update item',
    UPDATED: 'Item updated',
    IS_ACTIVE: 'Active',
    ERROR: 'Status',
    ERRORS: {
      NO_PERIOD_TYPE: 'Period type',
      NO_PERIOD: 'No period',
      OK: 'OK',
      NO_NEXT_PERIOD: 'No next period',
      NO_NEXT_SHOP_ITEM: 'No next item',
    },

    MUST_CREATE_FIRST: 'Must create article before image upload',

    NEXT_ARTICLE_INFO: 'For renewal of memberships',

    PRIORITY_INFO: 'Which order the items will show in the registration',

    IS_ACTIVE_INFO: 'An active item will always be displayed in the registration when company is selected.',

    IS_DEFAULT_INFO: 'When members are not allowed to choose articles.',

    RENEWAL: 'Renewal settings',
    VISIBILITY: 'Visibility',

    PERIOD_TYPE: 'Period type for membership',

    ERROR_DEFAULT_MUST_NOT_COST: 'Default membership article may not have a cost',
    ERROR_CREATE_WITH_NAME: 'Must have name',
    ERROR_PERIOD_TYPE_MUST_BE_SET: 'Period type must be set',
    ERROR_ONLY_MEMBERSHIPS_ALLOWED: 'Only memberships are allowed',
    ERROR_MEMBERSHIP_NO_PERIOD_TYPE: 'Membership must have type of period assigned',
    ERROR_NUM_DAYS_MUST_SET: 'Number of days must be assigned for days memberships',
    ERROR_INTERVAL_MUST_SET: 'Interval is invalid',
    ERROR_INTERVAL_DATE_FROM_TO: 'Date interval must be from lesser than to',
    ERROR_MISSING_PERIODS: 'Missing periods for periodmemberships',

    CREATING: 'Creating article',
    UPDATING: 'Updating article',
    FROM_DATE: 'From date',
    TO_DATE: 'To date',
    CREATE_FOR_NEXT: 'Create for next period',
    NEXT_SHOP_ITEM_ID: 'Renew into article',
    NEXT_PERIOD_ID: 'Next period to renew into',
    DAYS: 'Number of days',
    PERIODS: 'Number of periods',
    RANGE: 'Date interval',
    IS_DEFAULT: 'Preselected for {company_name}',
    IS_FAMILY: 'Family membership',

    PERIOD: {
      INFO: 'Select period to add membership to',
      NAME: 'Name',
      SELECT: 'Select',
      FROM: 'Period from',
      TO: 'Period to'

    },

    TYPE: {
      UNKNOWN: 'Unknown',
      REGISTER: 'Register',
      RENEW: 'Renew',
      TICKET: 'Ticket',
      COMPANY: 'Company',
      MEMBER: 'Member',
      REDEEM_CODE: 'Redeem code',
      STRIPE_INVOICE: 'Stripe invoice'
    }
  },
  INVOICE: {
    CREATE_BGMAX: 'Upload BGMax file',
    MISSING_ACCOUNT_NO: 'Account number must be set for BGMax',
    DOWNLOADING: 'Invoice is downloading...',
    CAN_INVOICE_ERROR: 'Unable to send: Can only send unpaid invoices.',
    TEXT: 'Freetext',
    MATCH_DELETED: 'BGMax row deleted',
    UNABLE_DELETE_MATCH: 'Unable to delete BGMax row',
    CONFIRM_DELETE: 'Are you sure you want to delete the invoice?',
    DELETED: 'Invoice deleted',
    UNABLE_DELETE: 'Unable to delete',
    PAYMENT_CREATED: 'Payment was created',
    UNABLE_CREATE_PAYMENT: 'Unable to create payment',
    TOTAL_SEK: 'Total',
    AMOUNT_PAID: 'Paid amount',
    PAID_AT: 'Paid at',
    SUM: 'Sum',
    MARK_PAID_MEMBERSHIP: 'Set membership paid',
    MUST_SELECT_TEMPLATE: 'Must select template',
    TEMPLATE_MISSING_INVOICE_PDF_LINK: 'Template is missing the tag %INVOICE_PDF_LINK%, unable to send.',
    EMAIL_SENT: 'Email was sent',
    UNABLE_SEND_EMAIL: 'Unable to send email',
    SEND_EMAIL: 'Send invoice via email',
    NO_SHOP_ITEM: 'Error: No shop item',
    SHOP_ITEM: 'Shop item',
    AMOUNT: 'Amount',
    INVALID_AMOUNT: 'Invalid amount',
    NO_UNPAID_MEMBERSHIPS: 'No unpaid memberships found',
    SELECT_MEMBERSHIPS: 'Select membership to invoice',
    SELECT_MEMBERSHIPS_INFO: '',
    CREATING: 'Creating invoice',
    EDITING: 'Editing invoice',
    INVOICE_TEMPLATE: 'Invoice template',
    CREATE: 'Create invoice',
    CREATED: 'Invoice created',
    DELETE: 'Delete invoice',
    NUM_ROWS: 'Number of rows',
    UPDATE_STATUS: 'Update status',
    PAYMENT_STATUS: 'Payment status',
    PRINTED: 'Printed',
    INVOICE: 'Invoice',
    INVOICES: 'Invoices',
    NO_INVOICES: 'No invoices',
    DOWNLOAD: 'Download',
    CREATED_AT: 'Created at',
    INVOICE_NUMBER: 'Invoice number',
    INVOICE_DATE: 'Invoice date',
    DUE_DATE: 'Due date',
    AMOUNT: 'Amount',
    STATUS: 'Status',
    IS_PAID: 'Payment status',

    NONE_FOUND: 'No invoices found',
    ORDERBY: 'Order by',
    ZIPCODE: 'Postal code',
    INVOICE_NO: 'Invoice number',
    MATCHED: 'Matched',
    UNABLE_MATCH: 'Unable to match invoice payment',
    EDIT_BGMAX: 'Edit Bgmax record',
    INVOICE_ID: 'Invoice number',
    RECIPIENT: 'Recipient',
    INVOICE: 'Invoice',
    PAYMENT: 'Payment',
    MATCH_PAYMENT_INFO: 'The selected payment will be matched to the invoice below',
    MATCH_INVOICE: 'Match payment',
    MATCH_OCR_INFO: 'I tabellen nedan kan du välja vilken faktura som transaktionen tillhör genom att klicka på (+) knappen.',
    CREATED_AT: 'Created date',
    FIND_MATCH: 'Select matching invoice for the transaction',
    FIND_INVOICE: 'Find invoice',
    MANUAL_FIX_INVOICE_ID: 'Match to invoice number:',
    AMOUNT: 'Amount',
    ERROR: 'Error',
    STATUS: 'Status',
    STATUSES: {
      NONE: 'None',
      PENDING_SEND: 'Pending send',
      WAITING_PAYMENT: 'Wait pay',
      PAID: 'Paid',
      DELETED: 'Deleted'
    },

    BGMAX_ERROR_ERROR: {
      UNMATCHED: 'No match',
      ALREADY_PAID: 'Already paid',
      UNKNOWN: 'Unknown'
    },
    BGMAX_ERROR_STATUS: {
      NONE: 'Not handled',
      MATCHED: 'Matched manually',
      DELETED: 'Deleted',
      IGNORE: 'Ignored'
    },
    PAYMENT_STATUS: 'Payment status',
    PAYMENT_STATUSES: {
      LEGACY: 'Invalid',
      FREE: 'Free',
      NOT_PAID: 'Not paid',
      PARTIALLY_PAID: 'Partial',
      PAID_IN_FULL: 'Paid',
      REFUND: 'Refund'
    },
    TAB_INVOICES: 'INVOICES',
    TAB_BGMAX: 'BGMAX',
    TAB_BGMAX_ERRORS: 'BGMAX ERRORS',
    TAB_COMPANIES: 'COMPANIES',
    TAB_TEMPLATES: 'TEMPLATES',
    TAB_SETTINGS: 'SETTINGS',
    UNABLE_DOWNLOAD: 'Unable to download invoice',
    RECREATED: 'Invoice was recreated',
    UNABLE_BGMAX_ERROR_GET: 'Unable to get BGMAX errors',
    UNABLE_RECREATE: 'Unable to recreate invoice',
    UNABLE_CREATE: 'Unable to create invoice',
    CREATED: 'Invoice created',
    OCR: 'OCR',
    DUE_DATE: 'Due date',
    CREATE: 'Create invoice',
    INVOICE_CREATE_SHOP_ITEM_MISSING_INFO: 'Shop item is missing on one or more selected memberships, set the article on the membership to continue',
    INVOICE_CREATE_DIFFERENT_PERIODS: 'Selected memberships have different periods, they must be the same period.',
    MESSAGE: 'Text in invoice',
    GENERATION: 'Invoice creation',
    HAS_EMAIL: 'Has email',
    MISSING_EMAIL: 'Missing email',
    MARK_PRINTED_INFO: 'Will create invoices and print PDF',
    NOT_MARK_PRINTED_INFO: 'Will only create invoices but not print PDF',
    CREATE_PDF: 'Generate PDF files',
    NUM_SELECTED: 'Number selected',
    PAYMENT_STATUS: 'Payment status',
    RENEW_STATUS: 'Renew status',
    CREATED_TO: 'Created to',
    CREATED_FROM: 'Created from',
    CREATE_FOR_FILTERS: 'Create from filters',
    CREATE_FOR_SELECTION: 'Create from selection',
    COUNT_SELECTED: 'Number of selected invoices',
    JOB_INFO: 'Invoices will be generated and stitched together into a big PDF file which can be downloaded in "Files".',
    PAID: 'Paid',
    WAIT: 'Waiting',
    LATE: 'Late',
    PAID_AT: 'Paid at',
    PRINTED: 'Printed',
    TOTAL_SEK: 'Total',
    MEMBER: 'Member',
    NUMBER: 'Invoice number',
    SELECTED: 'Selected',
    NOT_PRINTED: 'Not printed',
    PARTIALLY_PAID: 'Partially paid',
    NOT_PAID: 'Not paid',
    STATUS: 'Status',
    CREATE_STRIPE_INVOICE: 'Create Stripe Invoice',
    EMAIL_SENDOUT: 'E-mail sendout',
    EMAIL_SENDOUT_INFO: 'All results in the table will be e-mailed to recipients with the selected template.',
    REMINDER_DUE_DATE: 'New due date (reminder only)',
    EMAIL_TEMPLATE: 'E-mail template',
    UNABLE_LIST_TEMPLATES: 'Unable to list templates',
    INVALID_TEMPLATE: 'Invalid, must select e-mail template',
    UNABLE_GET_COUNT: 'Unable to get number of invoices',
    RUN_JOB: 'Start job',
    CREATE_PDF: 'Create PDF',
    PDF_TITLE: 'Create PDF file',
    PDF_TEMPLATES: 'Templates',
    INVALID_PDF_TEMPLATE: 'Invalid, must choose a PDF template',
    UNABLE_LIST_TEMPLATES: 'Unable to list templates',
    RECIPIENT: 'Recipient',
    INVOICE_TEMPLATE: 'Invoice template',
    INVOICING: 'Invoicing',
    CONFIRM_DELETE: 'Confirm deletion of invoice',
    JOB_RESULT: 'Result of job',
    JOB_RESULT_BAD_TEXT: 'Unable to create e-mails for {num} invoices.',
    JOB_RESULT_GOOD_TEXT: 'Created {num} e-mails for invoices.'
  },
  EXPORT: {
    MEMBER_LETTERS_CREATED: 'A job has been started. Look at the "Files" page when the job has finished running.',
    EXPORT: 'Export',
    EXCEL: 'Excel',
    LETTER_LABELS: 'Cover labels',
    PRINT_LABELS: 'Print letter labels'
  },
  EVENT: {
    QUESTIONS: 'Questions',
    EVENT: 'Event',
    FROM_DATETIME: 'From',
    TO_DATETIME: 'To',
    VAT_OPTION: 'VAT display options',
    VAT_OPTIONS: {
      INC_VAT: 'Inc. VAT',
      EX_VAT: 'Ex. VAT'
    }
  },
  MEMBER_EVENT: {

    EVENT_NAME: 'Event',
    SIGNUP: 'Signed up',
    NO_FOUND: 'No events found'
  },
  MEMBERSHIP: {
    PERIOD: 'Period',
    CREATED: 'Membership created',
    UPDATED: 'Membership updated',
    DELETED: 'Membership deleted',
    UNABLE_CREATE: 'Unable to create membership',
    UNABLE_UPDATE: 'Unable to update membership',
    UNABLE_DELETE: 'Unable to delete membership',
    UNABLE_GET: 'Unable to get memberships',
    CREATE: 'Create membership',
    UPDATE: 'Update membership',
    DELETE: 'Delete membership',
    CONFIRM_DELETE: 'Are you sure you want to delete the membership?',
    SHOP_ITEM: 'Membership',
    CREATING: 'Creating membership',
    EDITING: 'Editing membership',
    EDIT_MEMBERSHIP: 'Edit membership',
    MEMBERSHIPS: 'Memberships',
    NO_MEMBERSHIPS_FOUND: 'No memberships found',
    VFDT: 'Valid from',
    VTDT: 'Valid to',
    REG_AT: 'Reg date',
    IS_PAID: 'Is paid'
  },
  PAYMENT: {
    PAYMENTS: 'Payments',
    ARTICLE: 'Article',
    TYPE: 'Type',
    CREATED_AT: 'Created at',
    AMOUNT: 'Amount',
    STATUS: 'Status',
    RECEIPT: 'Receipt',
    DOWNLOAD: 'Download'
  },
  MEMBER_RENEW: {
    PROFILE_ACTIVE_CONDITIONS: 'If active will show a "Renew now" button on the profile if there is an active renewal on the member, or there is an active membership which expires within a month.',
    PROFILE_CONFIRM: 'Confirm (Quick)',
    PROFILE_RENEW_WIZARD: 'Wizard/Stepper',
    
    PROFILE_RENEW: 'Renewal via "My pages"',
    NONE: 'None',
    ACTIVATE_ALL: 'Autorenew on all',
    DEACTIVATE_ALL: 'No autorenew on all',
    AUTORENEW_SETTINGS: 'Translate: Autorenew',
    SET_AUTORENEW_ALL: 'Activate/Deactivate autorenew on all members',
    SENDOUT_SETTINGS: 'Sendouts and templates',


    INVOICE_ERROR1: 'Invoicing error 1: Theres no memberships to renew from',
    INVOICE_ERROR2: 'Faktureringsfel 2: Memberships have missing articles',
    INVOICE_ERROR3: 'Faktureringsfel 3: Memberships have articles that has no next article to renew into',


    TO_PERIOD_INFINITE: 'Infinite',
    PAYMENT_STATUS: 'Payment status',
    SENDOUT_METHODS: {
      EMAIL: 'Email',
      SMS: 'SMS'
    },

    METHOD: 'Method',
    SENDOUT_NONE_FOUND: 'No sendouts found',

    CONFIRM_CREATE_RENEWAL_BATCH: 'Are you sure you want to create renewals for the members? Links will be sent out via the given methods.',
    COUNT_MEMBERS: 'Count members: {count}',
    COUNT_MEMBERS_TO_RENEW: 'Members to renew',

    EMAIL_TEMPLATE: 'Email template',
    SMS_TEMPLATE: 'SMS template',

    RENEWAL_TYPE_INFO: 'Renewals are the full registration with payments. Confirm is a minimal form with no payment option.',

    UNABLE_CREATE_BATCH: 'Unable to create renewal batch',
    BATCH_CREATED: 'Renwal batch created',

    CREATE_RENEW_BATCH: 'Create renewals from result {count}',

    CREATING_BATCH: 'Creating a manual batch',
    EDITING_BATCH: 'Editing a manual batch',

    CREATE_RENEWS: 'Create renewals',

    SMS_EMAIL_SET_INFO: 'Selected member will have a link sent after you confirm this batch.',

    PERIOD_FROM: 'Renew from',
    PERIOD_TO: 'Renew to',

    MEMBERS_TO_RENEW_INFO: 'The selected members will get a renewal link sent to them.',

    SHORT_LINK: 'Renew link',
    INFINITE_NOT_PERIOD_DAYS: 'Infinite periods requires memberships with number of days.',
    UNPAID_MEMBERSHIPS: 'There are unpaid memberships on the member',
    ALREADY_HAVE_PERIOD: 'There is already an active renewal for the period {period}.',
    DO_SEND: 'Send',
    DONT_SEND: 'Dont send',
    SEND_FAIL: 'Failure',
    TRY_SEND: 'Sending...',
    EMAIL_OPTION: 'Send email',
    SMS_OPTION: 'Send SMS',
    FROM_PERIOD: 'From period',
    TO_PERIOD: 'To period',
    STATUS: 'Status',
    RENEW_TYPES: {
      AUTOMATIC: 'Automatic',
      MANUAL_SINGLE: 'Admin - Single',
      MANUAL_BATCH: 'Admin - Batch'
    },
    SOURCE: 'Source',
    SOURCES: {
      NONE: 'None',
      MANUAL_SINGLE: 'Admin - Manual',
      MANUAL_BATCH: 'Admin - Batch',
      AUTOMATIC: 'Automatic',
      MEMBER_REQUEST: 'Member'
    },
    CREATED: 'Member renew created',
    UNABLE_CREATE: 'Unable to create member renew',
    DELETED: 'Member renew deleted',
    UNABLE_DELETE: 'Unable to delete member renew',
    SCHEDULED_AT: 'Scheduled at',
    INVALID_DATE: 'Invalid date',
    UNABLE_GET_SHOP_ITEMS: 'Unable to get shop items',
    STATUSES: {
      SCHEDULED: 'Scheduled',
      ACTIVE: 'Awaiting answer',
      STARTED_LINK: 'Started by member',
      INVOICED: 'Invoiced',
      TIME_OUT: 'Timeout',
      AWAITING_PAYMENT: 'Awaiting payment',
      DONE: 'Renewed - Done',
      DELETED: 'Deleted - Aborted'
    },
    CREATE_RENEW: 'Create renewal',
    RENEWAL_TYPE: 'Renewal type',
    IS_CONFIRM: 'Is confirmation',
    IS_RENEWAL: 'Is renewal',
    DETAILS: 'Details',
    SENDOUTS: 'Sendouts',
    PERIOD_TYPE_INFINITE: 'Infinite',
    PERIOD_TYPE_PERIOD: 'Periods',
    WIZARD: {
      HEADER: 'Renewal guide',
      INTRO_HEADER: 'Introduction',
      INTRO_INFO1: 'This guide will help you setup the renewal settings for your company. You can always change these settings later.',
      INTRO_INFO2: 'Click on the button below to start the guide.',
    },
    ACTIVE_RENEWAL: 'Active renewal',
    RENEWALS: 'Renewals',
    EDITING: 'Editing renewal',
    CREATING: 'Creating renewal',
    NO_RENEWS: 'No renewals',
    FORCE_RUN_AUTORENEW: 'Force autorenewal job',
    UNABLE_FORCE_RUN_AUTORENEW: 'Unable to force run autorenewal',
    CONFIRM_RENEW: 'Confirm renewal',
    ARE_YOU_SURE_DELETE: 'Are you sure you want to delete renewal?',
    CONFIRM: 'Confirm',
    UNABLE_GET_RENEWS_FOR_EMAIL_BATCH: 'Unable to get list of renewals for email batch',
    UNABLE_GET_RENEWS_FOR_SMS_BATCH: 'Unable to get list of renewals for sms batch',
  },
  MEMBER_RELATION: {
    UNABLE_UPDATE: '',
    UPDATED: '',
    UNABLE_CREATE: '',
    CREATED: '',
    CREATING: 'Creating family member',
    EDITING: 'Editing family member',
  },

  MEMBER_PROPERTY: {
    PROP_NAME: 'Attribute',
    PRIORITY: 'Priority',
    PRIORITY_INFO: 'Order to show the group',
    ADMINISTER: 'Administer categories',
    QUESTIONEER_INFO: 'Questions participants must answer',
    QUESTIONEER: 'Question form to answer',
    ADD_GROUP: 'Add category',
    SELECT_GROUPS_HEADER: 'Selected categories',
    SELECT_GROUPS: 'Selected categories ({count})',
    PROPERTY_GROUP_HEADER: 'Editing attribute category',
    UPDATING: 'Updating attribute category',
    CREATING: 'Creating attribute category',
    IS_MULTI: 'Multiselect',
    MULTIPLE_OPTIONS_INFO: 'Can multiple options be selected?',
    CONFIRM_DELETE: 'Are you sure you want delete the attribute category?',
    SAVE_MEMBER_PROPERTY: 'Save attribute',
    SAVE_PROPERTY_GROUP: 'Save attribute category',
    GROUP_NAME: 'Category'
  },

  PARENT: {
    NONE_FOUND: 'No guardians found',
    CREATE: 'Create guardian',
    DELETE: 'Delete guardian',
    CREATING: 'Creating guardian',
    EDITING: 'Editing guardian',
    DELETED: 'Guardian deleted',
    UPDATED: 'Guardian updated',
    CREATED: 'Guardian created',
    UNABLE_DELETE: 'Unable to delete guardian',
    UNABLE_CREATE: 'Unable to create guardian',
    UNABLE_UPDATE: 'Unable to update guardian',
    CONFIRM_DELETE: 'Confirm deletion of guardian',
    PERSON_ID: 'PersonID'
  },
  
  SENDOUT_TEMPLATE: {
    CREATE: 'Create template connection',
    TYPE: 'Type',
    CREATED_AT: 'Created at',
    TEMPLATE_ID: 'Template'
  },

  
  KVITTO_RULE: {
    TITLE: 'Kvitto Rules',
    NAME: 'Name',
    COMPANY_ID: 'Company ID',
    FROM_EMAIL: 'From Email',
    SUBJECT_LIKE: 'Subject Like',
    SUBJECT_LIKE_COUNT: 'Subject Like Count',
    AI_MATCH_DESCRIPTION: 'AI Match Description',
    AI_MATCH_COUNT: 'AI Match Count',
    FROM_ORIGINAL_EMAIL: 'From original email',
    FORWARD_EMAIL_TO: 'Forward Email To',
    NEW_RULE: 'New Rule',
    NAME: 'Rule Name',
    FROM_ORIGINAL_EMAIL: 'From Original Email',
    CREATED: 'Kvitto rule created successfully.',
    UPDATED: 'Kvitto rule updated successfully.',
    UNABLE_CREATE: 'Failed to create kvitto rule.',
    UNABLE_UPDATE: 'Failed to update kvitto rule.',
    DELETED: 'Rule deleted',
    UNABLE_DELETE: 'Unable to delete rule'
  },


  KVITTO: {
    KVITTO_RULES: 'Regler',
    INCOMING_EMAILS: 'Incoming emails',
    INCOMING_EMAIL: {
      ORIGINAL_EMAIL: 'Original sender',
      ORIGIN_NAME: 'Original name',
      FROM_EMAIL: 'From email',
      FROM_NAME: 'From name',
      TO: 'To',
      CREATED_AT: 'Created at',
      TEXT_BODY: 'Text',
      FROM: 'From',
      MESSAGE_ID: 'Message ID',
      CUSTOMER_ID: 'Customer ID',
      CC: 'CC',
      SUBJECT: 'Subject',
      ID: 'ID',
    },

    MY_LIST: 'My list',
    HEADER: 'Receiptservice',
    NEW_TRANSACTION_FILE: 'New transactions file',
    FILE_UPLOAD_SUCCESS: 'File upload success',
    FILE_UPLOAD_FAILURE: 'File upload failure',
    ACCOUNTS: 'Accounts',
    USERS: 'Users',
    FILES: 'Transaktionsfiler',
    RECEIPTS: 'Receipts',
    NEW_KVITTO: 'New receipt',
    NEW_ACCOUNT: 'New account',
    NONE_ASSIGNED: 'None assigned',
    ACCOUNT: {
      NAME: 'Name of account',
      NAME_TIP: 'Name of the account for easier separation',
      ASSIGNED_USER_ID: 'Assigned',
      ASSIGNED_USER_ID_TIP: 'Assigned user on the account',
      EXTERNAL_ID: 'External ID',
    },
    STATUS: 'Status',
    SEARCH_STR: 'Search string',
    STATUSES: {
      NONE: 'None',
      MAPPED: 'Mapped',
      BOOKED: 'Booked',
      URGENT_NEED_MAPPING: 'Urgent! Need mapping',
      URGENT_NEED_BOOKED: 'Urgent! Need booking',
      CLOSED: 'Closed',
      SKIPPED: 'Skipped'
    }
  },

  

  MEMBER: {
    DEACTIVATE_REASON: 'Deactivate Reason (SPAR)',

    EMIGRATED: 'Emigrated',

    INVALID_DATE: 'Invalid date format',
    SUPPRESSION_REMOVED: 'Suppression removed',
    UNABLE_REMOVE_SUPPRESSION: 'Unable to remove suppression',
    REMOVE_SUPPRESSION_DONE: 'Email suppression removed',
    REMOVE_SUPPRESSION: 'Apply to remove suppression',
    INFO_REMOVE_SUPPRESSION: 'Enter email to remove the newsletter suppression from',


    DELETE_STRING: 'DELETE',
    IS_MEMBER: 'Is member',
    IS_PERSON: 'Is person',

    
    PRINT_DEACTIVATE_LETTER: 'Print deactivate letter',
    PRINT_DEACTIVATE_LETTER_INFO: 'Print a letter to the member that they are deactivated',
    
    MOBILE: 'Mobile',
    NO_MEMBER: 'Not member',
    NO_PARENTS_FOUND: 'No guardians found',
    PARENTS: 'Guardians',
    DELETED: 'Member deleted',
    EXIT_REQUESTED: 'Exit requested',
    ERROR_REQUEST_EXIT: 'Unable to request exit',
    EXIT_CONFIRMED_MESSAGE: 'Exit confirmed',
    EXIT_CONFIRM_POPUP_TITLE: 'Confirm exit',
    REQUEST_EXIT: 'Request exit',
    PENDING_EXIT: 'Exit requested',
    EXIT_REJECTED_MESSAGE: 'Exit rejected',
    CONFIRM_REQUEST_EXIT: 'Confirm that you wish to exit the association',
    NO_MEMBERS_SELECTED: 'No members selected',
    SELECT_MEMBERS_TO_DELETE: 'Select members to delete',
    UNABLE_DELETE_MEMBERS: 'Unable to delete members',
    MEMBERS_DELETED: 'Members deleted',
    DONT_SHOW_AGAIN: 'Dont show again',
    POPUP: 'Popup',
    LOGIN: 'Login',
    ENABLE_LOGIN_EMAIL: 'Enable login with email',
    ENABLE_LOGIN_BANKID: 'Enable login with BankID',
    PRICE_PER_LOGIN: 'Price per login (see contract price list)',
    UNABLE_TO_LOGIN_DUE_TO_SETTINGS: 'Members wont be able to login due to no login method enabled',
    
    ADD_MEMBERSHIP: 'Add membership for subcompanies',
    SELECT_COMPANIES: 'Select companies to add membership to for {period}. The companies you are already member of are not shown.',
    BECOME_MEMBER: 'Become member',

    CREATED_AT: 'Date created',
    UNSET_REG: 'Force registration',
    REG: 'Ongoing registration - Awaiting payment',
    REG_INFO: 'The member has not finalized the registration.',

    PROFILE_SECTIONS: 'Visble tables:',
    ORDERS: 'Orders',
    FILES: 'Files',
    DOB_INVALID_FEEDBACK: 'Use format YYYY-MM-DD',

    RENEW_NOW_INFO: 'Your membership can be renewed',
    RENEW_NOW: 'Renew now!',

    MUST_CREATE_BEFORE_SPAR: 'Member must be created before SPAR lookup',
    SPAR_LOOKUP: 'SPAR',
    DO_SPAR_LOOKUP: 'Do SPAR lookup',
    PENDING_PAYMENT_REG: 'Member is being registered with a pending payment',
    SWESSN: 'Valid personnr',
    SECRET: 'Secret',
    SPARCODEID: 'SparkodID',
    BANKID_VERIFY_PERSONNR_MISMATCH: 'Personnummer differs.',
    VERIFY_WITH_BANKID: 'Verify using BankID',
    START_BANKID_QR: 'Open BankID using your phone or tablet and scan the QR code below.',
    INVALID_RESET_LINK: 'Reset link is expired or invalid',
    CONFIRM_DELETE: 'Confirm deletion',
    CONFIRM_DELETE_MESSAGE: 'Are you sure you want to delete the member?',
    DELETE_ALL: 'Delete all',
    CONFIRM_DELETE_ALL: 'Are you sure you want to delete all members?',
    DELETE_SELECTED: 'Delete selected',
    CONFIRM_DELETE_SELECTED: 'Are you sure you want to delete {count} members?',
    UNABLE_DELETE_MESSAGE: 'Unable to delete member',
    MEMBER_UPDATED: 'Member updated',
    UPDATED_AT: 'Updated at',
    SKV_PERIOD_ID: 'SKV period',
    INVOICE_CO: 'Invoice C/o',
    REG_AT: 'First registration',
    DEACTIVATED: 'Deactivated',
    DECEASED: 'Deceased',
    DO_AUTORENEW: 'Activate autorenew',
    NO_FAMILY_MEMBERS: 'No family members',
    FAMILY: 'Family members',
    UNABLE_GET_NUM_MEMBERS: 'Unable to get number of members',
    DELETE_SELECTED: 'Delete  selected',
    DELETE_ALL: 'Delete all',
    LANG: 'Language',
    CREATE: 'Create member',
    LEGAL_SEX: 'Legal sex',
    NUM_ROWS: 'Number of rows',
    MEMBERS: 'Members',
    CREATE_MEMBER: 'Create member',
    MEMBERS_PURGE: 'Purge members',
    CREATE_BEFORE_EDIT: 'Create member before editing these fields',
    NO_FILES: 'No files',
    NO_PARENTS: 'No guardians',
    STUDENT_ID: 'Student-ID',
    STUDENT_STATUS: 'Student Status',
    INVOICE_ADDRESS_HEADER: 'Invoicing address',
    ADDRESS_HEADER: 'Address',
    CONTACT: 'Contact information',
    DETAILS: 'Member data',
    SAVED: 'Data saved',
    STATUS: 'Membership status',
    STATUSES: {
      MEMBER: 'Accepted member',
      REJECTED: 'Rejected member',
      PENDING: 'Waiting',
      PENDING_EXIT: 'Waiting exit',
      EXITED: 'Exited'
    },
    SAMFUND: 'Religious community',
    MEMBER_ID: 'Database ID',
    PROFILE_SETTINGS_INFO: 'Marked data fields below will show up on the member profile page and can be updated by the member.',
    MEMBERSHIPS: 'Memberships',
    RENEW_ON_TOP_ONLY: 'Renewals can only be created from the top company, select top company in the page header.',
    DEACTIVATE_NOW: 'Deactivate from now',
    ACTIVATE_NOW: 'Activate from now',
    DEACTIVATE_AT_INFO: 'If deactivation date is set, member will be deactivated at the given date. If no date is set, you will deactivate immediately.',
    DEACTIVATE_UPDATE: 'Update activation',
    ACTIVATION_UPDATED: 'Activation status updated',
    UNABLE_UPDATE_ACTIVATE: 'Unable to update activation status',
    DEACTIVATE_AT: 'Deactivated from',
    DEACTIVATE: 'Deactivate member',
    ACTIVATE: 'Activate member',
    INVOICE_HEADER: 'Invoice address',
    INVOICES: 'Invoices',
    NO_GUARDIANS: 'There are no guardians connected.',
    YOUR_PARENTS: 'Your guardians',
    PARENTS_INFO: 'Guardians connected to your account.',
    FAMILY_INFO: 'Family members are people connected to your account which have your benefits.',
    YOUR_FAMILY: 'Your family members',
    DOWNLOAD_INFORMATION: 'Download your personal information',
    YOUR_PROFILE: 'Your profile',
    UPDATE_INFO: 'Update your information',
    EVENT: 'Event',
    FROM: 'From',
    TO: 'To',
    ACTIVE_EVENTS: 'Active events',
    NO_EVENTS_FOUND: 'No events found',
    NO_ORDERS_TABLE: 'No orders found',
    CONTACT: 'Contact information',
    SYS: 'Receive system messages',
    DEF: 'Receive newsletters',
    IMP: 'Receive important messages',
    NOTIFICATIONS: 'Notifications',
    SHOW_CARD: 'Show membership card',
    HIDE_CARD: 'Hide membership card',
    CARD: 'Membership card',
    NAME: 'Name',
    PERS_ORGNR: 'Person/Orgnumber',
    PERSONNR: 'Personnumber',
    PERSONNR_INFO: 'Swedish Personnumber (YYYYMMDD-1234)',
    DOUBLE_SSN_INFO: 'Personnumber is already registered!',
    PASSWORD: 'Password',
    REPEAT_PASSWORD: 'Repeat password',
    NEW_PASSWORD: 'New password',
    CONFIRM_PASSWORD: 'Confirm password',
    COMPANY_NAME: 'Company name',
    ORG_NUMBER: 'Organisation number',
    ORG_NUMBER_INFO: 'Org number: XXYYZZ1234',
    EMAIL: 'E-mail',
    REPEAT_EMAIL: 'Repeat e-mail',
    FIRSTNAME: 'Firstname',
    LASTNAME: 'Lastname',
    PHONE: 'Phone',
    ADDRESS: 'Address',
    ZIPCODE: 'Postcode',
    POSTAL: 'City',
    CO: 'C/o',
    COMMUNE: 'Commune',
    REGION: 'Region',
    INVOICE: 'Invoice',
    MEMBER: 'Member',
    PERSON: 'Person (non-member)',
    INVOICE_INFO: 'Invoice information',
    MEMBER_CREATED: 'Member created',
    MEMBER_UPDATED: 'Member updated',
    MEMBER_DELETED: 'Member deleted',
    UNABLE_CREATE_MEMBER: 'Unable to create member',
    UNABLE_UPDATE_MEMBER: 'Unable to update member',
    UNABLE_DELETE_MEMBER: 'Unable to delete member',
    CREATE_MEMBER: 'Create member',
    MEMBER_DATA: 'Medlemsdata',
    LEAVE_EMPTY_AUTO: 'Leave empty for autogenerated',
    MEMBER_NUMBER: 'Member number',
    PUBLIC_ID: 'Member number',
    PUBLIC_ID_SHORT: 'Mem. nr.',
    UPDATE_FROM_SPAR: 'Update from SPAR',
    DEFINED_GENDER: 'Defined gender',
    MEMBER_DEACTIVATED: 'Member is deactivated',
    MEMBER_DECEASED: 'Member is deceased',
    HD: 'Head member',
    TYPE: 'Member type',
    AUTORENEW: 'Autorenew',
    MOBILE: 'Mobile phone',
    LANDLINE: 'Landline',
    EMAIL_ALREADY_REGISTERED: 'E-mail is already registered.',
    UPDATE_PASSWORD: 'Update password',
    UPDATE_PASSWORD_INFO: 'Create member first, then you can update the password.',
    DEPARTMENT: 'Department',
    COUNTRY: 'Country',
    INVOICE_ADDRESS_INFO: 'Invoicing address',
    FAMILY_DATA: 'Family data',
    GUARDIANS: 'Guardians',
    ATTRIBUTE: 'Attribute',
    OTHER_INFO: 'Other information',
    FIRST_REGISTER: 'First registered',
    EDUCATIONS_INFO: 'Connected educations',
    YOUR_EDUCATIONS: 'Your educations',
    SPURL: 'Answer form URL',
    MEMBERSHIP: 'Membership',
    COPY_INFO: 'Copy to other company and period',
    EDUCATIONS: 'Educations',
    RENEWALS: 'Renewals',
    INVOICES: 'Invoices',
    TRANSACTIONS: 'Transactions',
    NOTES: 'Notes',
    OPTONS: 'Options',
    SEND_RESET_PASSWORD: 'Send reset password by e-mail',
    SEND_MEMBER_JOINED_EMAIL: 'Send welcome e-mail to member',
    RESET_PASSWORD_INFO: 'Do you want to send e-mail to the member regarding password reset?',
    RESET_PASSWORD: 'Reset password',
    RESET_RENEWALS: 'Reset renewal settings',
    LOGIN_AS_MEMBER: 'Log in as member',
    DOWNLOAD_GDPR: 'Download GDPR data',
    CREATE_INVOICE_FROM_MEMBERSHIPS_INFO: 'Create invoice from membership articles which are marked as not paid.',
    CREATE_INVOICE: 'Create invoice',
    DELETE_FROM_SYSTEM: 'Delete from system',
    CONVERT: 'Convert',
    CONVERT_MEMBER_TO_PERSON: 'Convert member to person',
    WARN_MANDATORY: 'Warning! Some mandatory fields are not set!',
    OK_MANDATORY: 'Each mandatory field is set.',
    PASSWORD_AT_LEAST: 'At least 6 characters',
    PASSWORDS_DIFFERS: 'Passwords differs',
    PASSWORD_UPDATED: 'Password was updated',
    UNABLE_PASSWORD_UPDATE: 'Unable to update password',
    DO_SPAR: 'Perform SPAR lookup',
    UNABLE_DO_SPAR: 'Unable to perform SPAR lookup',
    SPAR_DISABLED: 'SPAR is disabled',
    NO_FAMILY: 'There are no family members',
    NO_EDUCATIONS: 'There are no educations',
    FAMILY_REMOVED: 'Family member was removed',
    UNABLE_FAMILY_REMOVE: 'Unable to remove family member',
    FAMILY_SAVED: 'Family member saved',
    UNABLE_FAMILY_SAVE: 'Unable to save family member',
    UNABLE_FAMILY_ADD: 'Unable to add familjemedlem',
    UNABLE_REMOVE_MEMBERSHIP: 'Unable to remove membership',
    MEMBERSHIP_REMOVED: 'Membership was removed',
    NON_MEMBER: 'Not member',
    CNAME: 'Company name',
    RDT: 'Reg. date',
    EXIT_AT: 'Exit date',
    AVD: 'Department',
    INVOICE_FIRSTNAME: 'Invoice Firstname',
    INVOICE_LASTNAME: 'Invoice Lastname',
    INVOICE_EMAIL: 'Invoice E-mail',
    INVOICE_PHONE: 'Invoice Phone',
    INVOICE_ADDRESS: 'Invoice Address',
    INVOICE_POST: 'Invoice City',
    INVOICE_ZIPCODE: 'Invoice Postal code',
    INVOICE_COUNTRY: 'Invoice Country',
    COUNTRY: 'Country',
    AGE: 'Age',
    IS_HD: 'Is main member',
    NOT_HD: 'Not main member',
    OPTIONS: 'Options',
    PUBLIC_SEX: 'Defined gender',
    LADOK: 'LADOK status',
    FMT_PERSONNR: 'Formated personnumber',
    DOB: 'Birthday',
    DOB_INFO: 'YYYYMMDD',
    DAY: 'Day',
    MONTH: 'Month',
    YEAR: 'Year',
    INTERNATIONAL_DOB: 'Use 3 separate fields (day, month, year)',
    SEX: 'Legal gender',
    POST: 'City',
    OPTIONS: 'Options',
    RESET_PASSWORD_SENT: 'Reset password link has been sent',
    PARENT_CREATED: 'Guardian created',
    UNABLE_CREATE_PARENT: 'Unable to create guardian',
    PARENT_REMOVED: 'Guardian removed',
    UNABLE_REMOVE_PARENT: 'Unable to remove guardian',
    NO_PARENT_ID: 'Unable to find guardian ID',
    RENEW_RESET: 'Renewal was reset',
    FORGET_PASSWORD: 'Reset password',
    FORGET_PASSWORD_INFO: 'Enter e-mail to receive reset password instructions',
    EMAIL_SENT: 'Email was sent',
    STRIPE_ID: 'Stripe ID',
    UPDATE_STRIPE: 'Update Stripe Customer',
    CREATE_STRIPE: 'Create Stripe Customer',
    STRIPE_CREATED: 'Stripe Customer created',
    STRIPE_UPDATED: 'Stripe Customer updated',
    STRIPE_MISSING_INFO: 'Stripe Customer must be created in order to create Stripe invoices',
    STRIPE_INVOICE_CREATED: 'Stripe invoice Created',
    STRIPE_INVOICE_UNABLE_DELETE: 'Unable to delete Stripe invoice',
    STRIPE_INVOICE_DELETED: 'Stripe invoice deleted',
    STRIPE_INVOICE_PUBLISHED: 'Stripe invoice published',
    STRIPE_INVOICE_UNABLE_PUBLISH: 'Unable to publish Stripe invoice',
    IN_PERSONNR: 'Person number',
    FID: 'FamilyID',
    'FIRSTNAME+LASTNAME': 'Firstname+Lastname',
    VFDT: 'Valid from date',
    VTDT: 'Valid to date',
    PAID_AT: 'Paid at date',
    TRANSACTION: 'Transaction',
    SKIP: 'Skip',
    MEMBERSHIP_ITEM_ID: 'Membership shop item ID',
    SHOP_ITEM_ID: 'ArticleID',
    IS_PARENT: 'Is guardian',
    SOURCE: 'Source',
    COMMUNE_CODE: 'Commune code',
    CNUM: 'Company number',
    'ADDRESS+ZIPCODE+POSTADDRESS': 'Address+Postalcode+City',
    REGION_CODE: 'Region code',
    'LASTNAME+FIRSTNAME':'Lastname+Firstname',
    PAYING: 'Paying',
    IS_PAID: 'Is paid (1 or 0)',
    PARENT_ID: 'Guardian ID',
    'ZIPCODE+POSTADDRESS':'Postalcode+City',
    UNABLE_GET_PERMISSIONS: 'Could not fetch user permissions',
    UNABLE_LIST_FAMILY: 'Could not list family members',
    ADD_FAMILY_MEMBER: 'Family member added',
    UNABLE_ADD_FAMILY_MEMBER: 'Could not add family member',
    UNABLE_ADD_MEMBER_IN_ANOTHER_FAMILY: 'Family member is already in another family',
    UPDATE_HD: 'Family head member updated',
    UNABLE_UPDATE_HD: 'Could not update family head member',
    DELETE_HD_NOT_ALLOWED: 'Can not delete head family member with related family members!',
    HEAD_MEMBER_INFO: 'Head family member can be changed in Family data',
    FAMILY_MEMBERS: 'Family members',
    ATTRIBUTES: 'Attributes',
  },
  SAMPLE: {
    SAMPLE: 'Sample',
    SAMPLES: 'Samples',
    NO_SAMPLES: 'No samples',
    SAMPLE_ID: 'Sample ID',
    NAME: 'Name',
    CREATED_AT: 'Created',
    UNABLE_GET: 'Unable to retrieve sample',
    UNABLE_LIST: 'Unable to list samples',
    UNABLE_CREATE: 'Unable to create sample',
    UNABLE_UPDATE: 'Unable to update sample',
    UNABLE_DELETE: 'Unable to delete sample',
    CREATED: 'Sample created',
    UPDATED: 'Sample updated',
    DELETED: 'Sample deleted',
    CREATE: 'Create sample',
    UPDATE: 'Update sample',
    DELETE: 'Delete sample',
    CREATED: 'Sample created',
    UPDATED: 'Sample updated',
    DELETED: 'Sample deleted',
    UNABLE_CONFIRM: 'Unable to confirm sample',
    UNABLE_REJECT: 'Unable to reject sample',
    UNABLE_LIST_MEMBERS: 'Unable to list members',
    START: 'Start sample',
    UNABLE_START: 'Unable to start sample',
    STARTED: 'Sample started',
    STOP: 'End sample',
    UNABLE_STOP: 'Unable to end sample',
    STOPPED: 'Sample ended',
    UNABLE_CREATE_EXCEL: 'Unable to create Excel',
    DO_MANUAL: 'Manual',
    STATUS: 'Status',
    ANSWER_YES: 'Answer yes',
    ANSWER_NO: 'Answer no',
    STATUSES: {
      CON: 'Confirmed',
      REJ: 'Not a member',
      NO_ANS: 'No answer'
    },
    COMPANIES_INCLUDED: 'Companies included in the sample',
    SAMPLE_ALREADY_EXISTS: 'A sample already exists for the company',
    SHOW_HISTORY: 'Show history',
    DEADLINE: 'Deadline',
    SHORT_NUM_ANSWERS: '# Answers',
    SHORT_NUM_MEMBERS: '# Members',
    INTRO: 'Introduction',
    COMPANY_SELECTION: 'Company selection',
    SELECTION: 'Selection group',
    OPTIONS: 'Options',
    END_DATE: 'End date',
    TEMPLATES: 'Templates',
    CONFIRM: 'Confirm',
    CREATE_INFO: 'You are about to create a new sample. Follow the guide and fill in everything needed. The sample will not be running until it has been created AND you have chosen to start the sample.',
    SELECT_COMPANY_INFO: 'Choose which companies to include in the sample. You can also specify that each company will get its own individual sample created for it.',
    OPTION_SPLIT: 'All selected companies get their own samples',
    SAMPLE_SIZE: 'Specify the proportion (in percent) of members to be included in the sample',
    NUM_MEMBERS: 'Number of members',
    PCT_MEMBERS: 'Percentage of members',
    OPTIONS_INFO: 'Members included in the sample will receive a link to confirm their membership. Specify how you want the system to send out these links<br />If you choose SMS and/or Email, 3 reminders will be sent to each member who doesn\'t respond, after which you can make manual calls to remaining members',
    ONLY_DO_MANUAL: 'Manual calling only',
    END_DATE_INFO: 'Specify the last date for the sample. When this date occurs, no further dispatch will take place.',
    TEMPLATES_FOR_SENDOUT: 'Templates for dispatch',
    SENDOUT_EMAIL_INFO: 'The options specifies that an email should be sent, choose which template to send to the members.',
    PREVIEW: 'Preview',
    SENDOUT_SMS_INFO: 'The options specifies that SMS should be sent, choose which template to send to the members.',
    FINAL_STEP_INFO: 'The sample will be created when you click on "Create Sample". Note that the automatic dispatches will not start until you have actively chosen to "Start" the sample.',
    UNABLE_CALC_MEMBERS: 'Unable to calculate number of members'
  },
  OTHER: {
    INFO: 'Any requested features will be placed here'
  },

  PERSON: {
    PERSON_CREATED: 'Person created',
    PERSON_UPDATED: 'Person updated',
    UNABLE_CREATE_PERSON: 'Unable to create person',
    UNABLE_UPDATE_PERSON: 'Unable to update person',
    UNABLE_GET: 'Unable to get person'
  },
  LANGUAGE: {
    SWEDISH: 'Swedish',
    ENGLISH: 'English',
    ARABIC: 'Arabic',
    BOSNIAN: 'Bosnian',
  },
  
  COMMON: {
    ENTER_VALUE: 'Enter value',
    PAID_SHORT: 'Paid',
    LOADING: 'Loading...',
    SESSION_EXPIRES_IN: 'Logs out in {days} days, {hours}:{minutes}:{seconds} ',
    WRITE_CONFIRM_TEXT_INFO: 'This is an additional safety measure. Write the following text in the box below: ',
    WRITE_CONFIRM_TEXT: 'Write to confirm',
    CREATE_SERVICE: 'Create service',
    SERVICE_CREATED: 'Service created',
    SERVICE_DOMAIN_INFO: 'In order to create a service you will need to log in to oderland to add the domain to the IP 46.21.97.83',
    PERIOD_TYPE: 'Period type',
    PERIOD_TYPES: {
      INFINITY: 'Infinite',
      PERIOD: 'Period'
    },
    INSTANCES: 'Instances',
    INVALID_FORM: 'Invalid form',
    ID: 'ID',
    THANK_YOU: 'Thank you!',
    ABORT: 'Abort',
    FORM_VALIDATION_FAILED: 'Form validation failed, control fields',
    ARE_YOU_SURE: 'Are you sure you want to proceed?',
    SELECT_ALL: 'Select all',
    NUM_RECORDS: 'Number of rows',
    COPIED_TO_CLIPBOARD: 'Copied to clipboard',
    UNABLE_COPY_CLIPBOARD: 'Unable to copy to clipboard',
    UPLOAD: 'Upload',
    GENERATE: 'Generate',
    NEW: 'Create new',
    EDIT: 'Edit',
    FEMALE: 'Female',
    MALE: 'Male',
    UNABLE_GET_PAGE: 'Unable to get page',
    SMS: 'SMS',
    UNABLE_DOWNLOAD: 'Unable to download file',
    SUCCESS: 'Success',
    ERROR_LOADING_DATA: 'Error loading data',
    CONNECT: 'Connect',
    SELECT: 'Select',
    NUM_ROWS: 'Count rows',
    ERROR_OCCURED: 'An error has occured',
    REMOVE: 'Remove',
    COPY_LINK: 'Copy link',
    SV: 'Swedish',
    EN: 'English',
    LANG: 'Language',
    IS_BOOKED: 'Booked',
    SERVICE: 'Service',
    MODULE: 'Module',
    PAID_AT: 'Paid at',
    COST_SEK: 'Cost',
    SAVE_CHANGES: 'Save changes',
    COPIED: 'Copied',
    COST: 'Cost',
    BACK: 'Back',
    PAYMENT_STATUS: 'Payment status',
    DESCR: 'Description',
    SETTINGS: 'Settings',
    CONFIRM: 'Confirm',
    SHOW: 'Show',
    HIDE: 'Hide',
    EXPORT_XLSX: 'Export XLSX',
    EXPORT_CSV: 'Export CSV',
    YES: 'Yes',
    NO: 'No',
    ERROR: 'Error',
    OK: 'OK',
    LACK_PERMISSION: 'You lack permissions',
    ACTIONS: 'Actions',
    CANCEL: 'Cancel',
    NAME: 'Name',
    PRICE_SEK: 'Price',
    SELECTED: 'Selected',
    CREATE_NEW: 'Create new',
    UPDATE: 'Update',
    CREATE: 'Create',
    MAN: 'Man',
    WOMAN: 'Woman',
    OTHER: 'Other',
    UNKNOWN: 'Unknown',
    DONT_WANT: 'Don\'t want to share',
    PAID: 'Paid',
    NOT_PAID: 'Not paid',
    LADOK_STUDENT_STATUS: {
      S: 'Skip',
      Y: 'Active student',
      N: 'Not student',
      P: 'Pending'
    },
    ACTIVE_STUDENT: 'Active student',
    NO_STUDENT: 'No student',
    WAITING: 'Waiting',
    MISSING: 'Missing',
    DELETE: 'Delete',
    SEARCH: 'Search',
    ADD: 'Add',
    COLUMN: 'Column',
    VALUE: 'Value',
    COPY: 'Copy',
    SEND: 'Send',
    RESET: 'Reset',
    LOGIN: 'Login',
    DOWNLOAD: 'Download',
    CLOSE: 'Close',
    AN_ERROR_OCCURED: 'An error occurred',
    TRY_AGAIN_LATER: 'Try again later',
    EXCEL: 'Excel',
    UNABLE_EXPORT: 'Unable to export',
    EXPORT_SUCCESS: 'Export successful',
    TOTAL: 'Total',
    UNABLE_REGIONS: 'Unable to get regions',
    UNABLE_COMMUNES: 'Unable to get communes',
    CREATED_FROM: 'Created from',
    CREATED_TO: 'Created to',
    UNABLE_GET_DATA: 'Unable to get data',
    WAIT_PAYMENT: 'Awaiting payment',
    PARTIALLY_PAID: 'Partially paid',
    PAID_IN_FULL: 'Paid',
    MEMBER_PERSON: 'Member/Person',
    CREATED_AT: 'Created at',
    SELECTION: 'Selection',
    CONTINUE: 'Continue',
    DATE: 'Date',
    INVOICE_INFORMATION: 'Fakturamottagare',
    ACTIVE: 'Active',
    TYPE: 'Type',
    MANDATORY: 'Mandatory',
    SAVE: 'Save',
    SEND_EMAIL: 'Send e-mail',
    SEND_SMS: 'Send SMS',
    LATE: 'Late',
    BYPOST: 'Letter/Mail',
    STATUS: 'Status',
    ALL: 'All',
    CLEAR: 'Clear',
    EXPORT: 'Export',
    MANAGE: 'Manage',
    UNABLE_UPDATE: 'Unable to update',
    UPDATED: 'Updated',
    UNABLE_CREATE: 'Unable to create',
    CREATED: 'Created',
    UNABLE_DELETE: 'Unable to delete',
    DELETED: 'Deleted',
    INVALID_EMAIL: 'Invalid e-mail',
    INVALID_ROLE: 'Invalid role',
    INVALID_GROUP: 'Invalid group',
    JOB_ESTIMATE_COST: 'Job estimated cost',
    AVAILABLE_BUDGET: 'Available budget',
    UPDATED_AT: 'Updated at',
    DONE: 'Done',
    GENERAL_SUCCESS: 'Successful operation',
    NOT_SET: 'Not set',
    SETTLED: 'Settled',
    IMPORT: 'Import',
    IS_PAID: 'Paid',
    PUBLISH: 'Publish',
    HELP: 'Help',
    CLICK_HELP: 'Click here for help',
    EMAIL: 'E-mail',
    MEMBER_NUMBER: 'Member number',
    NOT_ALLOWED: 'Not allowed',
    REQUIRED_FIELDS_INFO: 'Fields marked with {asterix} are mandatory.',
    PREVIEW: 'Preview',
    QUANTITY: 'Quantity'
  },
  REPORT: {
    MUST_SELECT_MONTH: 'Must select month'
  },
  PERIOD: {
    SKV_PERIOD_INTAG: 'SKV Registration',
    MDT_FROM: 'Member registered from',
    MDT_TO: 'Member registered to',
    ACTIVE_PERIODS: 'Active periods',
    PERIODS: 'Periods',
    PERIOD: 'Period',
    NAME_OF_PERIOD: 'Name of period',
    FROM: 'From date',
    TO: 'To date',
    CONFIRM_DELETE: 'Are you sure you want to delete the period?',
    CONFIRM: 'Confirm',
    CREATED: 'Period was created',
    UNABLE_CREATE: 'Unable to create period',
    UNABLE_OVERLAP: 'Unable to create period: Dates may not overlap',
    FILL_IN_ALL_FIELDS: 'Please fill in all fields',
    UPDATED: 'Period was updated',
    UNABLE_UPDATE: 'Unable to update period',
    DELETED: 'Period was deleted',
    UNABLE_DELETE: 'Unable to delete period',
    NEXT_PERIOD: 'Next period',
    NEW: 'New period',
    YEAR: 'Year',
    UNABLE_LIST: 'Unable to list periods',
    ID: 'ID',
    NAME: 'Name',
    SELECT_PERIOD_AND_COMPANY: 'Select period and company'
  },
  JOB: {
    DELETED: 'Job deleted',
    JOB_RUNNING: 'Job running',
    PROGRESS: 'Progress',
    ID: 'Job ID',
    JOBS: 'Jobs',
    JOB: 'Job',
    NAME: 'Name',
    CREATED_AT: 'Created at',
    FINISHED_AT: 'Finished at',
    NUM_PROCESSED: 'Number of processed',
    NUM_SUCCESS: 'Number of successful',
    NUM_FAIL: 'Number of failed',
    UNABLE_CREATE: 'Unable to create job',
    UNABLE_LIST: 'Unable to list jobs',
    UNABLE_GET: 'Unable to get job',
    UNABLE_UPDATE: 'Unable to update job',
    CREATED: 'Job created',
    UPDATED: 'Job updated',
    CREATED_WITH_COUNT: 'Job created with number of records: ',
    CREATE: 'Create job',
    STOP: 'Stop job',
    STATUS: 'Status',
    ERROR_MESSAGE: 'Error message',
    RESULT: 'Result',
    NUM_TOTAL: 'Total',
    NUM_PROCESSED: 'Processed records',
    NUM_OK: 'OK records',
    NUM_FAIL: 'Failure records',
    LADOK_RUN: 'Ladok run',
    UPLOAD_NEW_LADOK: 'Ladda upp ny Ladok fil',
    LADOK_INFO: 'Notice that the Ladok job will be performed on the selected period on top of the page.',
    UNABLE_STOP: 'Unable to stop job',
    STOPPED: 'Job stopped',


    RESULTS: {
      PENDING: 'Pending',
      SUCCESS: 'Success',
      FAILED: 'Failed',
      SKIPPED: 'Skipped',
      ERROR: 'Error occured',
      CANCELLED: 'Cancelled',
      CANCEL_REQUESTED: 'Cancel requested'
    },
    STATUSES: {
      DRAFT: 'Draft',
      START: 'Waiting to start',
      RUNNING: 'Running',
      FINISHED: 'Finished',
      DELETED: 'Deleted',
      CANCELLED: 'Cancelled',
      CANCEL_REQUESTED: 'Abort'
    }
  },

  MEMBER_COMPANY: {
    DUPLICATE_MEMBERSHIPS: 'Membership already exists in company and period',
    UNABLE_LIST: 'Unable to list memberships',
    UPDATED: 'Membership updated',
    UNABLE_UPDATE: 'Unable to update membership',
    CREATED: 'Membership created',
    UNABLE_CREATE: 'Unable to create membership',
    DELETED: 'Membership deleted',
    UNABLE_DELETE: 'Unable to delete membership'
  },

  TEMPLATE: {
    INVALID_EMAILS: 'Reply-to and From email must be valid email addresses and have a verified domain. If you wish to add your own domain you must contact support@memlist.se',
    NEW: 'New template',
    SHOW_ALL: 'Show all',
    TEMPLATES: 'Templates',
    NAME: 'Template name',
    UNABLE_LIST: 'Unable to list templates',
    EMAIL_EDITOR: 'Email editor',
    FROM_EMAIL: 'From email',
    INVALID_EMAIL: 'Invalid email',
    EMAIL_MUST_BE_VALID: 'Email must be valid',
    CREATED: 'Template created',
    UNABLE_CREATE: 'Unable to create template',
    UPDATED: 'Template updated',
    UNABLE_UPDATE: 'Unable to update template',
    DELETED: 'Template deleted',
    UNABLE_DELETE: 'Unable to delete template',
    UNABLE_GET: 'Unable to get template',
    UNABLE_LIST: 'Unable to list templates',
    DOMAIN_OK: 'Domain OK',
    DNS_MISSING: 'DNS Missing',
    CONTACT_SUPPORT: 'Contact support',
    VALIDATION: 'Validation',
    COPY_TO: 'Copy to',
    SYSTEM: 'System',
    IS_SMS: 'Is SMS',
    IS_EMAIL: 'Is email',
    SMS_MESSAGE: 'SMS message',
    NUM_SMS: 'SMS count',
    TEXT: 'Clear text message',
    NOTICE: 'Number of words in clear text and HTML version must not differ too much',
    EDIT: 'Edit template',
    SYNC_INSTANCE: 'Sync instance',
    SYNCED: 'Template has synced',
    UNABLE_SYNC: 'Unable to sync',
    UNABLE_COPY: 'Unable to copy',
    COPIED: 'Template was copied',
    CONFIRM_DELETE: 'Confirm that you want to delete the template',
    DESIGNER: 'Designer',
    UPDATE_CLEARTEXT: 'Update cleartext from design',
    EXPORT_PDF: 'Export PDF'
  },

  GRANT: {
    SUM_APPLICATION: 'Total sum application (SEK)',
    BUDGET: 'Budget',
    AMOUNT_LEFT_IN_APPLICATION: 'You may apply for {amount} SEK in total',

    DESCRIPTION_PLACEHOLDER: 'Include all relevant information for the application',
    DESCRIPTION: 'Description of the application',
    NUM_PARTICIPANTS: 'Number of participants (optional)',
    NAME: 'Name of application',
    CONFIRM_PUBLISH: 'This grant application will be sent to the top company - confirm',
    NAME_PLACEHOLDER: 'Name of application',
    STARTTIME: 'Start time',
    ENDTIME: 'End time',
    FROM_DATETIME: 'Start date for project',
    TO_DATETIME: 'End date for project',

    PUBLISHED_HEADER: 'Application sent',
    PUBLISHED_HEADER_INFO: 'Your application has been sent for review to the owning company.',
    CREATED_APPLICATION: 'Application created',
    UPDATED_APPLICATION: 'Application updated',
    UNABLE_CREATE_APPLICATION: 'Unable to create application',
    UNABLE_UPDATE_APPLICATION: 'Unable to update application',
    UNABLE_GET_COMPANY: 'Unable to fetch company information for grants',
    INCORRECT_SUM: 'Incorrect sum. Must be less or equal to:',
    INCORRECT_BALANCE: 'Income and expences not balanced! Difference:',
    SEND_APPLICATION: 'Send application',
    NOT_PUBLISHED_WARNING: 'Application is not published and will not be processed by the company!'
  },

  SUPPORT: {
    UNHANDLED: 'Unhandled',
    ACTIVE: 'Active',
    EMAILS: 'Emails',
    ARCHIVE: 'Archived',
    SUMMARY: 'Summary',
    IS_DEDICATED: 'Dedicated',
    IS_THS: 'ThinkingHat',
    MESSAGE: {
      SENT_BY: 'Sent by',
      MESSAGE: 'Message',
      CREATE: 'New reply',
      STATUS: 'Status',
      STATUSES: {
        NONE: 'None',
        UNREAD: 'Unread',
        PENDING_SEND: 'Pending send',
        UNHANDLED: 'Unhandled',
        REPLIED: 'Replied',
        DELETED: 'Deleted'
      }
    },
    TICKET: {
      REPLY_TEXT: 'Reply text',
      DETAILS: 'Details',
      MESSAGES: 'Messages',
      CREATE: 'Create ticket',
      CREATED: 'Ticket created',
      UPDATED: 'Ticket updated',
      DELETED: 'Ticket deleted',
      UNABLE_DELETE: 'Unable to delete ticket',
      CREATED_AT: 'Created date',
      ASSIGNED_USER: 'Assigned user',
      NAME: 'Title',
      DESCRIPTION: 'Description',
      TYPE: 'Type',
      UNASSIGNED: 'Unassigned',
      ORIGINAL_SENDER: 'Original sender',
      TYPES: {
        UNDETERMINED: 'Undetermined',
        SALES_CONTACT: 'Sales contact',
        BUGREPORT: 'Bug report',
        EDUCATION: 'Education',
        SUGGESTION: 'Suggestion',
        INQUIRY: 'Inquiry'
      },
      STATUS: 'Status',
      STATUSES: {
        NONE: 'None',
        UNHANDLED: 'Unhandled',
        TODO: 'Todo',
        REPLY_SENT: 'Reply sent',
        NEW_MESSAGE: 'New message',
        RESOLVED: 'Resolved',
        CLOSED: 'Closed',
        TENTATIVE: 'Tentative'
      },
      MESSAGE_TYPE: 'Message type',
      MESSAGE_TYPES: {
        NONE: 'None',
        UNREAD: 'Unread',
        UNHANDLED: 'Unhandled',
        REPLIED: 'Replied',
        DELETED: 'Deleted'
      }
    },
  },

  TODO: {
    UNABLE_GET_WIZARD: 'Unable to get task wizard',
    TODO_WIZARD_COMPLETED: 'Task handin is now completed.',
    START_DATE: 'Start date',
    END_DATE: 'End date',

    I_AM_FINISHED_WITH_TASK: 'I am done with this task',
    I_AM_NOT_FINISHED_WITH_TASK: 'I am NOT done with this task',
    CONFIRM_STATUS_TASK: 'Confirm the status of this task',

    TODO_WIZARD_HEADER: 'Tasks to do',
    UPLOAD_FILE: 'Upload file',
    WIZARD_NOT_DONE_STATUS: 'Must set as done',
    WIZARD_NO_FILES_UPLOADED: 'Files missing',
    NO_FILES_UPLOADED: 'No files uploaded',
    SUBTASK_UPDATED: 'Subtask has been updated',
    PUBLISH_SUCCESS_HEADER: 'Published',
    PUBLISH_SUCCESS_INFO: 'The task has been published',
    CONFIRM_PUBLISH: 'Publish',
    CONFIRM_PUBLISH_INFO: 'Are you sure you want to publish the task?',
    NEW: 'New task',
    CLOSE: 'Close task',
    DELETE: 'Delete task',
    UNABLE_GET: 'Unable to get todo',
    UNABLE_DELETE_SUBTASK: 'Unable to delete subtask',
    SUBTASK_DELETED: 'Subtask was deleted',
    PUBLISHED: 'Task has been published',
    UNABLE_PUBLISH: 'Unable to publish task',
    HEADER: 'Task manager',
    CREATED: 'Task created',
    UPDATED: 'Task updated',
    UNABLE_CREATE: 'Unable to create task',
    UNABLE_UPDATE: 'Unable to update task',
    WAIT : 'Waiting',
    DRAFT: 'Draft',
    NOT  : 'Not done',
    DONE : 'Done',
    CN   : 'Confirmed',
    DEL  : 'Deleted',
    ACC  : 'Accepted',
    REJ  : 'Rejected',
    BAC  : 'Backlog',
    FUT  : 'Future',
    UNREAD : 'Unread',
    READ : 'Read',
    DOC  : 'Closed',
    FROM_DATETIME: 'From date',
    TO_DATETIME: 'To date',
    PUBLISHED_INFO: 'Task is published and visible to companies',
    NOT_PUBLISHED_INFO: 'Task is not published, companies will not see it',
    PUBLISH: 'Publish',
    CONFIRM_PUBLISH: 'Confirm publish',
    ARE_YOU_SURE_PUBLISH: 'Proceed to publish the tasks to selected companies?',
    COMPANIES: 'Companies',
    NAME: 'Name of tasks',
    DESCR: 'Description',
    SEND_TO_ADMINS_ON_STATUS: 'Send email to admins on status updates',
    NO_SUBTASKS_INFO: 'There are no subtasks in the task. You need to create subtasks before this can be published',
    AT_LEAST_1_SUBTASK_INFO: 'There must be at least 1 subtask',
    MISSING_FROM_DATE: 'Missing start date',
    MISSING_TO_DATE: 'Missing end date',
    SUBTASK_DELETED: 'Subtask deleted',
    UNABLE_DELETE_SUBTASK: 'Unable to delete subtask',
    UNABLE_GET: 'Unable to get task',
    UNABLE_GET_SUBTASKS: 'Unable to get substasks'
  },

  SENDOUT: {
    NOT_AVAILABLE: 'Unavailable',
    NUM_OK: 'OK',
    NUM_FAIL: 'Fails',
    UPDATED: 'Sendout updated',
    UNABLE_UPDATE: 'Unable update sendout',
    NO_RECIPIENTS_ERROR: 'There are no recipients in the sendout'
  },

  SHOP_ORDER: {
    STRIPE_DASHBOARD_LINK: 'Stripe admin link',
    DELETED: 'Order deleted',
    REFUND_SWISH: 'Refund Swish',
    REFUND_STRIPE: 'Refund Stripe',
    SWISH_REQUEST_ID: 'Swish Request ID',
    NO_REFUND_METHOD: 'No refund available',
    
    UPDATED: 'Order updated',
    STRIPE_ID: 'Stripe ID',
    SWISH_ID: 'Swish ID',
    DINTERO_TRANSACTION_ID: 'Dintero ID',
    REFUND_AMOUNT_ERROR: 'Refund amount must be less than or equal to the paid amount',
    REFUND_SUCCESS: 'Refund created',
    REFUND_FAILED: 'Unable to create refund',
    MAKE_REFUND: 'Create refund',
    REFUNDED_AMOUNT: 'Refunded amount',
    NOT_REFUNDED: 'Not refunded',
    NUM_ROWS: 'Number of rows',
    CREATED_AT: 'Created date',
    DINTERO_ID: 'Dintero ID',
    DINTERO_TRANSACTION_DATE: 'Dintero date',
    PAYMENT_AMOUNT: 'Paid amount',
    STATUS: 'Status',
    DETAILS: 'Details',
    CREATING: 'Creating order',
    EDITING: 'Editing order',
    PAYMENT_METHOD: 'Method',
    NONE_FOUND: 'No orders found',
    TYPE: 'Type',
    PAYMENT_STATUS: 'Payment status',
    ITEMS: 'Articles',

    REFUND_REASON: 'Refund reason',
    REFUND_ID: 'Refund ID',
    REFUND_AMOUNT: 'Refund Amount',
    REFUND_AT: 'Refund at',
    REFUND: 'Refund',
    CREATE_REFUND: 'Create refund',

    PAYMENT_METHODS: {
      DINTERO: 'Dintero',
      INVOICE: 'Invoice',
      FORTNOX_INVOICE: 'Fortnox invoice',
      STRIPE: 'STRIPE',
      bgmax: 'BGMax',
      UNKNOWN: 'Unknown',
      REDEEMCODE: 'Coupon',
      FREE: 'Free',
      SWISH: 'Swish',
    },

    PAYMENT_STATUSES: {
      LEGACY: 'Legacy',
      FREE: 'Free',
      NOT_PAID: 'Not paid',
      PARTIALLY_PAID: 'Partially paid',
      PAID_IN_FULL: 'Paid',
      REFUND: 'Refund'
    },
    STATUS: 'Status',
    STATUSES: {
      NONE: 'None',
      CREATED: 'Created',
      WAIT_PAYMENT: 'Waiting payment',
      PARTIALLY_PAID: 'Partially paid',
      PAID_IN_FULL: 'Paid in full',
      DELETED: 'Deleted',
      FAILED: 'Failed',
      DONE: 'Finished - Done',
      CANCELLED: 'Finished - Cancelled',
    },
    TYPES: {
      UNKNOWN: 'Unknown',
      REGISTER: 'Registration',
      RENEW: 'Renew',
      TICKET: 'Ticket',
      COMPANY: 'Company',
      MEMBER: 'Member',
      REDEEM_CODE: 'Redeem code',
      STRIPE_INVOICE: 'Stripe',
    },

    ALREAD_PAID: 'Order has already been paid',
    FOR_MEMBER: 'For member',
    PAID_AT: 'Paid at',
    DATE: 'Date',
    PRODUCT: 'Article',
    AMOUNT: 'Amount',
    TOTAL_AMOUNT: 'Amount total',
    FILTER: 'Filter',
    ANY_DATE: 'Any date',
    ANY_STATUS: 'Any status',
    COUNT: 'Number of orders',
    CREATED_FROM: 'Created from (including)',
    CREATED_TO: 'Created to (including)',
    BASED_ON_FILTER: 'Based on filters',
    SELECTED_IN_TABLE: 'Selected orders in the table',
    UNABLE_LIST_ORDER: 'Unable to list orders',
    CONFIRM_DELETE: 'Are you sure you want to delete the order?',
    LINKTO: 'Link to payment page',
    UNABLE_DELETE: 'Unable to delete order',
    SHOPORDER_DELETED: 'Order was deleted',
    UNABLE_FETCH: 'Unable to fetch order',
    ORDER_CREATED: 'Order was created',
    UNABLE_ORDER_CREATE: 'Unable to create order',
    ORDER_UPDATED: 'Order was updated',
    UNABLE_ORDER_UPDATE: 'Unable to update order',
    SEND_LINK: 'Send link',
    SEARCH_PARAMS: 'Ordernumber, OCR, Name or E-mail',
    UNABLE_GET_NUM_ORDERS: 'Unable to get num orders',
    CLIPBOARD_COPY: 'Copied to clipboard',
    UNABLE_CLIPBOARD_COPY: 'Unable to copy to clipboard',
    PAY_REF: 'Paid Ref.',
    INVALID_LINK: 'Link is invalid',
    ERROR_GET: 'Error getting your order',
    NO_ORDERS: 'You have no pending payments',
    CANCEL_IN_SECONDS: 'Order is cancelled in {seconds} seconds',
    TIMEOUT: 'Transaction took too long and has aborted',
    LATE: 'Late',
    PAID: 'Paid',
    WAIT: 'Unpaid',
    ARTICLE: 'Article',
    TOTAL: 'Total',
    AMOUNT: 'Amount',
    COUNT: 'Count',
    PAY_NOW: 'Pay now',
    SAVE_INVOICE: 'Save invoice',
    PAYMENTS: 'Payments'
  },

  BILLING: {
    REMAINING: 'Remaining budget',
    ADMINISTRATE: 'Change >>'
  },


  COMPANY_BILLING: {
    CREATE: 'Create',
    CREATED_AT: 'Created at',
    UPDATED_AT: 'Updated at',
    PAID_AT: 'Paid at',
    SCHEDULED_AT: 'Scheduled at',
    FROM_DATE: 'From date',
    TO_DATE: 'To date',
    DUE_DATE: 'Due date',
    TITLE: 'Title',
    DESCRIPTION: 'Description',
    STATUS: 'Status',
    STATUSES: {
      SCHEDULED: 'Scheduled',
      SENT: 'Sent',
      LATE: 'Late',
      PAID_FULL: 'Paid full',
      PAID_PARTIAL: 'Paid partial',
      DISPUTE: 'Dispute',
      REFUNDED: 'Refunded',
    },
    ALL: 'All',
    BILLING_SERVICE: 'Service',
    BILLING_SERVICES: {
      MEMLIST: 'Memlist',
      SMS_USAGE: 'SMS Usage',
      SMS_PREPURCHASE: 'SMS Prepurchase',
      SPAR_USAGE: 'SPAR Usage',
      SPAR_PREPURCHASE: 'SPAR Prepurchase',
      SUPPORT: 'Support',
      MEMBERCARD: 'Membercard',
      DEVELOPMENT: 'Development'
    },

    CREATED: 'Company billing created',
    UPDATED: 'Company billing updated',
    DELETED: 'Company billing deleted',

    UNABLE_CREATE: 'Unable to create company billing',
    UNABLE_UPDATE: 'Unable to update company billing',
    UNABLE_GET: 'Unable to get company billing',
    UNABLE_LIST: 'Unable to list company billing'
  },

  COMPANY_PERIOD: {
    MUST_SELECT_FILE_AND_PERIOD: 'Must select file and period',
    SKV_ADVANCE_IMPORTED_AT: 'SKV advance import date',
    SKV_SETTLED_IMPORTED_AT: 'SKV settled import date',
    UPDATED_AT: 'Latest update',
    ADVANCE_PAYOUT_AMOUNT: 'Payout',
    ADVANCE_PAYOUT_AMOUNT_UPDATED: 'Advance payout amount updated',
    UNABLE_ADVANCE_PAYOUT_AMOUNT_UPDATE: 'Unable to update advance payout amount',
    CREATE_NEW_REGISTER_FILE: 'Create SKV Registration',
    CREATE_CORRECTIONS_FILE: 'Create SKV Correction',
    CNUM: 'Company no.',
    MEMBER_COUNT: 'Member count',
    SKV_INCOME_AMOUNT: 'Prelim. Income',
    ADVANCE_INCOME_AMOUNT: 'Prelim. Income',
    SETTLED_AMOUNT: 'Settled Income',
    SETTLED_INCOME_AMOUNT: 'Settled Income',
    CALCULATE_SETTLED: 'Calculate Final',
    CALCULATE_ADVANCE: 'Calculate Advance',
    ADVANCE_CALCULATED: 'Advance was calculated',
    ADVANCE_INFO_HEADER: 'Advance {period}',
    SETTLED_INFO_HEADER: 'Settled payment {period}',
    NO_ROWS_FOUND: 'No rows found',
    NO_COMPANY_PERIODS: 'There are no financial years for {period} for any companies',
    CREATE_FOR_ALL: 'Create financial year for {period}',
    NEW: 'New financial year',
    UNABLE_LIST: 'Unable to list financial year',
    UNABLE_GET: 'Unable to get financial year',
    UNABLE_CREATE: 'Unable to create financial year',
    UNABLE_UPDATE: 'Unable to update financial year',
    UNABLE_DELETE: 'Unable to delete financial year',
    CREATED: 'Financial year created',
    UPDATED: 'Financial year updated',
    DELETED: 'Financial year deleted',
    ACTIVE: 'Active',
    COMPANY_NAME: 'Name',
    SHORTNAME: 'Shortname',
    ORG_NUMBER: 'Org. number',
    POSTADDRESS: 'Post address',
    PHONE: 'Phone',
    EMAIL: 'Email',
    MISSING_PREVIOUS_PERIOD: 'You cant create the financial year as you need a period from 2 years prior',
    A1_NUM_MEMBERS: 'NUM_MEMBERS',
    A2_SUM_BASIS_FEE: 'SUM_BASIS_FEE',
    A3_FEE_PER_MEMBER: 'FEE_PER_MEMBER',
    A4_ADVANCE_FROM_SKV: 'ADVANCE_FROM_SKV',
    A5_ADM_FEE_MEMBER: 'ADM_FEE_MEMBER',
    A6_PCT_FEE_SUBCOMPANY: 'PCT_FEE_SUBCOMPANY',
    A7_FEE_TO_TOPCOMPANY: 'FEE_TO_TOPCOMPANY',
    A8_ADVANCE_PAYOUT: 'ADVANCE_PAYOUT',
    B1_NUM_MEMBERS: 'NUM_MEMBERS',
    B2_SUM_BASIS_FEE: 'SUM_BASIS_FEE',
    B3_FEE_PER_MEMBER: 'FEE_PER_MEMBER',
    B4_SETTLED_CALCULATED_SUM: 'SETTLED_CALCULATED_SUM',
    B5_ADM_FEE_MEMBER: 'ADM_FEE_MEMBER',
    B6_PCT_FEE_SUBCOMPANY: 'PCT_FEE_SUBCOMPANY',
    B7_FEE_TO_TOPCOMPANY: 'FEE_TO_TOPCOMPANY',
    B8_SETTLED_SUM: 'SETTLED_SUM',
    B9_DIFFERENCE: 'DIFFERENCE',
    ADVANCE_CALCULATED_AT: 'Advance calculated at',
    SETTLED_CALCULATED_AT: 'Settled calculated at'
  },


  PAGES: {
    MEMBER_REGISTRATIONS: {
      UNABLE_GET_SYSTEM_SETTINGS: 'Unable to get system settings',
      APPLICATION_TEMPLATES: 'Application templates',
      CONFIRM_REJECT_EXIT: 'Confirm reject exit',
      CONFIRM_REJECT_EXIT_MESSAGE: 'Are you sure you want to reject the exit application?',
      
      REQUESTS: 'Requests',
      HISTORY: 'History',
      EXITS: 'Exits',
      EXITS_HISTORY: 'Exits history',
      CHECKBOX_REG_APPROVAL: 'Registration requires approval',
      TIP_REG_APPROVAL: 'Members must be approved by admin before they can log in.',
      HEADER_REG_APPROVAL: 'Member requests',
      CHECKBOX_EXIT_APPROVAL: 'Exit requires approval',
      TIP_EXIT_APPROVAL: 'Members must be approved by admin before they can exit.',
      HEADER_EXIT_APPROVAL: 'Exit requests',
      CONFIRM_ACCEPT_MESSAGE: 'Are you sure you want to accept the member application?',
      CONFIRM_REJECT_MESSAGE: 'Are you sure you want to reject the member application?',
      CONFIRM_ACCEPT: 'Accept member application',
      CONFIRM_REJECT: 'Reject member application',
      UNABLE_UPDATE: 'Unable to update member application',
      UNABLE_LIST: 'Unable to list member applications',
      UNABLE_DOWNLOAD: 'Unable to download member applications',
    },

    MEMBER_JOBS: {
      SELECTED: 'Selection',
      ACCEPTED_AT: 'Accepted at',
      ACCEPTED_BY: 'Accepted by',
      CREATE_NEW: 'Create for period',
      ERROR_RUNNING_JOB: 'Error running job',
      UNABLE_LOAD: 'Unable to load',
    },
    LOGIN: {
      TIP_REDIRECT: 'Redirect unauthenticated users to this page',
      HEADER_REDIRECT: 'Redirect unauthenticated users',
      MUST_HAVE_LOGIN_OPTION: 'At least one login option must be selected',
      HEADER_EMAILPASSWORD: 'Login with e-mail and password',
      TIP_EMAILPASSWORD: 'Activate to login with e-mail and password.',
      CHECKBOX_EMAILPASSWORD: 'E-mail and password',
      HEADER_BANKID: 'Login with BankID',
      TIP_BANKID: 'Activate to login with BankID.',
      CHECKBOX_BANKID: 'BankID',
      CONTACT_SUPPORT_BANKID: 'Contact support@memlist.se to unlock this feature.'
    },
    REPORTS: {
      REPORTS: 'Reports',
      DOWNLOAD_SALES: 'Download sales report',
      DOWNLOAD_MUCF_1: 'MUCF Report 1',
      DOWNLOAD_MUCF_2: 'MUCF Report 2',
      DOWNLOAD_MUCF_3: 'MUCF Report 3',
      DOWNLOAD_MONTH_REPORT: 'Medlemskapsrapport',
      MUST_SELECT_INTERVAL: 'Must select interval',
      FROM_DATE: 'From date',
      TO_DATE: 'To date',
      FOR_YEAR: 'For year'
    },
    PAGE: {
      PAGES: 'Pages/Modules',
      PAGE: 'Page',
      ON: 'On',
      SUPERADMIN: 'Superadmin',
      ACTIVATED: 'Page activated',
      REMOVED: 'Page removed',
      UNABLE_LIST: 'Unable to list pages'
    },
    FAITH: {
      DEACTIVATE_INFO: 'Remember that you need to create a correction file and send to skatteverket.',
      SKV_INCLUDE_MINORS: 'Include minors for SKV',
      IMPORT_ERROR_MESSAGE: 'Error',
      IMPORT_ERROR_ROW: 'Row',
      IMPORT_FILE_ERRORS: {
        COMPANY_NOT_FOUND: 'Company {company} not found',
        COMPANY_PERIOD_NOT_FOUND: 'Company {company} has no period {period}',
        YEAR_MISMATCH: 'Selected period and file period does not match',
        INVALID_DATE_FORMAT: 'Date in file is invalid',
        ADVANCE_FOR_SETTLED_COMPANY_PERIOD_NOT_FOUND: 'Advance period is missing for settled period',
        ADVANCE_NOT_CALCULATED_FOR_SETTLED_COMPANY_PERIOD_NOT_FOUND: 'Advance period is not calculated for the settled period',
      },
      COMPANY_PERIOD_NOT_EXISTS: 'Company year missing for period {period} for company {company}',
      MOVE_REG_AFTER: 'Move new members to next period when registered after date',
      ADVANCE_CALCULATED: 'Advance was calculated',
      UNABLE_CALCULATE_ADVANCE: 'Unable to calculate advance',
      UNABLE_CALCULATE_SETTLED: 'Unable to calculate settlements',
      SETTLED_CALCULATED: 'Settlement calculated',
      REGISTRATIONS: 'Registrations',
      CORRECTIONS: 'Corrections',

      TIP_FEES: 'Fees for companies globally is set here.',
      HEADER_FEES: 'Fee for companies',

      FEE_PCT: 'Fee percentage',
      ADM_FEE: 'Administration fee amount',
      MEM_PCT: 'Fee percentage per member',
      MEM_FEE: 'Fee per member',
      GDPR_CONTACT_NAME: 'GDPR contact name',
      GDPR_CONTACT_EMAIL: 'GDPR contact email',

      MUST_BE_ENGLISH_FLOAT: 'Must be english float, no commas',
      AMOUNT_PAID_TO_COMPANY_INFO: 'Amount paid for {period} to {company}',
      AMOUNT_PAID: 'Amount paid',

      TIP_INTAG: 'Settings for skatteverket',
      HEADER_INTAG: 'Registrations to skatteverket',

      TIP_REDOVISNINGSRAKNING: 'Properties for the redovisningsräkning report.',
      HEADER_REDOVISNINGSRAKNING: 'Redovisningsräkning',
      REDOVISNINGSRAKNING: {
        SIGNED_NAME: 'Signed name',
        INVALID_SIGNED_NAME: 'Invalid name',
        UNABLE_CREATE: 'Unable to create redovisningsräkning',
        NEW_LINE_INFO: 'Insert \\n for new row',
        LEFT_HEADER: 'Left header',
        INVALID_LEFT_HEADER: 'Invalid left header',
        RIGHT_HEADER: 'Right header',
        INVALID_RIGHT_HEADER: 'Invalid right header',
      },
      SETTINGS: 'Settings',
      SUMMARY: 'Summary',
      IMPORT: 'Import',
      EXPORT: 'Export',
      REPORTS: 'Reports',
      GENERATE: 'Generate',
      FILE_TYPE: 'File type',
      FOR_PERIOD: 'For period',
      GENERATED_AT: 'Generated',
      USER_NAME: 'User',
      HISTORY: 'History',
      GENERATE_FILE: 'Generate new file',
      UNABLE_LOAD_FILES: 'Unable to load files',
      SKV_EXPORT_REGISTRATIONS: 'Skatteverket: Members',
      SKV_EXPORT_INCOME_PERSONNR_ADVANCE: 'Skatteverket: Income - Preliminary',
      SKV_EXPORT_INCOME_PERSONNR_SETTLED: 'Skatteverket: Income - Settled',
      SKV_IMPORT_INCOME_PERSONNR_ADVANCE: 'Skatteverket: Import income - Preliminary',
      SKV_IMPORT_INCOME_PERSONNR_SETTLED: 'Skatteverket: Import income - Settled',
      SKV_EXPORT_CORRECTIONS: 'Skatteverket: Correctionsfile',
      UPLOAD_SKATTEVERKET_FILE: 'Upload Skatteverket file',
      FILE_IMPORTED: 'Import file created',
      UNABLE_FILE_IMPORT: 'Unable to upload import file',
      UPLOAD_FILE: 'Upload file',
      NO_FILES: 'No files',
      UNABLE_EXPORT_FILE: 'Unable to export file',
      NAME_MISSING: 'Name not set',
      FILE_EXPORTED: 'File exported'
    },
    EDUCATIONS: {
      ALREADY_EXISTS: 'Education already exists',
      FACULTIES: 'Faculties',
      EDUCATIONS: 'Educations',
      PROGRAM_CODE: 'Program code',
      IMPORT_EDUCATIONS: 'Import educations',
      ADD: 'Add education',
      SECTION: 'Section',
      SECTION_COMPANY: 'Section/Company',
      NUM_ROWS: 'Number of rows: ',
      FACULTY: 'Faculty',
      CODE: 'Code',
      PROGRAM: 'Program',
      COURSE: 'Kurs',
      FREE_COURSE: 'Free course',
      NEW: 'New education',
      DELETED: 'Education deleted',
      UNABLE_DELETE: 'Unable to delete education',
      CREATED: 'Education was created',
      UNABLE_CREATE: 'Unable to create education',
      UPDATED: 'Education updated',
      UNABLE_UPDATE: 'Unable to update education',
      UNABLE_GET: 'Unable to get education',
      UNABLE_LIST: 'Unable to list educations',
      ERROR_COMPANY: '',
      ERROR_FACULTY: '',
      ERROR_TYPE: '',
      ERROR_CODE: '',
      ERROR_NAME: '',
      NAME: 'Education name',
      TYPE: 'Typ',
      TYPE_NONE: 'None',
      TYPE_PROGRAM: 'Program',
      TYPE_COURSE: 'Course',
      TYPE_FREE_COURSE: 'Free course',
      NEW_FACULTY: 'New faculty',
      FACULTY_DELETED: 'Faculty deleted',
      UNABLE_FACULTY_DELETE: 'Unable to delete faculty',
      FACULTY_CREATED: 'Faculty created',
      UNABLE_FACULTY_CREATE: 'Unable to create faculty',
      FACULTY_UPDATED: 'Faculty updated',
      UNABLE_FACULTY_UPDATE: 'Unable to update faculty',
      UNABLE_GET_FACULTY: 'Unable to get faculty',
      UNABLE_LIST_FACULTY: 'Unable to list faculties',
      FACULTY_NAME: 'Faculty',
      FACULTY_DESCR: 'Description',
      IMPORT_SECTIONS: 'Import sections'
    },
    APIKEYS: {
      DINTERO_SETTINGS: 'Dintero settings',
      SERVICE: 'Service',
      API_KEY_DATA: 'Api key data',
      API_SERVICE: 'External service',
      PUBLIC_TOKEN: 'Public token',
      STRIPE_SETTINGS: 'Stripe settings',
      NEW: 'New Api key',
      MUST_BE_SINCH_PHONE: 'Must be phone number in Sinch admin',
      SET_SERVICEPLAN_ID: 'Set service plan ID from Sinch admin',
      CHECK_SINCH_ADMIN: 'From Sinch admin',
      FETCH_ACCESS_TOKEN: 'Fetch access token',
      SET_AFTER_SUCCESS_SPAR: 'Will be set after successful authentication with SPAR admin',
      EXISTS_IN_SPAR_ADMIN: 'Exists in SPAR admin',
      UNABLE_GET: 'Unable to get API Keys'
    },
    DASHBOARD: {
      MEMBERS: 'Members',
      MEN: 'Men',
      WOMEN: 'Women',
      OTHERS: 'Others',
      RENEWALS: 'Renewals',
      TOTAL: 'Total',
      DONE: 'Done',
      NOTDONE: 'Not done',
      LATEST_MEMBERS: 'Latest members',
      MEMBER_JOINING: 'Members joined',
      CHART_START_DATE: 'Start date',
      CHART_END_DATE: 'End date',
      THINGS_TODO: 'You have things to do',
      TODO_TEXT: 'Don\'t forget to finish the tasks below before the deadline date has passed.',
      TODO: 'To do',
      BUDGET_LOW: 'Budget low',
    },
    CAMPAIGNS: {
      ACTIVE:'Active',
      ACTIVE_TIP:'Activates the campaign and can then be used by users.',
      ACTIVE_HEADER_TIP:'Indicates if the campaign is active or not.',
      ACTIVE_WARNING: 'Note: All registrating members within given time span vill recieve this discount on ALL articles!',
      MAX_USES: 'Maximum usages',
      MAX_USES_TIP: 'Maximum number of codes or users available to take part in the discount.',
      INVALID_MAX_USES: 'Invalid maximum usages',
      NAME: 'Campaign',
      NAME_TIP: 'Name of the campaign visible only to administrators.',
      NAME_HEADER_TIP: 'Name of the campaign.',
      INVALID_NAME: 'Invalid name',
      AMOUNT: 'Amount',
      AMOUNT_HEADER_TIP: 'The number of used discounts versus available discounts.',
      USE_CODE: 'Must supply campaign code',
      USE_CODE_TIP: 'By activating "Must supply campaign code" checkbox, the user need to enter a specific campaign code to receive the possible discount. If this box is not checked, the discount will be applied according to the "first come, first served" principle according to the number set in the Max number field.',
      VALID_CODE: 'Valid code',
      INVALID_CODE: 'Invalid kod',
      CODE: 'Campaign code',
      CODE_TIP: 'Choice of code to be used by the user in order to apply a certain discount to a purchase.',
      CODE_HEADER_TIP: 'Campaign code.',
      CODE_INFO: 'Code to be used by member, minimum 4 characters',
      INVALID_PERCENT: 'Invalid percentage',
      VALID_PERCENT: 'Valid percentage',
      PERCENT_DISCOUNT: 'Percent discount',
      PERCENT_DISCOUNT_TIP: 'The percentage that will be applied as a discount on the item.',
      PERCENT_DISCOUNT_HEADER_TIP: 'The percentage discount.',
      INVALID_DATE: 'Invalid date',
      VALID_DATE: 'Valid date',
      TO_DATE: 'To date',
      TO_DATE_TIP: 'To which date the campaign will be active.',
      TO_DATE_HEADER_TIP: 'The last valid day of the discount code.',
      DATE_DESCR: 'YYYY-MM-DD',
      FROM_DATE: 'From date',
      FROM_DATE_TIP: 'From which date the campaign will be active.',
      FROM_DATE_HEADER_TIP: 'The first valid day of the discount code.',
      PRIORITY: 'Priority',
      DESCR: 'Description',
      DESCR_TIP: 'This text is only shown to administrators to provide an explanation of the reason for the discount.',
      INVALID_DESCR: 'Invalid description',
      CAMPAIGNS: 'Campaigns',
      NEW: 'New campaign',
      DELETED: 'Campaign deleted',
      UPDATED: 'Campaign updated',
      CREATED: 'campaign created',
      UNABLE_DELETE: 'Unable to delete campaign',
      UNABLE_LIST: 'Unable to list campaign',
      UNABLE_GET: 'Unable to get campaign',
      UNABLE_UPDATE: 'Unable to update campaign',
      UNABLE_CREATE: 'Unable to create campaign',
      EDIT_ICON_TIP: 'Edit campaign.',
      DELETE_ICON_TIP: 'Delete campaign.'
    },

    FILES: {
      NEW_FILE: 'New file',
      UNABLE_CREATE_FILE: 'Unable to create file',
      SUCCESS_CREATE_FILE: 'File created',
      UNABLE_DOWNLOAD: 'Unable to download file',
      UPLOADED: 'File was uploaded',
      MUST_BE_LESS: 'File must be less than 50MB',
      UNABLE_UPLOAD: 'Unable to upload file',
      MUST_BE_CSV: 'Must be CSV file format',
      FILE_NAME: 'File name',
      FILE_NAME_TIP: 'Name of the uploaded file.',
      DISPLAY_NAME: 'Display name',
      DISPLAY_NAME_TIP: 'Name displayed on member page.',
      DATE: 'Date',
      DATE_TIP: 'Date when the file was upploaded.',
      HANDLE: 'Handle',
      HANDLE_TIP: 'Handle the file with different functions.',
      COPY_LINK_ICON_TIP: 'Copy link.',
      DOWNLOAD_FILE_ICON_TIP: 'Download file.',
      EDIT_FILE_ICON_TIP: 'Edit file.',
      DELETE_FILE_ICON_TIP: 'Delete file.',
      IS_S3: 'Is in S3',
      IS_S3_TIP: 'If the file is stored inside an S3 Bucket (in Sweden).',
      ACCESSABILITY: 'Accessability',
      PUBLIC_LINK: 'Public link',
      PUBLIC_LINK_TIP: 'TODO',
      MEMBER_VISIBLE: 'Visible on My page',
      MEMBER_VISIBLE_TIP: 'TODO',
      FILE_INFO: 'File information',
      ONLY_FOR_MEMBER: 'Visas endast för medlemmen som äger filen'
    },

    MEMBERTYPES: {
      UNABLE_LIST_MEMBERTYPES: 'Unable to list member types'
    },

    JOBS: {
      UNABLE_CREATE: 'Unable to create job',
      UNABLE_LIST: 'Unable to list jobs',
      UNABLE_GET: 'Unable to get job',
      UNABLE_UPDATE: 'Unable to update job',
      CREATED: 'Job created',
      UPDATED: 'Job updated',
      CREATED_WITH_COUNT: 'Job created with number of records: ',
      CREATE: 'Create job',
      STOP: 'Stop job',
      STATUS: 'Status',
      ERROR_MESSAGE: 'Error message',
      RESULT: 'Result',
      NUM_TOTAL: 'Total',
      NUM_PROCESSED: 'Processed records',
      NUM_OK: 'OK records',
      NUM_FAIL: 'Failure records',
      LADOK_RUN: 'Ladok run',
      UPLOAD_NEW_LADOK: 'Ladda upp ny Ladok fil',
      LADOK_INFO: 'Notice that the Ladok job will be performed on the selected period on top of the page.',
      UNABLE_STOP: 'Unable to stop job',
      STOPPED: 'Job stopped',
    },
    REDEEMCODES: {
      REDEEMCODES: 'Redeem codes',
      VALID_FOR_ARTICLE: 'Valid for article',
      VALID_FOR_EVENT: 'Valid for event',
      MEMBERSHIP_IN_COMPANY: 'Membership in company',
      RECIPIENT: 'Recipient',
      SEND_TO_MEMBER: 'Send to member',
      CREATE_SMS_EMAIL_INVITE: 'Create SMS/E-mail invite',
      SELECTED_MEMBER: 'Selected member',
      TO_EMAIL: 'To email',
      TO_MOBILE: 'To phone/SMS',
      PHONE_INFO: 'Use + and country code, even within Sweden',
      EXPIRES_AT: 'Valid until date',
      DATE_INFO: 'YYYY-MM-DD',
      GENERATED_BY_SYSTEM: 'Generated by system',
      MUST_HAVE_EMAIL: 'Must have e-mail',
      MUST_SELECT_RECIPIENT_TYPE: 'Must select recipient type',
      MUST_SELECT_MEMBER: 'Must select member',
      MUST_SELECT_ARTICLE: 'Must select article',
      MUST_SET_EXPIRATION: 'Must set expiration date'
    },
    SHOPITEM: {
      NEXT_SHOP_ITEM: 'Renew into',
      CREATED_AT: 'Created at',
      CONFIRM_SELECTED_SHOPITEMS: 'Confirm selection',
      SELECT_SHOPITEMS: 'Search memberships',
      COMPANY: 'Company',
      SEARCH_NAME: 'Search article name',
      CURRENCY: 'Currency',
      NAME: 'Article name',
      VAT_FREE: 'No VAT',
      UNABLE_GET: 'Unable to get article',
      UNABLE_DELETE: 'Unable to delete article',
      UNABLE_LIST: 'Unable to list articles',
      UNABLE_CREATE: 'Unable to create article',
      UNABLE_UPDATE: 'Unable to update article',
      DELETED: 'Article was deleted',
      UPDATED: 'Article was updated',
      CREATED: 'Article was created',
      UNABLE_LIST_SHOPITEMS: 'Unable to list shop items',
      SELECT: 'Select article',
      ARTICLE: 'Article',
      MISSING: 'Article missing',
      ACTIVATE_STRIPE: 'Activate Stripe payments',
      STRIPE_ACTIVATION_INFO: 'Stripe Api keys must be set in "Api keys"',
      NO_MEMBERSHIPS: 'Company lacks membership articles',
      SHOPITEMS: 'Articles',
      NO_API_KEY: 'No API Key is setup for payments.',
      CONFIRM_DELETE: 'Are you sure you want to delete the item?',
      CREATE_FOR_ALL: 'Create for all companies',
      PRESENTATION: 'Presentation',
      DESCR: 'Description',
      IMAGE: 'Image of the article',
      UPLOAD_IMAGE: 'Upload image',
      PRIORITY: 'Priority',
      PRICE_SEK: 'Price',
      VAT_SEK: 'VAT',
      EX_VAT: 'Price per unit excl. VAT',
      ACCOUNTING: 'Accounting',
      BOOK_ACCT_VAT: 'Book account VAT',
      BOOK_ACCT_ARTICLE: 'Book account article',
      BOOK_PLACE: 'Cost center',
      PROJECT: 'Project',
      TAGS: 'Tags',
      TAGS_INFO: 'Write text and ENTER',
      MEMBERSHIP_SETTINGS: 'Membership settings',
      MEMBER_TYPE_ID: 'Member type',
      MIN_AGE: 'Minimum allowed age for purchase/selection',
      MAX_AGE: 'Maximum allowed age for purchase/selection',
      UNABLE_DUPLICATE: 'Unable to duplicate',
      UNABLE_COPY: 'Unable to copy',
      COPIED: 'Copied article',
      DUPLICATED: 'Duplicated article',
      IMAGE_UPLOADED: 'Image was uploaded',
      UNABLE_IMAGE_UPLOAD: 'Unable to upload image',
      SAVE_TICKET: 'Save ticket',
      SAVE: 'Save article',
      COPY_TO_NEXT_PERIOD: 'Copy to next period',
      NEW: 'New article',
      NAME: 'Article name',
      AMOUNT: 'Amount',
      AMOUNT_VAT: 'Amount VAT',
      VAT_PCT: 'VAT %',
      IS_ACTIVE: 'Active (will be displayed in the registration)',
      IS_SHARED: 'Is shared by all companies',
      IS_MEMBER_FEE: 'Is member fee',
      IS_EXTRA: 'Is extra',
      UNABLE_DELETE_ON_ORDERS: 'Unable to delete item when used on orders or memberships',
      CAMPAIGN_CODE: 'Campaign code',
      CAMPAIGN_CODE_TIP: 'If you have an active promotional code, you can enter it here to apply the possible discount.',
      APPLY_CAMPAIGN_CODE: 'Apply',
      UNABLE_APPLY_CAMPAIGN_CODE: 'Unable to apply campaign code.',
      CAMPAIGN_CODE_VALID: 'Valid campaign code',
      CAMPAIGN_CODE_INVALID: 'Invalid campaign code',
      RECEIVER: 'Refers To',
      RECEIVER_OPTION1: 'Company',
      RECEIVER_OPTION2: 'Member',
      QUANTITY_METHOD: 'Calculation method',
      QUANTITY_OPTION1: 'Per quantity',
      QUANTITY_OPTION2: 'Per member',
    },

    SMS: {
      SMS_SENT: 'SMS sent',
      UNABLE_SEND: 'Unable to send SMS'
    },
    REGISTRATION: {
      PAGES: 'Pages',
      CONFIRM_DELETE_PAGE: 'Confirm removal of the page',
      VERIFY_BANKID_TEXT: 'BankID signature text',
      VERIFY_BANKID: 'Verify with BankID',
      ALLOW_DUPLICATES: 'Allow duplicates to register (this will disable the login)',
      NEW_PAGE: 'New page',
      CHECK_LADOK: 'Check against LADOK',
      ERROR_OCCURED: 'An error occured, try again later',
      LOGIN_URL: 'Login URL at registration',
      TOO_MANY_LOOKUPS: 'Too many lookups, wait a minute.',
      EMAIL_TAKEN: 'E-mail is already registered',
      PERSONNR_TAKEN: 'Person number is already registered',
      UNABLE_PROCEED: 'Unable to proceed',
      CONFIRM: 'Confirm',
      SELECTED_COMPANIES: 'Selected companies',
      UNABLE_GET_TERMS: 'Unable to get terms',
      SINGLE_MEMBERSHIP: 'Single membership',
      FAMILY_MEMBERSHIP: 'Family membership',
      PERSONNR_FORMAT: 'YYYYMMDD1234',
      INPUT_PERSONNR: 'Input a valid person number',
      SELECTED_SKIP_PERSONNR: 'You opted to skip person number',
      SKIP_PERSONNR: 'I don\'t want to give out my person number',
      NEW_FAMILY_MEMBER: 'New family member',
      NEXT_STEP: 'Next step',
      PREV_STEP: 'Previous',
      CREATE_ACCOUNT: 'Create account',
      PROCEED_TO_PAYMENT: 'Proceed to payment',
      RENEW_ACCOUNT: 'Renew account',
      SHOW_AT_REGISTRATION: 'Show for registration',
      SHOW_AT_RENEWAL: 'Show for renewal',
      MUST_FULL_PERSONNR: 'Must provide full valid Social Security Number',
      DO_SPAR: 'Do SPAR lookup on Social Security Number (cost per lookup)',
      MANDATORY: 'Mandatory',
      DISPLAY: 'Display options',
      SELECT_LOCAL_COMPANY: 'Select local company',
      CAN_ALSO_JOIN: 'You can also join these',
      SELECT_REGION: 'Select region',
      UPLOAD_CSS: 'Upload CSS file',
      REMOVE_CSS: 'Delete CSS',
      ACTIVATED_FIELDS: 'Activated fields',
      FIELDS_MANDATORY_INFO: 'Mandatory member information is configured in Settings -> Member requirements'
    },
    ATTRIBUTES: {
      NONE: 'None',
      NAME: 'Name',
      UPDATED: 'Attribute was updated',
      UNABLE_UPDATE: 'Unable to update attribute',
      CREATED: 'Attribute was created',
      UNABLE_CREATE: 'Unable to create attribute',
      DELETED: 'Attribute was deleted',
      UNABLE_DELETE: 'Unbale to delete attribute',
      COLUMN: 'Column',
      VALUE: 'Value',
      UNABLE_LIST: 'Unable to list attributes',
      UNABLE_DELETE_GROUP: 'Unable to delete category',
      UNABLE_DELETE_GROUP_CONFLICT: 'Some members have this category assigned',
      GROUP_DELETED: 'Group deleted',
      HEADER: 'Categories of attributes/properties',
      MULTIPLE_OPTIONS: 'Multiple options',
      MULTIPLE_OPTIONS_INFO: 'Can multiple options be selected?',
      OTHER_OPTIONS: 'Other options',
      IS_DROPDOWN: 'Is dropdown',
      SHOW_AT_REGISTRATION: 'Show at registration',
      SHOW_AT_PROFILE: 'Show in profile',
      MANDATORY: 'Mandatory selection',
      SINGLE_SELECTION: 'Single selection',
      MULTI_SELECTION: 'Multiple selection',
      UNABLE_LIST_CATEGORIES: 'Unable to list categories',
      CATEGORY_CREATED: 'Category was created',
      UNABLE_CATEGORY_CREATE: 'Unable to create category',
      CATEGORY_UPDATED: 'Category was updated',
      UNABLE_CATEGORY_UPDATE: 'Unable to update category',
      UNABLE_GET: 'Unable to get category',
      CATEGORY: 'Category',
      NEW: 'New attribute',
      NEW_CATEGORY: 'New category',
      TOO_LONG: 'Name must be less than 64 characters'
    },
    REGISTRATIONSETUP: {
      TIP_EXTRA_VISIBILITY_OPTION: 'How the extra selection should be displayed',
      HEADER_EXTRA_VISIBILITY_OPTION: 'Extra visibility option',
      EXTRA_VISIBILITY_SEARCH: 'Searchable',
      EXTRA_VISIBILITY_LIST: 'List',

      EXTRA_CHECKBOX: 'Multiple',
      EXTRA_RADIO: 'Single',
      EXTRA_NONE: 'No extra selection',
      COMPANY_NAME: 'Company name',
      PRESELECTED: 'Preselected',
      AREA_ALLOCATION: 'Area allocation',
      EXTRA: 'Extra',
      MEMBERSHIP: 'Membership',
      SHOW: 'Show',
      NOT_ASSIGNED: 'Unassigned',
      ASSIGNED: 'Assigned({num})',

      TIP_LEVEL0_LABEL: 'Text to appear as the heading for the first choice in company selection',
      HEADER_LEVEL0_LABEL: 'Company Selection Heading Level 1 (Top company)',

      TIP_LEVEL1_LABEL: 'Text to appear as the heading for the second choice in company selection',
      HEADER_LEVEL1_LABEL: 'Company Selection Heading Level 2',

      TIP_LEVEL2_LABEL: 'Text to appear as the heading for the third choice in company selection',
      HEADER_LEVEL2_LABEL: 'Company Selection Heading Level 3',

      TIP_LEVEL3_LABEL: 'Text to appear as the heading for the fourth choice in company selection',
      HEADER_LEVEL3_LABEL: 'Company Selection Heading Level 4',

      TIP_EXTRA_LABEL: 'Text to appear as the heading of extra company selection',
      HEADER_EXTRA_LABEL: 'EXTRA company selection',

      ACTIVATE_FAMILYREG: 'Activate family registration',
      PARENT_REAL_MEMBERS: 'Parents are real members',
      SUGGEST_AREA_MEMBERSHIP: 'Suggest area membership',
      TIP_EXTRA_OPTION: 'Extra option for members',
      HEADER_EXTRA_OPTION: 'Extra option',
      REGISTER_WIZARD: 'Registration wizard',
      COMPANY_SELECT_V2: 'Company select - v2',
      UNABLE_CREATE_PAGE: 'Unable to create page',
      PAGE_CREATED: 'Page created',
      PAGE_UPDATED: 'Page updated',
      UNABLE_UPDATE_PAGE: 'Unable to update page',
      PAGE_DELETED: 'Page deleted',
      UNABLE_DELETE_PAGE: 'Unable to delete page',
      UNABLE_DELETE_CSS: 'Unable to delete CSS',
      CSS_DELETED: 'CSS deleted',
      UNABLE_LIST_PAGES: 'Unable to list pages',
      PERSONNR: 'Swedish Social Security number',
      STUDENT: 'Student',
      DETAILS: 'Details',
      PROPERTIES: 'Attributes',
      PARENTS: 'Guardians',
      SHOP: 'Shop',
      AREA_ALLOC: 'Company selection',
      CONFIRM: 'Confirm',
      REG_FAMILY: 'Family',
      ONEPAGER: 'Single page',
      UNABLE_SORT: 'Unable to sort pages'
    },
    SENDOUT: {
      REMAINING_SMS_BUDGET: 'Remaining SMS Budget',
      CREATED: 'Sendout created',
      SENDOUT: 'Sendout',
      EMPTY_CREATED: 'An empty sendout has been created',
      UNABLE_CREATE: 'Unable to create sendout',
      DELETED: 'Sendout was deleted',
      UNABLE_DELETE: 'Unable to delete sendout',
      UNABLE_GET: 'Unable to get sendout',
      UNABLE_LIST: 'Unable to list sendouts',
      SELECT_TYPE: 'Select members or users',
      STARTED: 'Sendout has started',
      UNABLE_START: 'Unable to start sendout',
      UPDATED: 'Sendout was updated',
      UNABLE_UPDATED: 'Unable to update sendout',
      CONFIRM_DELETE: 'Are you sure you want to delete the sendout?',
      CONFIRM_START: 'Are you sure you want to start the sendout?',
      SHOW_HISTORY: 'Show history',
      CREATING: 'Creating sendout',
      UPDATING: 'Updating sendout',
      NEW_SENDOUT: 'New sendout',
      OPENS: 'Opens',
      CLICKS: 'Clicks',
      BOUNCES: 'Bounces',
      DROPS: 'Drops',
      NAME_ON_SENDOUT: 'Name on sendout',
      TYPE: 'Type',
      NOT_ENOUGH_BUDGET: 'Not enough budget set for sendout',
      SYS: 'System',
      DEF: 'Default',
      IMP: 'Important',
      EMAIL_SENDOUT: 'E-mail sendouts',
      SEND_A_TEST: 'Send a test',
      EMAIL_SEND_TO: 'E-mail to send to',
      PREVIEW_SEND: 'Send preview',
      SENDOUT_INFO: 'Sendout information',
      FROM_NAME: 'From name',
      CHARACTERS_REMAINING: 'Characters remaining',
      INFO_FROM_NAME: 'Will be "Sender" in inbox',
      SET_SUBJECT: 'Set subject',
      SCHEDULED_AT: 'Schedule at',
      SCHEDULED_AT_TOOLTIP: 'Scheduled at',
      ACTIVATE_SCHEDULE_AT: 'Activate',
      SCHEDULED_AT_HELPTEXT: 'Sendout will start at the datetime selected.',
      SUBJECT: 'Subject',
      EMAILSUBJECT: 'E-mail subject',
      PREVIEW_TEXT: 'Preview text',
      PREVIEW_INFO: 'Will display a preview text in some e-mail clients',
      SELECT_TEMPLATE_EMAIL: 'E-mail: Select template',
      REVIEW: 'Review',
      CREATE_NEW_TEMPLATE: 'Create new template',
      CORRECT_INFO: 'Fields above must be set correctly before sendout can start',
      ALL_CORRECT: 'All fields are correct',
      UNABLE_SELECT_TEMPLATE: 'Unable to select template',
      EMAIL_SENT: 'E-mail sent',
      UNABLE_SEND: 'Unable to send',
      WARNING_COST: 'Cost will be applied',
      SMS_SENDOUT: 'SMS sendout',
      SENDER: 'Sender',
      SENDER_ID_INFO: 'Sender ID instead of a phone number.',
      SMS_SELECT_TEMPLATE: 'SMS - select template',
      TEMPLATE_MUST_BE_SELECTED: 'A template must be selected',
      SMS_SENT: 'SMS sent',
      FROM_ID: 'From ID',
      SMS_TEMPLATE: 'SMS template',
      SMS_SEND_TO: 'SMS send to',
      EMAIL_TEMPLATE: 'Email template',
    },
    SETTINGS: {
      PAYMENT_DAYS: 'Due date - days after creation',
      ENABLED_EVENT_DINTERO: 'Activate online payment for events',
      ENABLE_SELECT_PAYMENT: 'Activate new payment selection',
      UNABLE_GET: 'Unable to get company settings',
      LOGIN: 'Login',
      ALERT_INFO_WILL_REG: 'If company is shown on the registration and is selected, a membership for the company will be created.',
      WILL_REG: 'Create membership in company if selected',
      CREATE_MEMBERSHIP: 'Create membership',
      DISPLAY_DURING_REGISTRATION: 'Display as choice during registration',
      SETTINGS: 'Company settings',
      UPDATED: 'Settings updated',
      UNABLE_GET: 'Unable to get settings',
      UNABLE_UPDATE: 'Unable to update settings',
      IS_FAITH: 'Is religious community',
      FEE_PCT: 'Fee percentage',
      ADM_FEE: 'Administration fee amount',
      MEM_PCT: 'Fee percentage per member',
      MEM_FEE: 'Fee per member',
      PUBLIC: 'Public',
      LINKS: 'Links',
      REGISTRATION: 'Registration',
      BANK: 'Bank',
      MEMBER_PROFILE: 'Member profile',
      PAYMENT: 'Payment',
      STRIPE: 'Stripe',
      FORTNOX: 'Fortnox',
      API_CONNECTION: 'API connection',
      EVENTS: 'Events',
      EDUCATIONS: 'Educations',
      FAITH: 'Religious community',
      MEMBER_REQUIREMENTS: 'Member requirements',
      MEMBER_TYPES: 'Member types',
      EXTRA_DATA: 'Extra data',
      REQUIRES_HTTP: 'Must include http:// or https://',
      MEMBER_REG_PAGE: 'Member register page',
      MEMBER_LOGIN_PAGE: 'Member login page',
      USER_LOGIN_PAGE: 'User login page',
      PUBLIC_EVENTS_PAGE: 'Public events page',
      MEMBER_LOGIN_ORG_PAGE: 'Member login all org page',
      MEMBER_REQ_PERSONNR: 'Date of birth',
      MEMBER_REQ_SSN: 'Personal number',
      MEMBER_REQ_AGE: 'Age',
      MEMBER_REQ_SEX: 'Sex',
      MEMBER_REQ_ADDRESS: 'Address (postcode, city, country)',
      MEMBER_REQ_FULLNAME: 'First name + Last name',
      MEMBER_REQ_EMAIL: 'E-mail',
      MEMBER_REQ_PHONE: 'Phone number',
      MEMBER_REQ_EMAILPHONE: 'E-mail or mobile phone number',
      MEMBER_REQ_PASSWORD: 'Password',
      MEMBER_REQUIREMENTS_INFO: 'All below selected categories will become mandatory if selected in all situations where these categories appear.',
      EMAILS: 'E-mails',
    },
    RENEWS: {
      SMS_COUNT_INFO: 'Count calculated by template length and number of renews',
      SELECT_SENDOUT_METHOD: 'Select sendout methods',
      MANUAL: 'Create renewals',
      RENEWALS: 'Renewals',
      RENEW_MEMBERSHIPS: 'Renew memberships',
      NEXT_PERIOD_NOT_SET: 'Next period is not set',
      CREATE_TO: 'Create renewal for period ',
      TO_PERIOD: 'To period',
      TO_VFDT: 'From date',
      SENDOUT_METHOD: 'Sendout via',
      TIME_SENT: 'Time sent',
      DELETED: 'Renewal deleted',
      UNABLE_DELETE: 'Unable to delete renewal',
      ACTIVE: 'Awaiting answer',
      STARTED_LINK: 'Started web',
      INVOICED: 'Invoiced',
      DELETED: 'Deleted',
      DONE: 'Done',
      SCHEDULED: 'Scheduled',
      AWAITING_PAYMENT: 'Not paid',
      TIME_OUT: 'Not answered',
      LINK_SENT_EMAIL: 'Renewal link sent via e-mail',
      UNABLE_LINK_SENT_EMAIL: 'Unable to send renwal link via e-mail',
      NEXT_PERIOD_ERROR: 'Next period is not setup correctly.',
      UNABLE_LIST: 'Unable to list renewals',
      UNABLE_GET: 'Unable to get renewal',
      SEND_SMS: 'Send SMS',
      SEND_EMAIL: 'Send E-mail',
      CONFIRM_SEND_SMS: 'Do you want to send SMS? Costs will apply.',
      CONFIRM_SEND_EMAIL: 'Do you want to send E-mail?',
      CLICK_TO_SEND_SMS: 'Click to send renewal via SMS',
      CLICK_TO_SEND_EMAIL: 'Click to send renewal via E-mail',
      SMS_SEND_FAIL: 'SMS failed',
      SMS_DID_SEND: 'SMS was sent',
      SMS_TRY_SEND: 'SMS will be sent',
      SMS_DONT_SEND: 'SMS wont be sent',
      EMAIL_SEND_FAIL: 'E-mail failed',
      EMAIL_DID_SEND: 'E-mail was sent',
      EMAIL_TRY_SEND: 'E-mail will be sent',
      EMAIL_DONT_SEND: 'E-mail wont be sent',
      REMINDER_SMS_SEND_AT: 'Reminder via SMS will be sent',
      REMINDER_EMAIL_SEND_AT: 'Reminder via E-mail will be sent',
      REMINDER_NO_MORE_SMS: 'No more SMS reminders will be sent',
      REMINDER_NO_MORE_EMAIL: 'No more E-mail reminders will be sent',
      SUMMARY_1: 'Renewal created',
      SUMMARY_2: 'with status',
      SUMMARY_3: 'and payment status',
      CREATE: 'Create renewal',
      ACTIVATE_AUTORENEW: 'Activate autorenewal',
      SEND_TO_PARENT: 'Send to guardian e-mail if set',
      RENEW_TEMPLATE: 'Template for renewal',
      RENEW_TEMPLATE_SMS: 'Template for renewal SMS',
      SEND_AT_TIME: 'Send at time',
      CREATE_RENEW_AT: 'When to create renewal',
      BEFORE_LAST_DAY: 'Before last membership day',
      AFTER_LAST_DAY: 'After last membership day',
      NUM_DAYS: 'Number of days',
      EMAIL_JOB: 'Email job',
      SMS_JOB: 'SMS job',
      CREATE_INVOICES: 'Create invoices',
      CONFIRM_EMAIL: 'Are you sure you want to create e-mails for selected renewals?',
      CONFIRM_SMS: 'Are you sure you want to create SMS for selected renewals?',
      CREATED_TO: 'Created to',
      CREATED_FROM: 'Created from',
      UNABLE_COUNT: 'Unable to count',
      UNABLE_GENERATE_INVOICES: 'Unable to generate invoices',
      INVOICES_GENERATED: 'Invoices generated',
      NO_INVOICES_GENERATED: 'No invoices generated',
      INVOICE_JOB_CREATED: 'Invoice creation job started',
      SELECT_AT_LEAST_ONE: 'Select at least one renewal',
      HAS_EMAIL: 'Has e-mail',
      MISSING_EMAIL: 'Missing e-mail',
      ALL_ACTIVE_RENEWS: 'All active renewals for period',
      SELECTED_IN_TABLE: 'Selected renewals in the table',
      JOB_WILL_COST: 'This job has an estimated cost of',
      NUM_ESTIMATED: 'Number estimated',
      NUMBER_SENT: 'Number sent',
      UNABLE_CALC_SMS: 'Unable to calculate SMS cost',
      NO_SELECTION: 'No selection is made',
      HANDLE_SELECTED_MEMBERS: 'Handle members in table',
      LAST_RUN: 'Last run',
      RENEW_INTO: 'Renew into period',
      NUM_REMIND: 'Number of reminders',
      DBR: 'Days before next reminder',
      NUMBER_ESTIMATED: 'Estimated number',
      NUMBER_RENEWS: 'Number of renews',
      NUMBER_SMS_IN_TEMPLATE: 'Number of SMS for template',
      USE_TEMPLATE_EMAIL: 'Use e-mail template',
      USE_TEMPLATE_SMS: 'Use SMS template',
      SMS_EXTRA_INFO: 'Warning! Calculation is an estimate.',
      SEND_VIA_EMAIL: 'Send via e-mail',
      SEND_VIA_SMS: 'Send via SMS',
      IS_CONFIRM: 'Is confirmation (free membership)'
    },
    SEARCH: {
      CLEAR_SELECTED_SHOPITEMS: 'Clear memberships',
      NUM_SELECTED_SHOPITEMS: 'Count selected memberships',
      MORE_FILTERS: 'More filters',
      MAKE_A_SEARCH: 'Perform search',
      MAKE_A_SELECTION: 'Create a selection',
      CONFIRM_SELECTED_SHOPITEMS: 'Confirm selection',
      SELECT_SHOPITEMS: 'Search memberships',
      ADVANCED_SEARCH: 'Advanced Search',
      ADVANCED_INFO: 'Advanced search, save searches and export to Excel',
      SELECTION_NOT_FOUND: 'Unable to select search parameters',
      UNABLE_SEARCH: 'Unable to perform search',
      SELECT_SEARCH: 'Select search',
      NUM_MEMBER_FOUND: 'Number of members found:',
      NO_SAVED: 'No saved searches',
      SELECT_MEMBERS_PERSONS: 'Select members or persons',
      DELETED: 'Search deleted',
      SELECT: 'Select search',
      MAKE_SELECTION: 'Make selection',
      DO_SEARCH: 'Perform a search',
      MEMBERSHIP_FOR_DATE: 'Membership exists for date',
      MEMBERSHIP_FROM: 'Memberships from date',
      MEMBERSHIP_TO: 'Memberships to date',
      SEARCH_MEMBERS: 'Search members',
      ONLY_MISSING_EMAIL: 'Only members with missing e-mail',
      SEARCH_USERS: 'Search users',
      ONLY_WITH_EMAIL: 'Only members with any e-mail set.',
      ONLY_IN_STICKPROV: 'Only members within a sample selection',
      ONLY_IN_PERIOD: 'Only members with membership within period',
      ONLY_WITH_ERRORS: 'Only members with errors',
      ONLY_MISSING_PHONE: 'Only members with missing phone',
      ONLY_MISSING_MEMBERSHIP: 'Only members without membership',
      SEARCH_WITH_EVENT: 'Search with event',
      SAVE_SEARCH: 'Save search as',
      SAVED_NAME: 'Name of saved search',
      CREATE_SELECTION: 'Create selection',
      LACK_COMPANY_PERMISSION: 'You do not have access to selected company.',
      MUST_SELECT: 'You must make a selection',
      SEARCH_COUNT: 'Perform a search to determine the count',
      COUNT_SEARCHED: 'Count in selection is ',
      SEARCH_CREATED: 'Search was created',
      UNABLE_CREATE: 'Unable to create search',
      UNABLE_LIST_REGIONS: 'Unable to list regions',
      PAYMENT_STATUS: 'Payment status',
      SEARCH_ATTRIBUTE: 'Search with attribute',
      NO_ATTRIBUTE: 'No attribute',
      TO_AGE: 'To age',
      FROM_AGE: 'From age',
      SEARCHED: 'Searched',
      UNABLE: 'Unable to search',
      USER_SEARCH_INFO: 'Search for users based on title',
      USERS_AS_DIRECTORS: 'Users who are board members',
      USERS_AS_ACCOUNTANTS: 'Users who are accountants',
      UNABLE_LIST_TITLES: 'Unable to list titles',
      REG_BEFORE: 'Registered before date (inclusive)',
      REG_AFTER: 'Registered after date (inclusive)'
    },
    EVENTS: {
      TICKET_NOT_IN_EVENT: 'Purchased ticket does not exist on the event',
      PARTICIPANT_MISSING: 'Participant missing',
      TICKET_MISSING: 'Ticket missing',
      
      CHANGE_EVENT: 'Change event',
      EVENT: 'Event',
      FREE: 'Free',
      UNABLE_JOIN_ANY_MEMBER: 'You need to be a member to join this event',
      UNABLE_JOIN_COMPANY_MEMBER_PAID: 'You need to be an active and paid member of the company to join this event',
      UNABLE_JOIN_ANY_MEMBER_PAID: 'You need to be an active and paid member to join this event',
      UNABLE_JOIN_MAX_TICKETS: 'You already have the max amount of tickets allowed',

      TIP_URL_FORWARD: 'URL to forward participant of event after registration is done',
      HEADER_URL_FORWARD: 'Forward URL for participants after registration',

      TIP_ENABLED_EVENT_DINTERO: 'Ticket will be sent when payment is done',
      HEADER_ENABLED_EVENT_DINTERO: 'Ticket payment',
      CHECKBOX_ENABLED_EVENT_DINTERO: 'Demand payment before ticket is sent',
      CHECKBOX_SEND_CONFIRM: 'Send e-mail confirmation',
      HEADER_SEND_CONFIRM: 'Send e-mail confirmation',
      TIP_SEND_CONFIRM: 'When a member registers for an event, an e-mail confirmation will be sent to the member.',
      CHECKBOX_SUBEVENTS: 'Show subevents on public event list',
      HEADER_SUBEVENTS: 'Show subevents on public event list',
      TIP_SUBEVENTS: 'Active subevents will be shown on the public event list.',
      ACCEPT_TERMS: 'I have read and accept the terms and conditions',
      ACCOUNTING: 'Accounting',
      ADDRESS: 'Address',
      ADDRESS_UNAVAILABLE: 'Address unavailable',
      ADD_PARTICIPANT: 'Add participant',
      ADD_TICKET: 'Add ticket',
      BAN_FOR_NUM_DAYS: 'Ban warned for number of days',
      BAN_ORGS_WITH_NUM_MEMBERS_BANNED: 'Ban organisations with number of banned members',
      CATEGORIES: 'Categories',
      CATEGORY: 'Category page',
      CHECKEDIN: 'Checkedin',
      CHECKED_IN: 'Checked in',
      CHOOSE_TICKETS:'Choose tickets',
      CLOSE_SIGNUP: 'Close signup at time',
      COMMUNICATION: 'Communication',
      CONFIRM_DELETE: 'Are you sure you want to delete the event?',
      COST_CENTER: 'Cost center',
      CREATE_OR_CHOOSE: 'Create or choose existing',
      CREATED_EMPTY: 'An empty event has been created',
      DATE_TIME: 'Date and time',
      DELETED: 'Event was deleted',
      DETAILS:'Details',
      EDIT_TICKET: 'Edit ticket',
      EMAIL_TEMPLATE: 'E-mail template for ticket',
      EMAIL_TEMPLATE_WARN: 'E-mail to send for warning',
      EMAIL_TICKET: 'A e-mail will be sent to your e-mail address with your ticket that you might need to show to enter the event.',
      END_TIME: 'End time',
      ERROR_SIGN_UPP: 'Could not sign up for event',
      ERROR_TRY_LATER: 'Something went wrong, try again later',
      EVENTS: 'Events',
      EVENT_FULL: 'Event is full',
      EVENT_NAME: 'Name of event',
      EVENT_NOT_FULL: 'Event not full',
      EVENT_TIMESTAMPS: 'Event times',
      EXAMPLE_LAT: 'Example: 121.11',
      EXAMPLE_LNG: 'Example: 93.23',
      FACEBOOK_INFO: 'Read more information about the event on Facebook',
      FACEBOOK_URL: 'Facebook URL',
      GENERATE_GOOGLE_MAPS: 'Generate google maps',
      GENERATE_GOOGLE_MAPS_ALERT: 'Google Maps has not been generated for this event. Click on the button below after you have entered the street, postal code and postal address.',
      GEO_COORDS: 'Geo coordinates',
      INFO_PARTICIPATION: 'You can not change participant on the ticket. You can however change contact information of the person on the ticket.',
      INTERNAL_ERROR: 'Internal error',
      INVOICE_RECIPIANT_TYPE: 'Invoice recipiant type',
      IRL_EVENT_TEXT: 'This is an IRL event, click button to generate google maps.',
      LAST_SIGNUP_DATETIME: 'Last signup date and time',
      LINKS: 'Links',
      LOCATION: 'Location',
      LOCATION_DESCRIPTION: 'Location description (optional)',
      LOCATION_FOR_EVENT: 'Location for event',
      MAXIMUM_ORDER: 'You can maximum order',
      MAXIMUM_TICKETS: 'Maximum {max_tickets} tickets per person',
      MAX_SIGNUPS: 'Max signups (num seats)',
      MAX_TICKETS_PER_PERSON: 'Max tickets per person',
      MEMBERS_ONLY: 'Members only',
      MEMBER_PERSON_ADDED: 'Member/person added to event',
      MISSING_FIELDS: 'Missing fields',
      MUST_ACCEPT: 'Signup must accept terms',
      MUST_FILL_ADDRESS: 'You must fill in address information first',
      NEW_EVENT: 'New event',
      NEW_TICKET: 'New ticket',
      NUM_SEATS: 'Seats',
      NUM_SIGNUPS: 'Signups',
      ONLINE: 'Online/Internet',
      OPEN_FOR_ALL: 'Open for all',
      ANY_MEMBER: 'Any member in the system',
      COMPANY_MEMBER_PAID: 'Paid membership within company',
      ANY_MEMBER_PAID: 'Any member with a paid membership',
      OTHER_LINK: 'Other link',
      PARTICIPANTS: 'Participants',
      PARTICIPANT_TYPE: 'Participant type',
      PERM_BAN_AFTER_NUM_WARN: 'Permanent ban after number of warnings',
      POSTAL_ADDRESS: 'Postal address',
      PREVIOUS_EVENTS: 'Previous events',
      PUBLISH_OPTIONS: 'Publishing options',
      READ_MORE: 'Read more',
      REGISTERED: 'You are registered',
      REMOVED_FROM_EVENT: 'Removed from event',
      REMOVE_PARTICIPANT: 'Remove participant',
      REMOVE_PARTICIPANT_TEXT: 'Are you sure you want to remove participant? Refunds must be done manually.',
      SAME_AS_PARTICIPANT: 'Same address as participant',
      SAVE_EVENT: 'Save event',
      SEARCH_NAME: 'Search name or e-mail',
      SEARCH_SELECT_MEMBER: 'Search and select member',
      SEARCH_SELECT_PERSON: 'Search and select person',
      SELECT_TICKET_TEMPLATE: 'You must select e-mail template for ticket',
      SEND_EMAIL: 'Send email to Administrators when a signup occurs',
      SEND_INVITATION: 'Send invitation to all members',
      SEND_INVITATION_TIME: 'Send invitation at this time',
      SEND_SMS_INVITATION: 'Send SMS invitation to all members',
      SEND_TICKET: 'Send ticket',
      SEND_TICKET_AGAIN: 'Send ticket again',
      ERROR_SEND_TICKET: 'Unable to send ticket',
      SUCCESS_SEND_TICKET: 'Ticket sent',
      SIGNUPED: 'Signups',
      SIGNUPS: 'Signup',
      SIGNUP_DATE_EXPIRED: 'Signup date expired',
      SIGN_UP: 'Sign up',
      SMS_TEMPLATE_INVITATION: 'SMS for invitation',
      START_TIME: 'Start time',
      STREET_AND_POST: 'Address and postal',
      STRIKE_WARN: 'Warning on no-show',
      SUCCESS_GOOGLE_MAPS: 'Google Maps generated',
      TAGS: 'Tags',
      TERMS: 'Terms',
      TERMS_TEXT: 'Terms text',
      TICKET: 'Ticket',
      TICKETS: 'Tickets',
      TICKET_CONNECTED: 'Ticket was connected',
      TICKET_DELETED: 'Ticket deleted',
      TICKET_SELECT_ERROR: 'Ticket was already sent to participant, you can\'t change it.',
      TICKET_SEND: 'Send ticket via e-mail to participant',
      TYPE_OF_TICKET: 'Type of ticket',
      UNABLE_ADD_MEMBER_PERSON: 'Unable to add member/person to event',
      UNABLE_CONNECT_TICKET: 'Unable to connect ticket',
      UNABLE_CREATE: 'Unable to create event',
      UNABLE_DELETE: 'Unable to delete event',
      UNABLE_DELETE_CONFLICT: 'Unable to delete event because there are active participants, remove participants first.',
      UNABLE_DELETE_TICKET: 'Unable to delete ticket',
      UNABLE_GET: 'Unable to get event',
      UNABLE_GOOGLE_MAPS: 'Unable to generate Google Maps',
      UNABLE_LIST: 'Unable to list events',
      UNABLE_LIST_PARTICIPANTS: 'Unable to list participants',
      UNABLE_LIST_TICKETS: 'Unable to list tickets',
      UNABLE_REMOVE_PARTICIPANT: 'Unable to remove participant from event',
      UNABLE_UPDATE: 'Unable to update event',
      UPDATED: 'Event was updated',
      VAT_ACCOUNT: 'VAT Account',
      VISIT_WEBPAGE: 'Visit this web page for more information about the event',
      ZIPCODE: 'Postcode',
      INACTIVE_MEMBERSHIP: 'Please renew your membership and try again or contact the association member support.',
      MEMBERS_ONLY: 'Please sign in to your account as this event is a members only event.',
      EVENT_ONLINE: "Online/Internet",
      STATUS: 'Status',
      IS_CLOSED: 'Closed/Start date passed',
      IS_OPEN: 'Open'
    },
    EVENT_CATEGORY: {
      CONFIRM_DELETE: 'Are you sure you want to delete the event category?',
      UNABLE_GET: 'Unable to get category',
      UNABLE_LIST: 'Unable to list category',
      CREATED: 'Category created',
      UPDATED: 'Category updated',
      DELETED: 'Category deleted',
      UNABLE_CREATE: 'Unable to create category',
      UNABLE_UPDATE: 'Unable to update category',
      UNABLE_DELETE: 'Unable to delete category',
      NEW: 'New category',
      EDIT: 'Edit category',
      NON_PUBLIC_NAME: 'Name (non public)',
      LINK: 'Link',
      HEADER: 'Header',
      FORWARD_SUCCESS: 'Forward URL after successful signup',
      URL_INFO: 'http:// or https:// must be included',
      PAGE_TEXT: 'Page text',
      LOWEST: 'Lowest sort',
      HIGHEST: 'Highest sort'
    },
    TERMS: {
      CREATED_AT: 'Created at',
      ACTIVE: 'Active',
      NAME: 'Name',
      TERMS: 'Terms',
      ACCEPT: 'Accept terms',
      MISSING: 'Terms are missing',
      NEW: 'New terms',
      TERMS_TEXT_INFO: 'This text will be placed next to the checkbox',
      CHECKBOX_TEXT: 'Checkbox text',
      TEXT: 'Terms text',
      UNABLE_GET: 'Unable to get terms',
      UPDATED: 'Terms updated',
      UNABLE_UPDATE: 'Unable to update terms',
      CREATED: 'Terms created',
      UNABLE_CREATE: 'Unable to create terms',
      DELETED: 'Terms deleted',
      UNABLE_DELETE: 'Unable to delete terms'
    },
    BOOKING: {
      CREATED: 'Booking created',
      SEND: 'A confirmation will be sent to your e-mail',
      CONFIRM_BOOKING: 'Confirm booking',
      SELECT_BOOKING: 'Select date below'
    },
    QUICKSEARCH: {
      UNABLE_SEARCH: 'Unable to search for member',
      UNABLE_SEARCH_PERSON: 'Unable to search for person',
      SEARCH_TERMS: 'Name, personnumber, e-mail, phone...',
      QUICKSEARCH_INFO: 'Quick search for member in all companies and periods'
    },

    MEMBER_PROPERTY: {
      HEADER_GROUP_ATTRIBUTES: 'Attributes within group',
      NEW_ATTRIBUTE: 'New attribute',
      EDIT_ATTRIBUTE: 'Edit attribute',
      NAME: 'Name',
      PLACEHOLDER_DESCR: 'Descriptive text about the attribute',
      DESCR: 'Description'
    },
    SYSTEM: {
      INSTANCE_TIMEOUT_TEMPLATE_ID: 'Monitoring: Instance timeout template',
      INSTANCE_RETURN_TEMPLATE_ID: 'Monitoring: Instance return connection template',
      ADMIN_INQUIRY_TEMPLATE_ID: 'Inquiry from new customer',
      CUSTOMER_CONFIRM_INQUIRY_TEMPLATE_ID: 'Inquiry confirmation to customer',
    },
    DUPLICATES: {

      NUM_DUPLICATES: 'Antal dubbletter',

      MERGE_INFO1: 'Medlemsdata som ej behålls kommer att raderas',
      MERGE_INFO2: 'Alla medlemmar i tabellen nedan kommer att slås samman för respektive rad',
      MERGE_INFO3: 'Medlemskap som läggs över på medlem som behålls kommer att kontrolleras att det ej är en dubblett. Medlemskap med samma period men olika föreningar kommer att behållas. Medlemskap med samma period och förening kommer att beslutas enligt alternativen i "Medlemskap".',
      MERGE_INFO4: 'Observera att medlemmar kommer att raderas när denna körning har startats.',

      MEMBER_SKIP_OPTIONS: 'Exkludera medlemmar från körningen som saknar följande:',
      MEMBER_SKIP_OPTION1: 'Förnamn',
      MEMBER_SKIP_OPTION2: 'Efternamn',
      MEMBER_SKIP_OPTION3: 'E-post',
      MEMBER_SKIP_OPTION4: 'Telefon',
      MEMBER_SKIP_OPTION5: 'Personnummer',

      MEMBERSHIP_MERGE_OPTIONS: 'Medlemskap',
      MEMBERSHIP_MERGE_OPTION1: 'Behåll nyaste medlemskapet vid period överlapp',
      MEMBERSHIP_MERGE_OPTION2: 'Behåll äldsta medlemskapet vid period överlapp',
      MEMBERSHIP_MERGE_OPTION3: 'Behåll medlemskapet som har betalats vid period överlapp',

      MEMBER_DATA_OPTIONS: 'Målmedlem',
      MEMBER_DATA_OPTION1: 'Behåll den nyaste medlemmen',
      MEMBER_DATA_OPTION2: 'Behåll den äldsta medlemmen',

      TRANSACTION_OPTIONS: 'Transaktionsdata',
      TRANSACTION_OPTION1: 'Behåll endast målmedlemmens transaktioner',
      TRANSACTION_OPTION2: 'Behåll transaktoner från alla medlemmar',
    },
    USER_PERMISSIONS: {
      UPDATED: 'User permissions updated',
      UNABLE_UPDATE: 'Could not update user permissions',
      UNABLE_LIST: 'Could not list user permissions'
    },

    EXAMPLES: {
      EDITOR: 'Editor',
      TABLE: 'Table',
      NAME: 'Name',
      NAME2: 'Name2',
      DATE: 'Date',
      NEW: 'New example',
      WELCOME_ADMIN: 'Welcome admin!',
      ON_SUBMIT: 'Form was submitted',
    },

    MEMBER_DETAILS: {
      PHONE: 'Mobile phone',
      LANDLINE: 'Land line',
      YYYY: 'YYYY',
      INVALID_PHONE: 'Invalid phone number',
      INVALID_CO: 'The name must be at least two characters',
      INVALID_DOB: 'Invalid date of birth',
      INVALID_EMAIL: 'Invalid e-mail',
      INVALID_EMAIL2: 'E-mail does not match',
      INVALID_PASSWORD: 'Password must be 6-30 characters',
      INVALID_PASSWORD2: 'Password does not match',
      TOOLTIP_FIRSTNAME: 'Field for first name as well as middle names.',
      TOOLTIP_LASTNAME: 'Field for last name.',
      TOOLTIP_COMPANY: 'Company name field',
      TOOLTIP_DOB: 'Birthday, in the format of year, month and day, for example 19880120.',
      TOOLTIP_INTDOB: 'Birthday, in the format of month, day and lastly year',
      TOOLTIP_ADDRESS: 'Street address and number.',
      TOOLTIP_ZIPCODE: 'Postal number that is connected to your address.',
      TOOLTIP_POST: 'The town associated with the postal code.',
      TOOLTIP_CO: 'Field used in addresses when you are sending a letter or package to a person by using someone else\'s address or the address of a company.',
      TOOLTIP_COUNTRY: 'The country to which the address is linked',
      TOOLTIP_REGION: 'Geographical region that must be linked to the accompanying address. In most cases, this information can be filled in automatically by postal code, if this is not done, a manual selection is required.',
      TOOLTIP_COMMUNE: 'Geographic municipality that must be linked to the accompanying address. In most cases, this information can be filled in automatically by postal code, if this is not done, a manual selection is required.',
      TOOLTIP_PHONE: 'he mobile number that must be linked to this account must be bypassed by the country code, e.g. +46701234567.',
      TOOLTIP_LANDLINE: 'Home phone including area code.',
      TOOLTIP_EMAIL: 'A working e-mail address that must be linked to this account, must contain an @ and a dot.',
      TOOLTIP_EMAIL2: 'Please confirm the e-mail address in this box by typing it again.',
      TOOLTIP_PASSWORD: 'Password that is connected to this account, must be 6-30 characters.',
      TOOLTIP_PASSWORD2: 'Please confirm the password in this box by typing it again.',
      TOOLTIP_GENDER: 'Choose gender identity.',
      TOOLTIP_AVD: 'Enter what section you belong to.',
    },

    INSTRUMENT_TYPES: {
      TITLE: 'Instrument types',
      ADD_TYPE: 'Add',
      UPDATED: 'Instrument type created',
      DELETED: 'Instrument type deleted',
      UNABLE_CREATE: 'Could not create instrument type',
      UNABLE_DELETE: 'Could not delete instrument type',
      UNABLE_LIST: 'Could not list instrument types',
      NAME: 'Name',
      INVALID_NAME: 'Invalid name'
    },


    IMPORT: {
      WAIT_WHILE_AI_MAPPING: 'Please wait, our AI is mapping your data.',

      TIP_PARENT_COMPANY: 'Companies that lack company number will be added to the following company.',
      PARENT_COMPANY: 'Parent company',
      TIP_TO_PERIOD: 'Members will be imported to this period.',
      TO_PERIOD: 'To period',
      TO_COMPANY: 'To company',
      TIP_TO_COMPANY: 'Members missing company number will be added to the following company.',
      TIP_PRIMARY_KEY: 'The primary key in the file. If there are duplicate primary key entries, these records will merge into one.',
      PRIMARY_KEY: 'Primary key',
      VERIFY_FIELDS: 'Verify and map fields',
      SKIP_ALL: 'Skip all',
      ROW: 'Row',
      CELL_HEADER: 'Header',
      CELL_NUMBER: 'Cell number',
      MESSAGE: 'Message',
      VALIDATION_ERROR: 'An error occured during validation of import',
      CREATE: 'Create new',
      FILE_REQUIREMENTS_INFO: 'Before uploading XLSX file, ensure the following:',
      FILE_REQUIREMENTS_1: 'File must be XLSX 2007 format.',
      FILE_REQUIREMENTS_2: 'All columns/cells must be formatted as string/text.',
      FILE_REQUIREMENTS_3: 'A column for ID must be present.',
      FILE_REQUIREMENTS_4: 'Each date must be formatted as such: YYYY-MM-DD.',
      FILE_REQUIREMENTS_5: 'Swedish SSN must be 12 numbers with a dash: YYYYMMDD-1234.',
      FILE_REQUIREMENTS_6: 'Some columns may not exceed a number of characters. Check <a href="https://memlist.se/docs/importera-medlemmar-genom-excel/">this page</a> for currently supported column lengths.',
      FILE_REQUIREMENTS_7: 'Regions and communes must follow the Swedish skatteverket code standard.',
      FILE_REQUIREMENTS_8: 'Phone numbers must have +<country code><number>.',
      EXCEL_FIELD: 'Excel field',
      MEMLIST_FIELD: 'Memlist field',
      COLUMN: 'Column',
      FILE_READ_ERROR: 'Unable to read file',
      OPTIONS: 'Options',
      MAP_COLUMNS: 'Map columns',
      VALIDATION: 'Validation',
      FINALIZE: 'Finalize',
      NOT_FOUND: 'Unable to fetch import',
      ERROR: 'Error occured',
      TAB_INFO_HEADER: 'Import information',
      CREATED_AT: 'Created at',
      BUTTON_MAP_COLUMNS: 'Map columns',
      BUTTON_VALIDATE: 'Validate',
      BUTTON_FINALIZE: 'Finalize',
      ACTIVE: 'Active imports',
      HISTORY: 'Previous imports',
      STATUS: 'Status',
      NEW_IMPORT: 'New import',
      FILE: 'File to import',
      VALIDATION_100_ROWS: 'Due to performance reason only the first 100 rows in the file is validated.',
      UPLOAD_FILE: 'Upload file',
      INVALID_FILE: 'Invalid file',
      TO_PERIOD: 'To period',
      CREATED_BY: 'Created by',
      TAB_FINALIZE_HEADER: 'Finalize import',
      TAB_INFO_HEADER: 'Import information',
      TAB_MAP_COLUMNS_HEADER: 'Column mapping',
      TAB_VALIDATION_HEADER: 'File validation',
      BUTTON_FINALIZE: 'Finalize',
      BUTTON_VALIDATE: 'Validate',
      BUTTON_MAP_COLUMNS: 'Map columns',
      UNABLE_DELETE: 'Unable to delete',
      COUNT_MAPPINGS: 'Mapped column count',
      FILENAME: 'Filename',
      RUNNING_VALIDATION: 'Validation is running...',
      DONE_VALIDATION: 'Validation is done',
      FAILURE_VALIDATION: 'Validation failed',
      READY_TO_VALIDATE: 'Ready to validate',
      SUCCESS_FINALIZE: 'Import is now finalized!',
      FAILURE_FINALIZE: 'Import failed to finalize!',
      RUNNING_FINALIZE: 'Import is being finalized...',
      READY_TO_FINALIZE: 'Import is ready to finalize!',
      FINALIZE_IMPORT: 'Finalize import',
      UNABLE_UPLOAD: 'Unable to upload file',
      UNABLE_UPDATE: 'Unable to update import',
      CONTINUE: 'Continue',
      STATUSES: {
        WAIT_MAP_COLUMNS: 'Map columns',
        FAIL_VALIDATION: 'Failure to validate',
        FAIL_FINALIZE: 'Failure to import',
        DONE_VALIDATION: 'Validation done',
        DONE_MAP_COLUMNS: 'Column mapping done',
        WAIT_VALIDATION: 'Wait validation...',
        WAIT_FINALIZE: 'Wait import...',
        RUN_FINALIZE: 'Running import...',
        RUN_VALIDATION: 'Running validation...',
        DONE_FINALIZE: 'Import done'
      }
    },

  },

  TAB: {
    INVOICE_COMPANIES: {
      CREATE_INVOICES: 'Create ({count}) invoices',
      NO_INVOICE_AFTER: 'Missing invoice after date',
      HEADER_COMPANY: 'Company',
      HEADER_MEMBER_COUNT: 'Member count',
      HEADER_TOTAL_AMOUNT: 'Total amount',
      HEADER_LAST_INVOICE: 'Latest invoice',
      TIP_NO_INVOICE_AFTER: 'ilter to list all organizations missing a generated invoice after a specific date.',
      TIP_HEADER_COMPANY: 'Name of the organisation.',
      TIP_HEADER_MEMBER_COUNT: 'The number of members per organisation.',
      TIP_HEADER_TOTAL_AMOUNT: 'Total amount estimated to be notified.',
      TIP_HEADER_LAST_INVOICE: 'Date when the last invoice was generated.',
      CONFIRM_TITLE: 'Invoices job',
      CONFIRM_MESSAGE: 'The invoices are generated and will be displayed in the invoice table when the run is completed.',
      CONFIRM_ERROR: 'Unable to run invoice job.',
      UNABLE_CREATE_INVOICES: 'Unable to create invoices.',
      UNABLE_LIST_COMPANIES: 'Unable to list companies.',
      UNABLE_LIST_SHOP_ITEMS: 'Unable to list shop items.'
    },
    INVOICE_TEMPLATES: {
      DELETE_LOGO: 'Remove logo',
      CREATE_TITLE: 'Create invoice template',
      UPDATE_TITLE: 'Update invoice template',
      NEW_TEMPLATE: 'New Template',
      SETTINGS: 'Settings',
      SENDER: 'Sender',
      BOX: 'Graphic box',
      BOX_TEXT: 'Text',
      BOX_BG: 'Background color',
      BOX_FG: 'Text color',
      NAME: 'Name',
      LANGUAGE: 'Language',
      TYPE: 'Type',
      SENDER_HEADER: 'Sender text',
      HEADER: 'Header',
      REMINDER: 'Reminder',
      REMINDER_FEE: 'Reminder fee',
      UPLOAD_LOGO: 'Uppload logo',
      SENDER_WEBADDRESS: 'Webb address',
      SENDER_PHONE: 'Phone number',
      SENDER_ORGNUMBER: 'Organisation number',
      SENDER_CONTACT: 'Contact person',
      SENDER_EMAIL: 'E-mail',

      TYPES: {
        MEMBER: 'Avi för medlemmar',
        COMPANY: 'Faktura förening',
        MEMBER_RENEW: 'Förnyelse för medlemmar'
      },

      TYPE_OPTION1: 'Invoice members',
      TYPE_OPTION2: 'Invoice companies',
      HEADER_OPTION1: 'Notice',
      HEADER_OPTION2: 'Invoice',
      HEADER_OPTION3: 'Reminder invoice',
      TH_NAME: 'NAME',
      TH_CREATED_AT: 'CREATED AT',
      TIP_SENDER_HEADER: 'The text to display on the top left',
      TIP_SETTINGS: 'Choose what information should be visible about the recipient on the invoice.',
      TIP_SENDER: 'Choose which sender information should be visible on the notification.',
      TIP_NAME: 'Sets the name of the invoice template. This name is only shown to administrators and is not visible to companies or members when an invoice is sent.',
      TIP_LANGUAGE: 'Choose the language in which the invoice is to be generated.',
      TIP_TYPE: 'Choice of type of invoice. Invoice for members - Creates an invoice for one or more individual members. Invoice for companies - Creates an invoice for companies.',
      TIP_HEADER: 'Choose type of notification.',
      TIP_REMINDER: 'Create a reminder invoice and possibly set a reminder fee.',
      TIP_BOX: 'In this section you can adjust the graphic box that is in the invoice',
      TIP_BOX_TEXT: 'Define content in the graphic box.',
      TIP_BOX_BG: 'Define the background color of the graphic box. Choose a background color that contrasts well with the text color.',
      TIP_BOX_FG: 'Define the text color of the graphic box. Choose a text color that contrasts well with the background color.',
      TIP_USE_BPOST_IMAGE: 'Insert a BPosttidning image on top left',
      USE_BPOST_IMAGE: 'Insert BPosttidning',
      INVALID_SENDER_HEADER: 'Invalid sender header',
      INVALID_NAME: 'Invalid name',
      INVALID_LANGUAGE: 'Invalid language',
      INVALID_TYPE: 'Invalid type',
      INVALID_HEADER: 'Invalid header',
      INVALID_REMINDER_FEE: 'Invalid reminder fee',
      INVALID_BOX_TEXT: 'Invalid text',
      CREATED: 'Invoice template created.',
      DELETED: 'Invoice template deleted.',
      UPDATED: 'Invoice template updated.',
      UPLOADED: 'Logo file uploaded',
      UNABLE_CREATE: 'Unable to create invoice template.',
      UNABLE_LIST: 'Unable to list invoice templates.',
      UNABLE_DELETE: 'Unable to delete invoice template.',
      UNABLE_UPDATE: 'Unable to update invoice template.',
      UNABLE_UPLOAD: 'Unable to upload logo file',
      UNABLE_PREVIEW: 'Unable to preview invoice template',
    },

    SETTINGS_DNS: {
      TITLE: 'Valid addresses for sendouts',
      NEW_ADDRESS: 'New address',
      INVALID_EMAIL: 'Invalid e-mail',
      ADDED: 'Address added',
      DELETED: 'Address deleted',
      UNABLE_ADD: 'Unable to add address',
      UNABLE_DELETE: 'Unable to delete address',
      UNABLE_LIST: 'Unable to list addresses'
    },

    USER_TERMS: {
      NEW_TERM: 'New term'
    }
  },

  QUICKSEARCH: {
    RESULTS: 'Results',
    NO_RESULTS: 'No results found'
  },

  ADVANCED_SEARCH: {
    SAVE_OPTIONS: 'Options',
    PERIODS: 'Periods',
    ALL_PERIODS: 'All periods',
    SAVE_QUERY: 'Save query',
    
    NO_RESULTS: 'No results',
    DO_AUTORENEW: 'Autorenew',
    SECRET: 'Secrecy',
    ALL_COMPANIES: 'All companies',
    NO_ACTIVE_RENEWAL: 'No active renewal',
    HAS_ACTIVE_RENEWAL: 'Has active renewal',
    RENEWAL: 'Renewal',
    
    UNABLE_EDIT: 'This row can not be edited',

    NUM_SELECTION: 'Number of recipients: {count}',

    SELECT_RECIPIENTS: 'Selected recipients {count}',

    CREATE_QUERY: 'Save query',

    NO_QUERIES: 'No queries',

    QUERY_UPDATED: 'Query updated',
    UNABLE_QUERY_UPDATE: 'Unable to update query',

    QUERY_CREATED: 'Query created',
    UNABLE_QUERY_CREATE: 'Unable to create query',


    UNABLE_QUERY_LIST: 'Unable to list queries',

    UNABLE_QUERY_DELETE: 'Unable to delete search',
    QUERY_DELETED: 'Search deleted',


    SELECT_SAVED_SEARCH: 'Select saved search',

    COMPANIES: 'Companies',

    SEARCH_QUERIES: 'Search queries',
    SELECT_QUERY: 'Select query',
    QUERY_UPDATED: 'Query updated',

    NAME_REQUIRED: 'Name is required',

    SAVED: 'Saved',
    UNABLE_TO_SAVE: 'Unable to save',

    NO_RECORDS_IN_SELECTION: 'No rows to export',

    SHOW_MORE_FIELDS: 'Show more fields',
    HIDE_MORE_FIELDS: 'Hide more fields',

    MEMBERS: 'Members',
    USERS: 'Users',
    PERSONS: 'Persons',

    PAYMENT_STATUS: 'Payment status',
    SEX: 'Legal sex',
    PUBLIC_SEX: 'Defined gender',

    ALL: 'All',
    YES: 'Yes',
    NO: 'No',
    NONE: 'None',
    MISSING: 'Missing',
    EXISTS: 'Exists',

    PAID: 'Paid',
    NOT_PAID: 'Not paid',

    MEMBER_TYPE: 'Member type',
    AGE: 'Age',
    AGE_FROM: 'From',
    AGE_TO: 'To',
    RDT: 'Registration date',
    EXIT_AT: 'Exit date',
    RDT_FROM: 'From',
    RDT_TO: 'To',

    DEACTIVATE_AT: 'Deactivate date',
    DEACTIVATE_FROM: 'From',
    DEACTIVATE_TO: 'To',
  
    SHOP_ITEM: 'Membership article',

    MISSING_MEMBERSHIP: 'Missing membership',
    MISSING_MEMBERSHIP_FROM: 'From',
    MISSING_MEMBERSHIP_TO: 'To',

    HAVE_MEMBERSHIP: 'Exists membership',
    HAVE_MEMBERSHIP_FROM: 'From',
    HAVE_MEMBERSHIP_TO: 'To',


    DECEASED: 'Deceased',
    DEACTIVATED: 'Deactivated',
    ADDRESS: 'Address',
    ZIPCODE: 'Postal code',
    COUNTRY: 'Has country',
    COUNTRY_CODE: 'Assigned country',
    PHONE: 'Phone',
    EMAIL: 'Email',
    HD: 'Main member',
    PERSONNR: 'Personnummer',

    SAVE_SEARCH: 'Save search',

    UNABLE_TO_SEARCH: 'Unable to search',

    EVENTS: {
      NAME: 'Event',
      HEADER: 'Select event',
      EVENT_NAME: 'Event name',
      COMPANY_NAME: 'Company',
      SELECT_EVENT: 'Select event ({count})',
      ADD_EVENT: 'Add event',
      NONE_SELECTED: 'No events selected'
    },
    MEMBERSHIPS: {
      NAME: 'Memberships',
      HEADER: 'Select memberships',
      MEMBERSHIP_NAME: 'Membership',
      COMPANY_NAME: 'Company',
      SELECT_MEMBERSHIP: 'Select membership ({count})',
      ADD_MEMBERSHIP: 'Add membership',
      NONE_SELECTED: 'No selected memberships',
      INCLUDE_ANY: 'At least one membership exists',
      INCLUDE_ALL: 'All memberships must exist',
      INCLUDE_INFO: 'Inclusion options',
    },
    ATTRIBUTES: {
      INCLUDE_ANY: 'Any attribute must exist',
      INCLUDE_ALL: 'All attributes must exist',
      INCLUDE_INFO: 'Include alternative',
      NAME: 'Attribute',
      HEADER: 'Select attribute',
      GROUP_NAME: 'Category',
      PROP_NAME: 'Value',
      SELECT_ATTRIBUTE: 'Select attributes ({count})',
      ADD_ATTRIBUTE: 'Add attribute',
      NONE_SELECTED: 'No attributes selected'
    },
    EDUCATIONS: {
      SEARCH_EDUCATION: 'Enter education code',
      CODE: 'Code',
      EDUCATION: 'Education',
      INCLUDE_ANY: 'Any educations must exist',
      INCLUDE_ALL: 'All educations must exist',
      INCLUDE_INFO: 'Include alternative',
      NAME: 'Educations',
      HEADER: 'Select educations',
      SELECT_EDUCATION: 'Select educations ({count})',
      ADD_EDUCATION: 'Add education',
      NONE_SELECTED: 'No educations selected'
    }
  },

  MODAL: {
    INVOICE_CREATE: {
      TITLE: 'Create invoices',
      INVOICE_COUNT: 'Total invoice count',
      PREVIEW_AS: 'Preview as',
      TIP_INVOICE_COUNT: 'The number of invoices that will be generated.',
      TIP_PREVIEW_AS: 'Preview the invoice as one of the recipients.',
      TH_ARTICLE: 'ARTICLE',
      TH_AMOUNT: 'AMOUNT',
      TH_CURRENCY: 'CURRENCY',
      TH_QUANTITY_TYPE: 'CALCULATION',
      TH_QUANTITY: 'QUANTITY',
      TH_VAT: 'VAT',
      TH_TOT_VAT: 'TOT. VAT',
      TH_TOT_AMOUNT: 'TOT. AMOUNT',
      TOT_VAT: 'Total VAT',
      EXC_VAT: 'Total exc. VAT',
      INC_VAT: 'Total inc. VAT',
      DUE_DATE: 'Due date',
      BOX_TEXT: 'Invoice text',
      ADD_ITEM: '+ Add item',
      CREATE: 'Create ({count})',
      QUANTITY_TYPE_1: 'Per count',
      QUANTITY_TYPE_2: 'Per member',
      TIP_TH_ARTICLE: 'The name of the item that will appear on the invoice for the previewed organization.',
      TIP_TH_AMOUNT: 'Cost per item.',
      TIP_TH_CURRENCY: 'Currency per item.',
      TIP_TH_QUANTITY_TYPE: 'Method of calculation for each article.',
      TIP_TH_QUANTITY: 'Number of articles.',
      TIP_TH_VAT: 'VAT rate.',
      TIP_TH_TOT_VAT: 'Total VAT amount.',
      TIP_TH_TOT_AMOUNT: 'Total sum for the number of times the cost per item.',
      TIP_TOT_VAT: 'Total VAT',
      TIP_EXC_VAT: 'Total amount excluding VAT.',
      TIP_INC_VAT: 'Total amount including VAT.',
      TIP_DUE_DATE: 'Override the current predefined due date (This setting can be found under Settings/Payment) by defining a new date.',
      TIP_BOX_TEXT: 'Override the current predefined invoice text (this text is placed in the invoice template under Invoices/Templates) by defining a new text.',
    },
  },

  COMPONENT: {
    EMAIL_VALID_DOMAIN: {
      INVALID: 'Invalid e-mail, contact Memlist support.',
      UNABLE_LIST: 'Unable to list valid e-mails.'
    },
  },

  COUNTRIES: [
    { key: 'NONE', value: 'None selected' },
    { key: 'AF', value: 'Afghanistan' },
    { key: 'AL', value: 'Albania' },
    { key: 'DZ', value: 'Algeria' },
    { key: 'AS', value: 'American Samoa' },
    { key: 'AD', value: 'Andorra' },
    { key: 'AO', value: 'Angola' },
    { key: 'AI', value: 'Anguilla' },
    { key: 'AQ', value: 'Antarctica' },
    { key: 'AG', value: 'Antigua and Barbuda' },
    { key: 'AR', value: 'Argentina' },
    { key: 'AM', value: 'Armenia' },
    { key: 'AW', value: 'Aruba' },
    { key: 'AU', value: 'Australia' },
    { key: 'AT', value: 'Austria' },
    { key: 'AZ', value: 'Azerbaijan' },
    { key: 'BS', value: 'Bahamas' },
    { key: 'BH', value: 'Bahrain' },
    { key: 'BD', value: 'Bangladesh' },
    { key: 'BB', value: 'Barbados' },
    { key: 'BY', value: 'Belarus' },
    { key: 'BE', value: 'Belgium' },
    { key: 'BZ', value: 'Belize' },
    { key: 'BJ', value: 'Benin' },
    { key: 'BM', value: 'Bermuda' },
    { key: 'BT', value: 'Bhutan' },
    { key: 'BO', value: 'Bolivia, Plurinational State of' },
    { key: 'BQ', value: 'Bonaire, Sint Eustatius and Saba' },
    { key: 'BA', value: 'Bosnia and Herzegovina' },
    { key: 'BW', value: 'Botswana' },
    { key: 'BV', value: 'Bouvet Island' },
    { key: 'BR', value: 'Brazil' },
    { key: 'IO', value: 'British Indian Ocean Territory' },
    { key: 'BN', value: 'Brunei Darussalam' },
    { key: 'BG', value: 'Bulgaria' },
    { key: 'BF', value: 'Burkina Faso' },
    { key: 'BI', value: 'Burundi' },
    { key: 'KH', value: 'Cambodia' },
    { key: 'CM', value: 'Cameroon' },
    { key: 'CA', value: 'Canada' },
    { key: 'CV', value: 'Cape Verde' },
    { key: 'KY', value: 'Cayman Islands' },
    { key: 'CF', value: 'Central African Republic' },
    { key: 'TD', value: 'Chad' },
    { key: 'CL', value: 'Chile' },
    { key: 'CN', value: 'China' },
    { key: 'CC', value: 'Cocos (Keeling) Islands' },
    { key: 'CO', value: 'Colombia' },
    { key: 'KM', value: 'Comoros' },
    { key: 'CG', value: 'Congo' },
    { key: 'CD', value: 'Congo, the Democratic Republic of the' },
    { key: 'CK', value: 'Cook Islands' },
    { key: 'CR', value: 'Costa Rica' },
    { key: 'HR', value: 'Croatia' },
    { key: 'CU', value: 'Cuba' },
    { key: 'CW', value: 'Curaçao' },
    { key: 'CY', value: 'Cyprus' },
    { key: 'CZ', value: 'Czech Republic' },
    { key: 'CI', value: "Côte d'Ivoire" },
    { key: 'DK', value: 'Denmark' },
    { key: 'DJ', value: 'Djibouti' },
    { key: 'DM', value: 'Dominica' },
    { key: 'DO', value: 'Dominican Republic' },
    { key: 'EC', value: 'Ecuador' },
    { key: 'EG', value: 'Egypt' },
    { key: 'SV', value: 'El Salvador' },
    { key: 'GQ', value: 'Equatorial Guinea' },
    { key: 'ER', value: 'Eritrea' },
    { key: 'EE', value: 'Estonia' },
    { key: 'ET', value: 'Ethiopia' },
    { key: 'FK', value: 'Falkland Islands (Malvinas)' },
    { key: 'FO', value: 'Faroe Islands' },
    { key: 'FJ', value: 'Fiji' },
    { key: 'FI', value: 'Finland' },
    { key: 'FR', value: 'France' },
    { key: 'GF', value: 'French Guiana' },
    { key: 'PF', value: 'French Polynesia' },
    { key: 'TF', value: 'French Southern Territories' },
    { key: 'GA', value: 'Gabon' },
    { key: 'GM', value: 'Gambia' },
    { key: 'GE', value: 'Georgia' },
    { key: 'DE', value: 'Germany' },
    { key: 'GH', value: 'Ghana' },
    { key: 'GI', value: 'Gibraltar' },
    { key: 'GR', value: 'Greece' },
    { key: 'GL', value: 'Greenland' },
    { key: 'GD', value: 'Grenada' },
    { key: 'GP', value: 'Guadeloupe' },
    { key: 'GU', value: 'Guam' },
    { key: 'GT', value: 'Guatemala' },
    { key: 'GG', value: 'Guernsey' },
    { key: 'GN', value: 'Guinea' },
    { key: 'GW', value: 'Guinea-Bissau' },
    { key: 'GY', value: 'Guyana' },
    { key: 'HT', value: 'Haiti' },
    { key: 'HM', value: 'Heard Island and McDonald Islands' },
    { key: 'VA', value: 'Holy See (Vatican City State)' },
    { key: 'HN', value: 'Honduras' },
    { key: 'HK', value: 'Hong Kong' },
    { key: 'HU', value: 'Hungary' },
    { key: 'IS', value: 'Iceland' },
    { key: 'IN', value: 'India' },
    { key: 'ID', value: 'Indonesia' },
    { key: 'IR', value: 'Iran, Islamic Republic of' },
    { key: 'IQ', value: 'Iraq' },
    { key: 'IE', value: 'Ireland' },
    { key: 'IM', value: 'Isle of Man' },
    { key: 'IL', value: 'Israel' },
    { key: 'IT', value: 'Italy' },
    { key: 'JM', value: 'Jamaica' },
    { key: 'JP', value: 'Japan' },
    { key: 'JE', value: 'Jersey' },
    { key: 'JO', value: 'Jordan' },
    { key: 'CX', value: 'Julön' },
    { key: 'KZ', value: 'Kazakhstan' },
    { key: 'KE', value: 'Kenya' },
    { key: 'KI', value: 'Kiribati' },
    { key: 'KP', value: "Korea, Democratic People's Republic of" },
    { key: 'KR', value: 'Korea, Republic of' },
    { key: 'KW', value: 'Kuwait' },
    { key: 'KG', value: 'Kyrgyzstan' },
    { key: 'LA', value: "Lao People's Democratic Republic" },
    { key: 'LV', value: 'Latvia' },
    { key: 'LB', value: 'Lebanon' },
    { key: 'LS', value: 'Lesotho' },
    { key: 'LR', value: 'Liberia' },
    { key: 'LY', value: 'Libya' },
    { key: 'LI', value: 'Liechtenstein' },
    { key: 'LT', value: 'Lithuania' },
    { key: 'LU', value: 'Luxembourg' },
    { key: 'MO', value: 'Macao' },
    { key: 'MK', value: 'Macedonia, the Former Yugoslav Republic of' },
    { key: 'MG', value: 'Madagascar' },
    { key: 'MW', value: 'Malawi' },
    { key: 'MY', value: 'Malaysia' },
    { key: 'MV', value: 'Maldives' },
    { key: 'ML', value: 'Mali' },
    { key: 'MT', value: 'Malta' },
    { key: 'MH', value: 'Marshall Islands' },
    { key: 'MQ', value: 'Martinique' },
    { key: 'MR', value: 'Mauritania' },
    { key: 'MU', value: 'Mauritius' },
    { key: 'YT', value: 'Mayotte' },
    { key: 'MX', value: 'Mexico' },
    { key: 'FM', value: 'Micronesia, Federated States of' },
    { key: 'MD', value: 'Moldova, Republic of' },
    { key: 'MC', value: 'Monaco' },
    { key: 'MN', value: 'Mongolia' },
    { key: 'ME', value: 'Montenegro' },
    { key: 'MS', value: 'Montserrat' },
    { key: 'MA', value: 'Morocco' },
    { key: 'MZ', value: 'Mozambique' },
    { key: 'MM', value: 'Myanmar' },
    { key: 'NA', value: 'Namibia' },
    { key: 'NR', value: 'Nauru' },
    { key: 'NP', value: 'Nepal' },
    { key: 'NL', value: 'Netherlands' },
    { key: 'NC', value: 'New Caledonia' },
    { key: 'NZ', value: 'New Zealand' },
    { key: 'NI', value: 'Nicaragua' },
    { key: 'NE', value: 'Niger' },
    { key: 'NG', value: 'Nigeria' },
    { key: 'NU', value: 'Niue' },
    { key: 'NF', value: 'Norfolk Island' },
    { key: 'MP', value: 'Northern Mariana Islands' },
    { key: 'NO', value: 'Norway' },
    { key: 'OM', value: 'Oman' },
    { key: 'PK', value: 'Pakistan' },
    { key: 'PW', value: 'Palau' },
    { key: 'PS', value: 'Palestine, State of' },
    { key: 'PA', value: 'Panama' },
    { key: 'PG', value: 'Papua New Guinea' },
    { key: 'PY', value: 'Paraguay' },
    { key: 'PE', value: 'Peru' },
    { key: 'PH', value: 'Philippines' },
    { key: 'PN', value: 'Pitcairn' },
    { key: 'PL', value: 'Poland' },
    { key: 'PT', value: 'Portugal' },
    { key: 'PR', value: 'Puerto Rico' },
    { key: 'QA', value: 'Qatar' },
    { key: 'RO', value: 'Romania' },
    { key: 'RU', value: 'Russian Federation' },
    { key: 'RW', value: 'Rwanda' },
    { key: 'RE', value: 'Réunion' },
    { key: 'BL', value: 'Saint Barthélemy' },
    { key: 'SH', value: 'Saint Helena, Ascension and Tristan da Cunha' },
    { key: 'KN', value: 'Saint Kitts and Nevis' },
    { key: 'LC', value: 'Saint Lucia' },
    { key: 'MF', value: 'Saint Martin (French part)' },
    { key: 'PM', value: 'Saint Pierre and Miquelon' },
    { key: 'VC', value: 'Saint Vincent and the Grenadines' },
    { key: 'WS', value: 'Samoa' },
    { key: 'SM', value: 'San Marino' },
    { key: 'ST', value: 'Sao Tome and Principe' },
    { key: 'SA', value: 'Saudi Arabia' },
    { key: 'SN', value: 'Senegal' },
    { key: 'RS', value: 'Serbia' },
    { key: 'SC', value: 'Seychelles' },
    { key: 'SL', value: 'Sierra Leone' },
    { key: 'SG', value: 'Singapore' },
    { key: 'SX', value: 'Sint Maarten (Dutch part)' },
    { key: 'SK', value: 'Slovakia' },
    { key: 'SI', value: 'Slovenia' },
    { key: 'SB', value: 'Solomon Islands' },
    { key: 'SO', value: 'Somalia' },
    { key: 'ZA', value: 'South Africa' },
    { key: 'GS', value: 'South Georgia and the South Sandwich Islands' },
    { key: 'SS', value: 'South Sudan' },
    { key: 'ES', value: 'Spain' },
    { key: 'LK', value: 'Sri Lanka' },
    { key: 'SD', value: 'Sudan' },
    { key: 'SR', value: 'Suriname' },
    { key: 'SJ', value: 'Svalbard and Jan Mayen' },
    { key: 'SZ', value: 'Swaziland' },
    { key: 'SE', value: 'Sweden' },
    { key: 'CH', value: 'Switzerland' },
    { key: 'SY', value: 'Syrian Arab Republic' },
    { key: 'TW', value: 'Taiwan, Province of China' },
    { key: 'TJ', value: 'Tajikistan' },
    { key: 'TZ', value: 'Tanzania, United Republic of' },
    { key: 'TH', value: 'Thailand' },
    { key: 'TL', value: 'Timor-Leste' },
    { key: 'TG', value: 'Togo' },
    { key: 'TK', value: 'Tokelau' },
    { key: 'TO', value: 'Tonga' },
    { key: 'TT', value: 'Trinidad and Tobago' },
    { key: 'TN', value: 'Tunisia' },
    { key: 'TR', value: 'Turkey' },
    { key: 'TM', value: 'Turkmenistan' },
    { key: 'TC', value: 'Turks and Caicos Islands' },
    { key: 'TV', value: 'Tuvalu' },
    { key: 'UG', value: 'Uganda' },
    { key: 'UA', value: 'Ukraine' },
    { key: 'AE', value: 'United Arab Emirates' },
    { key: 'GB', value: 'United Kingdom' },
    { key: 'US', value: 'United States' },
    { key: 'UM', value: 'United States Minor Outlying Islands' },
    { key: 'UY', value: 'Uruguay' },
    { key: 'UZ', value: 'Uzbekistan' },
    { key: 'VU', value: 'Vanuatu' },
    { key: 'VE', value: 'Venezuela, Bolivarian Republic of' },
    { key: 'VN', value: 'Viet Nam' },
    { key: 'VG', value: 'Virgin Islands, British' },
    { key: 'VI', value: 'Virgin Islands, U.S.' },
    { key: 'WF', value: 'Wallis and Futuna' },
    { key: 'EH', value: 'Western Sahara' },
    { key: 'YE', value: 'Yemen' },
    { key: 'ZM', value: 'Zambia' },
    { key: 'ZW', value: 'Zimbabwe' },
    { key: 'AX', value: 'Åland Islands' }
  ],
  CALENDAR: {
    MONTHS: {
      JANUARY: 'January',
      FEBRUARY: 'February',
      MARS: 'Mars',
      APRIL: 'April',
      MAY: 'May',
      JUNE: 'June',
      JULY: 'July',
      AUGUST: 'August',
      SEPTEMBER: 'September',
      OCTOBER: 'October',
      NOVEMBER: 'November',
      DECEMBER: 'December'
    },
    SHORT_MONTHS: {
      JAN: 'Jan',
      FEB: 'Feb',
      MAR: 'Mar',
      APR: 'Apr',
      MAY: 'May',
      JUN: 'Jun',
      JUL: 'Jul',
      AUG: 'Aug',
      SEP: 'Sep',
      OCT: 'Oct',
      NOV: 'Nov',
      DEC: 'Dec'
    }
  },
  TRANSLATOR: {
    SELECT: "Select your language"
  },
  PUBLIC: {
    NO_ACCESS: {
      CONTINUE_TO_LOGIN: '<< Back to login',
      HEADER: 'No access to company',
      INFO_P: 'Your account lacks access to a company.',
      INFO1: 'The group which your user belongs to lacks access to either a company or page',
      INFO2: 'Ask the administrator to add at least 1 company or page to the group you belong.'
    },

    NO_PAGE_ACCESS: {
      CONTINUE_TO_LOGIN: '<< Back',
      HEADER: 'No access to page',
      INFO_P: 'Your account lacks access to the given page.',
      INFO1: 'The group which your user belongs to lacks access to the given page',
      INFO2: 'Ask the administrator to add your group to the page.'
    },
    
    LOGIN: {
      INVALID_LOGIN_LINK: 'This link is invalid. Contact the administrator to receive a valid link.',
      DISABLED_LOGIN: 'Login is disabled.',
    },
    
    FORM: {
      CUSTOM_DONATION_AMOUNT: 'Custom amount',
      CREATE_FAMILY_MEMBER: 'Add family member',
      TOTAL: 'Total',
      CART: 'Cart',
      CART_EMPTY: 'Cart is empty',
      MUST_SELECT_MEMBERSHIP: 'You must select a membership',
      TO: 'to',
      ADD: 'Add',
      REMOVE: 'Remove',
      APPEARANCE: 'Appearance',
      PERSONNR_REQUIRED: 'Personnummer is required',
      MISSING_FIELDS: 'Fill in missing fields',
      INVALID_PERSONNR: 'Invalid personnummer',
      PERSONNR: 'Personnummer',
      PLEASE_WAIT: 'Please wait',
      PLEASE_WAIT_INFO: 'Your request is being processed',
      CONFIRM_HEADER: 'Confirm page',
      INVALID_TEXT: 'Invalid text',
      FIRSTNAME: 'Firstname',
      LASTNAME: 'Lastname',
      EMAIL: 'Email',
      EMAIL2: 'Repeat email',
      INVALID_EMAIL: 'Invalid e-mail',
      EMAIL_MISMATCH: 'E-mails do not match',
      PASSWORD: 'Password',
      PASSWORD2: 'Repeat password',
      PASSWORD_MISMATCH: 'Passwords do not match',
      INVALID_PASSWORD: 'Invalid password',
      INVALID_PHONE: 'Invalid phone',
      PHONE: 'Phone',
      LANDLINE: 'Landline',
      POST: 'City',
      ZIPCODE: 'Postal code',
      ADDRESS: 'Address',
      INVALID_ADDRESS: 'Invalid address',
      INVALID_POST: 'Invalid city',
      INVALID_ZIPCODE: 'Invalid postal code',
      COMMUNE_CODE: 'Commune code',
      REGION_CODE: 'Region code',
      COUNTRY: 'Country',
      INVALID_DOB: 'Invalid date of birth',
      DOB: 'Date of birth YYYYMMDD',
      CNAME: 'Company name',
      ORG_NUMBER: 'Organization number',
      INVALID_ORG_NUMBER: 'Invalid organization number',
      AVD: 'Department',
      INVOICE_FIELDS: 'Invoice fields',
      MALE: 'Man',
      FEMALE: 'Woman',
      OTHER: 'Other',
      PUBLIC_SEX: 'Gender',
      IN_PERSONNR: 'Social security number',
    },
    
    INQUIRY: {
      OTHER_TEXT: 'Other information',
      CREATED_SUCCESSFULLY: 'Inquiry created!',
      CREATED_SUCCESSFULLY_INFO: 'Your inquiry has been created and will be processed by our staff as soon as possible.',

      CREATE_FAILURE: 'Unable to create inquiry',
      CREATE_FAILURE_INFO: 'Your inquiry was not created. Please try again later or contact support at support@memlist.se',
      
      
      ORG_INFO: 'Information about your organization',
      ORG_NAME: 'Organisationsnamn',
      INPUT_ORG_NAME: 'Enter name of your organization',
      INPUT_C_NAME: 'Enter contact person name',
      ADMIN_EMAIL: 'Your e-mail',
      INPUT_ADMIN_EMAIL: 'Enter your e-mail',
      INPUT_ADMIN_PHONE: 'Enter your phone number',
      PHONE: 'Phone',
      C_NAME: 'Contact person',
      ADMIN_PHONE: 'Phone',
      MISSING_FIELDS: 'Missing fields',
      ORG_SETUP: 'Organization setup',
      ORG_TYPE: 'Organization type',
      ORG_TYPES: {
        RIKS: 'National organization',
        COMPANY: 'Company',
        STUDENT: 'Student organization',
        STUDENT_COMPANY: 'Student company',
        SPORTCLUB: 'Sports club',
        INTEREST_CLUB: 'Interest club',
        UNION: 'Union',
        COOP: 'Cooperative',
        BRF: 'Housing association',
        STIFT: 'Foundation',
        MUCF: 'Youth association (MUCF)',
        FAITH: 'Faith community',

      },
      ORG_STRUCTURE: 'Organization structure',
      ORG_STRUCTURES: {
        SINGLE: 'Single organization',
        MULTI: 'Multi organization'
      },
      PERIOD: 'Membership period',
      PERIODS: {
        INFINITE: 'Fluid',
        PERIOD: 'Calendar/fiscal year',
        OTHER: 'Other'
      },
      NUM_ORGS: 'Number of organizations',
      HAS_PAYMENT: 'Will you receive payments?',
      PAYMENT_METHODS: {
        SWISH: 'Swish',
        CREDITCARD: 'Creditcard',
        POST_INVOICE: 'Post invoice',
        EMAIL_INVOICE: 'Email invoice',
        OTHER: 'Other'
      },
      OTHER_SERVICES: 'Other services',
      SERVICES: {
        SENDOUT: 'Sendouts',
        TODO: 'Todo',
        EVENTS: 'Events',
        FORTNOX: 'Fortnox',
        SPAR: 'Spar',
        LADOK: 'LADOK',
        REGISTRATIONS: 'Registrations',
        FILEUPLOADS: 'File uploads',
        FILEMANAGEMENT: 'File management',
        COUPONS: 'Coupons',
        GRANT: 'Grant',
        DEACTIVATION: 'Deactivation',
        HISTORY: 'History/Logs',
      },
      SELECT_PAYMENT_METHODS: 'Select payment methods',
      SEND_INQUIRY: 'Send inquiry',
    },
    EVENT: {
      REGISTER_INFO: 'Register to event',
      REGISTER_INFO: 'Register your information',
      INVALID_PHONE: 'Invalid phone',
      INVALID_EMAIL: 'Invalid email'
    },
    REGISTRATION: {
      EXPECTED_ACTION_ERROR_HEADER: 'Settings error',
      EXPECTED_ACTION_ERROR: 'The company payment registration settings are invalid. Contact the administrator.',
      YOU_WILL_BE_MEMBER: 'You will be member in',
      MEMBERSHIP_CREATED: 'Membership created!',
      MEMBERSHIP_CREATED_INFO: 'Your account has been created and a confirmation email has been sent to the given email address.',
      UNABLE_CREATE_MEMBERSHIP: 'Your account was not created. Please try again later.',
      UNABLE_LOAD: 'Unable to load the registration. Link is invalid.',
      UNABLE_GET_WIZARD: 'Unable to fetch registrationwizard.',
      MEMBERSHIP_PENDING: 'We have received your membership application!',
      MEMBERSHIP_PENDING_INFO: 'Your application is pending and will be processed by the organization.',
      ERROR_OCCURED: 'An error occured',
      MEMBERSHIP_ERROR: 'Registration error',
      MEMBERSHIP_ERROR_MESSAGE: 'Unable to register member. Try again later or contact your administratior.'
    },
    DONATION: {
      DONATION: 'Donation',
      FROM_NAME: 'Your name',
      DONATION_TO: 'Your donation to {name}',
      ENTER_AMOUNT: 'Enter amount',
      MESSAGE: 'Enter an optional message',
      START_PAYMENT: 'Continue'
    },
    RENEW: {
      CONFIRM_MEMBERSHIP: 'Confirm membership',
      MEMBERSHIPS: 'Memberships',
      COMPANY: 'Company',
      PERIOD: 'Period',
      MEMBER_NOT_FOUND: 'Member data not found',
      OLD_LINK: 'Activation link is either too old or used up.',
      UNABLE_CONFIRM: 'Unable to confirm',
      THANKS_CONFIRM: 'Thank you for your cofirmation!',
      REDIRECT: 'Redirecting to your profile...',
      ERROR_CONFIRM_INFO: 'We are unable to confirm your membership at this moment. Try again later or contact administrators.',
      TRY_AGAIN_LATER: 'Unable to confirm, try again later.'
    },
    PAYMENT: {
      SWISH_EXCEPTION: 'Swish exception has occurred',
      SWISH_SUBSCRIPTION_ERROR: 'Swish couldnt get consent - Try again later',
      SELECT_PAYMENT: 'Select payment method',
      NO_PAYMENT_METHODS: 'No payments method has been configured',
      PAYMENT_TIMEOUT: 'Payment took too long to process.',
      START_OVER: 'Go back to the start page and try again',
      CONTACT_SUPPORT: 'Contact support',

      CLOSE_THIS_PAGE: 'Du kan stänga denna sida',

      CREDITCARD: 'Creditcard',
      CHANGE_RECIPIENT: 'Change recipient',
      SEND_TO_EMAIL: 'Send receipt to',
      INVALID_TOKEN: 'Invalid token',
      INVALID_SHOP_ORDER_ID: 'Invalid Order ID',

      WAITING_FOR_PAYMENT: 'Waiting for payment',
      PAYMENT_COMPLETE: 'Payment complete',
      PAYMENT_ERROR: 'Payment error',

      LOADING_SWISH: 'Loading Swish...',
      TOTAL_INC_VAT: 'Total amount incl. Vat',
      PAYMENT_FAILED: 'Payment failed',
      PAYMENT_SUCCESS: 'Payment succeeded!',
      WAITING: 'Waiting for payment...',
      TRY_LATER: 'Try again later',

      REVIEW: 'Review order',
      ITEMS: 'Items',
      ITEM: 'Item',
      PRICE: 'Price',
      TOTAL: 'Total',
      DISCOUNT: 'Discount',
      INVALID_ACTION: 'Invalid payment action',
      OPEN_SWISH: 'Pay now',
      SCAN_QR_INFO: 'Open the Swish app and scan the code below',
      INVOICE: 'Invoice',
      START_SWISH: 'Start payment',
      WRITE_YOUR_NUMBER: 'Enter your swish number',
      START_YOUR_SWISH_APP: 'Start your swish app',
      SELECT_BANK_ACCOUNT: 'Select bank account',
      CANT_SEE_QR: 'Cant see the QR code? Click here',
      APP_WONT_OPEN_CLICK_HERE: 'App wont open? Click here',
      PAYER_ALIAS: 'Your swish number',
      LOADING_SHOP_ORDER: 'Loading order'
    }
  }
};
